import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from 'app/api';
import {
  PhotoRequest,
  RoomPhotoModel,
} from 'features/dictionary/types';
import { showPopupMessage } from 'features/common/slice';

export const roomPhotoApi = createApi({
  reducerPath: 'roomPhotoApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Photos'],
  endpoints: (build) => ({
    listRoomPhotos: build.query<RoomPhotoModel[], { roomId: number }>(
      {
        query: ({ roomId }) => ({ url: `rooms/${roomId}/photos`, method: 'GET' }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'Photos', id } as const)),
              { type: 'Photos', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'RoomPhotos', id: 'LIST' }` is invalidated
            [{ type: 'Photos', id: 'LIST' }],
      }
    ),
    deleteRoomPhoto: build.mutation<{ success: boolean; id: number }, { roomId: number; id: number }>(
      {
        query: ({ roomId, id }) => ({ url: `rooms/${roomId}/photos/${id}`, method: 'DELETE' }),
        invalidatesTags: () => [{ type: 'Photos', id: 'LIST' }],
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(showPopupMessage({ text: 'Фотография успешно удалена' }));
        },
      }
    ),
    updateRoomPhoto: build.mutation<RoomPhotoModel, { roomId: number; id: number; data: PhotoRequest }>({
      query: ({ roomId, id, data }) => ({
        url: `rooms/${roomId}/photos/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: () => [{ type: 'Photos', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Описание фотографии обновлено' }));
      },
    }
    ),
    uploadRoomPhotos: build.mutation<RoomPhotoModel, { roomId: number; formData: FormData }>({
      query: ({ roomId, formData }) => ({
        url: `rooms/${roomId}/photos`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }),
      invalidatesTags: () => [{ type: 'Photos', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Новая фотография загружена' }));
      },
    }
    ),
  }),
});

export const {
  useLazyListRoomPhotosQuery,
  useDeleteRoomPhotoMutation,
  useUpdateRoomPhotoMutation,
  useUploadRoomPhotosMutation,
} = roomPhotoApi;
