import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import {
  AppDispatch,
  AppStore,
} from 'app/store';
import { logout } from 'features/auth/slice';
import axios, {
  AxiosError,
  AxiosRequestConfig,
} from 'axios';
import {
  setFormErrors,
  showPopupMessage,
} from 'features/common/slice';
import { NavigateFunction } from 'react-router';

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api' : process.env.REACT_APP_API_URL || 'https://agregator-dev.esphere.ru/api';

let store: AppStore;
let navigate: NavigateFunction;

export const injectStore = (_store: AppStore) => {
  store = _store;
};

export const injectNavigate = (_navigate: NavigateFunction) => {
  navigate = _navigate;
};

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = store.getState().auth.token;
  if (token) {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
axiosInstance.interceptors.response.use((response) => response, (err: AxiosError<any, any>) => {
  if (err.response?.status === 401) {
    store.dispatch(logout());

    navigate('/login');
  }

  if (err.response?.status === 422) {
    store.dispatch(setFormErrors(err.response.data.errors));
  }

  if (err.code === 'ERR_NETWORK') {
    store.dispatch(showPopupMessage({ text: err.message, type: 'error' }));
  }

  if (err.response?.status === 400 || err.response?.status === 500 || err.response?.status === 0) {
    store.dispatch(showPopupMessage({ text: err.response.data.message, type: 'error' }));
  }

  return Promise.reject(err);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface QueryError { status?: number; data: any }

interface CustomQueryArgs extends AxiosRequestConfig {
  onSuccess?: (dispatch: AppDispatch, data: unknown) => Promise<void>;
}

export type CustomBaseQueryType = BaseQueryFn<CustomQueryArgs, unknown, unknown>;

export const axiosBaseQuery: CustomBaseQueryType = async (
  { onSuccess, ...args },
  { dispatch }
) => {
  try {
    const result = await axiosInstance(args);

    if (onSuccess) {
      try {
        await onSuccess(dispatch, result.data);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error in onSuccess method', e);
        throw e;
      }
    }

    return { data: result.data };
  } catch (axiosError) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const err = axiosError as AxiosError<any, any>;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};
