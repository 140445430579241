import {
  Filter,
  FILTER_OPERATOR,
  FILTER_TYPE,
  Meta,
} from 'features/common/types';
import {
  OrderModel,
  orderStatuses,
} from 'features/order/types';
import React, {
  FC,
  useEffect,
} from 'react';
import { usePaginatedQuery } from 'features/common/hooks';
import PageTopBar from 'features/common/layout/PageTopBar';
import { PageContent } from 'features/common/layout/Styled';
import DataTable from 'features/common/components/DataTable';
import { useLazyListOrdersQuery } from 'features/order/api/order';
import { DateTime } from 'luxon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import { Chip } from '@mui/material';
import { formatMoney } from 'features/order/utils/numbers';

const columns = [
  {
    name: 'id',
    label: 'ID',
    sortName: 'o.id',
    minWidth: 70,
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'o.id',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'code',
    label: 'Код заказа',
    sortName: 'o.code',
    minWidth: 200,
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'o.code',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'created_at',
    label: 'Дата создания',
    sortName: 'o.created_at',
    minWidth: 120,
    format(value: string) {
      return (
        <>{
          DateTime.fromISO(value)
            .setLocale('ru')
            .toFormat('f')
        }</>
      );
    },
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'o.created_at',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'status',
    label: 'Статус заказа',
    sortName: 'o.status',
    minWidth: 150,
    format(value: number) {
      const status = orderStatuses.find((item) => item.code === value );
      return (
        <Chip label={status?.label} color={status?.color} size="small" />
      );
    },
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'o.status',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'partner',
    label: 'Партнер',
    sortName: 'o.partner',
    minWidth: 150,
    format: (value: string, item: OrderModel) => (
      <>
        <p>{item.partner.name}</p>
      </>
    ),
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'o.status',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'arrival_date',
    label: 'Дата заезда',
    sortName: 'o.arrival_date',
    minWidth: 120,
    format(value: string) {
      return (
        <>{
          DateTime.fromISO(value)
            .setLocale('ru')
            .toFormat('D')
        }</>
      );
    },
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'o.arrival_date',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'departure_date',
    label: 'Дата выезда',
    sortName: 'o.departure_date',
    minWidth: 120,
    format(value: string) {
      return (
        <>{
          DateTime.fromISO(value)
            .setLocale('ru')
            .toFormat('D')
        }</>
      );
    },
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'o.departure_date',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'hotel',
    label: 'Отель',
    sortName: 'o.hotel',
    minWidth: 200,
    format: (value: string, item: OrderModel) => (
      <>
        <p>{item.hotel.name_ru}</p>
      </>
    ),
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'o.hotel',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'city',
    label: 'Город',
    sortName: 'o.city',
    minWidth: 150,
    format: (value: string, item: OrderModel) => (
      <>
        <p>{item.hotel.city.name_ru}</p>
      </>
    ),
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'o.city',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'name',
    label: 'ФИО',
    sortName: 'o.customer_last_name',
    minWidth: 200,
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'o.customer_last_name',
        filterType: FILTER_TYPE.INPUT,
      },
      {
        operator: FILTER_OPERATOR.SUBSTRING,
        filterPath: 'o.customer_last_name',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
    format: (val: string, item: OrderModel) => (
      <>
        <p>{item.customer_last_name}</p>
        <p>{item.customer_first_name}</p>
        <p>{item.customer_middle_name}</p>
      </>
    ),
  },
  {
    name: 'customer_email',
    label: 'Email заказчика',
    sortName: 'o.customer_email',
    minWidth: 200,
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'o.email',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'price',
    label: 'Сумма заказа',
    sortName: 'o.price',
    minWidth: 150,
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'o.price',
        filterType: FILTER_TYPE.INPUT,
      },
      {
        operator: FILTER_OPERATOR.GTE,
        filterPath: 'o.price',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
    format: (val: string, item: OrderModel ) => (
      <>
        {
          formatMoney(Number(item.price.price), item.price.currency)
        }
      </>
    ),
  },
];

const defaultFilter: Filter = {
  filterOperator: FILTER_OPERATOR.FULL,
  filterValue: '',
  filterField: 'code',
  filterPath: 'o.code',
};

const Orders: FC = () => {
  const {
    settings,
    filters,
    handleFiltersChange,
    handleLimitChange,
    handlePageChange,
    handleSortChange,
  } = usePaginatedQuery('-o.id');

  const navigate = useNavigate();
  const menuItems = [
    {
      icon: <InfoOutlinedIcon />,
      name: 'Подробная информация',
      callback: (entry: OrderModel) => navigate(`/orders/${entry.code}`),
    },
  ];

  const [fetchOrders, orders] = useLazyListOrdersQuery();

  useEffect(() => {
    if (settings) {
      fetchOrders(settings);
    }
  }, [settings]);

  return (
    <>
      <PageTopBar
        name="Заказы"
      />
      <PageContent>
        <DataTable<OrderModel>
          columns={columns}
          data={orders.data ? orders.data.data : []}
          sortedBy={settings?.sort ?? null}
          meta={orders.data?.meta as Meta}
          isLoading={orders.isLoading}
          allowDelete={false}
          onLimitChange={handleLimitChange}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          filters={filters}
          defaultFilter={defaultFilter}
          onEditFilters={handleFiltersChange}
          menuItems={menuItems}
        />
      </PageContent>
    </>
  );
};

export default Orders;
