import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from 'app/api';
import {
  RoomAmenitiesRequest,
  RoomAmenity,
} from 'features/dictionary/types';
import { showPopupMessage } from 'features/common/slice';

export const roomAmenityApi = createApi({
  reducerPath: 'roomAmenityApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Amenities'],
  endpoints: (build) => ({
    listRoomAmenities: build.query<RoomAmenity[], { roomId: number }>({
      query: ({ roomId }) => ({ url: `rooms/${roomId}/amenities`, method: 'GET' }),
      providesTags: (result) =>
        result
          ? // successful query
          [
            ...result.map(({ id }) => ({ type: 'Amenities', id } as const)),
            { type: 'Amenities', id: 'LIST' },
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Amenities', id: 'LIST' }` is invalidated
          [{ type: 'Amenities', id: 'LIST' }],
    }),
    updateRoomAmenities: build.mutation<RoomAmenity, { roomId: number; data: RoomAmenitiesRequest }>({
      query: ({ roomId, data }) => ({
        url: `rooms/${roomId}/amenities`,
        method: 'POST',
        data,
      }),
      invalidatesTags: () => [{ type: 'Amenities', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Удобства комнаты обновлены' }));
      },
    }),
  }),
});

export const {
  useLazyListRoomAmenitiesQuery,
  useUpdateRoomAmenitiesMutation,
} = roomAmenityApi;
