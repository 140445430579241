import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import DataTable from 'features/common/components/DataTable';
import { usePaginatedQuery } from 'features/common/hooks';
import {
  Filter,
  FILTER_OPERATOR,
  FILTER_TYPE,
  ListSettings,
} from 'features/common/types';
import { useParams } from 'react-router-dom';
import { PageContent } from 'features/common/layout/Styled';
import {
  ProviderLogModel,
  ProviderLogTypeMap,
} from 'features/provider/types';
import {
  useLazyGetProviderLogQuery,
  useLazyListProviderLogsQuery,
} from 'features/provider/api/providerLog';
import { DateTime } from 'luxon';
import { get } from 'lodash';
import {
  Drawer,
  Typography,
} from '@mui/material';
import ProviderLogInfo from 'features/provider/components/ProviderLogInfo';
import { drawerStyles } from 'app/Styled';

const drawerSx = drawerStyles();
const columns = [
  {
    name: 'id',
    label: 'ID',
  },
  {
    name: 'type',
    label: 'Тип',
    format: (value: number) => <>{get(ProviderLogTypeMap, value)}</>,
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'type',
        filterType: FILTER_TYPE.SELECT,
      },
    ],
    options: {
      selectOpts: Object.keys(ProviderLogTypeMap).map(value => ({
        value,
        label: get(ProviderLogTypeMap, value),
      })),
    },
  },
  {
    name: 'request_duration',
    label: 'Время запроса',
    format: (value: string, item: ProviderLogModel) => {
      const date1 = DateTime.fromFormat(item.requested_at.substring(0, item.requested_at.length - 4), 'yyyy-MM-dd hh:mm:ss ZZZ');
      const date2 = DateTime.fromFormat(item.responded_at.substring(0, item.responded_at.length - 4), 'yyyy-MM-dd hh:mm:ss ZZZ');

      const diff = date2.diff(date1, [ 'hours', 'minutes', 'seconds']);

      return <>{`${diff.seconds} секунд`}</>;
    },
  },
  {
    name: 'requested_at',
    label: 'Запрос отправлен',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.GTE,
        filterPath: 'requested_at',
        filterType: FILTER_TYPE.DATE,
      },
      {
        operator: FILTER_OPERATOR.LTE,
        filterPath: 'requested_at',
        filterType: FILTER_TYPE.DATE,
      },
    ],
  },
  {
    name: 'responded_at',
    label: 'Ответ получен',
  },
];

const defaultFilter: Filter = {
  filterOperator: FILTER_OPERATOR.FULL,
  filterValue: '',
  filterField: 'type',
  filterPath: 'type',
};

const ProviderLogs: FC = () => {
  const {
    settings,
    filters,
    handleSelectedItemChange,
    handleFiltersChange,
    handleLimitChange,
    handlePageChange,
    handleSortChange,
  } = usePaginatedQuery('-p.id');
  const [fetchProviderLogs, providerLogs] = useLazyListProviderLogsQuery();
  const [fetchProviderLog, { currentData: providerLog }] = useLazyGetProviderLogQuery();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { id } = useParams();

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    if (settings) {
      handleSelectedItemChange(undefined);
    }
  };

  useEffect(() => {
    const doFetch = async () => {
      if (settings && id) {
        const fetchSettings: ListSettings = { ...settings };
        await fetchProviderLogs({ id: Number(id), settings: fetchSettings }, false);
      }
    };

    doFetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, id]);

  const handleItemEdit = async (item: Partial<ProviderLogModel>) => {
    try {
      await fetchProviderLog({ id: Number(id), logId: Number(item.id) }).unwrap();
      setIsDrawerOpen(true);
      if (item.id) {
        handleSelectedItemChange(Number(item.id));
      }
    // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  useEffect(() => {
    if (settings?.selectedItem) {
      handleItemEdit({ id: settings?.selectedItem });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.selectedItem]);

  return (
    <PageContent>
      <DataTable<ProviderLogModel>
        columns={columns}
        data={providerLogs.currentData ?? []}
        sortedBy={settings?.sort ?? null}
        onClick={handleItemEdit}
        meta={{
          limit: settings?.limit ?? 25,
          page: settings?.page ?? 0,
          total_count: -1,
          total_pages: -1,
        }}
        isLoading={providerLogs.isLoading}
        displayMenu={false}
        allowDelete={false}
        onLimitChange={handleLimitChange}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange}
        filters={filters}
        defaultFilter={defaultFilter}
        onEditFilters={handleFiltersChange}
      />
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        sx={drawerSx}
        variant="temporary"
      >
        <Typography variant="h5" gutterBottom paddingBottom={2}>
            Просмотр лога
        </Typography>
        {providerLog && (
          <ProviderLogInfo
            item={providerLog}
            providerId={Number(id)}
          />
        )}
      </Drawer>
    </PageContent>
  );
};

export default ProviderLogs;
