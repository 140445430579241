import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import { showPopupMessage } from 'features/common/slice';
import {
  ListResponse,
  ListSettings,
} from 'features/common/types';
import {
  HotelModel,
  HotelRequest,
} from 'features/dictionary/types';

export const hotelApi = createApi({
  reducerPath: 'hotelsApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Hotels'],
  endpoints: (build) => ({
    listHotels: build.query<ListResponse<HotelModel>, ListSettings>(
      {
        query: (settings) => ({ url: 'hotels', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.data.map(({ id }) => ({ type: 'Hotels', id } as const)),
              { type: 'Hotels', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Hotels', id: 'LIST' }` is invalidated
            [{ type: 'Hotels', id: 'LIST' }],
      }
    ),
    addHotel: build.mutation<HotelModel, HotelRequest>({
      query: (data) => ({ url: 'hotels', method: 'POST', data }),
      invalidatesTags: [{ type: 'Hotels', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Отель успешно добавлен' }));
      },
    }),
    getHotel: build.query<HotelModel, number>({
      query: (id) => ({ url: `hotels/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'Hotels', id }],
    }),
    updateHotel: build.mutation<HotelModel, { id: number; data: HotelRequest }>({
      query: ({ id, data }) => ({
        url: `hotels/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Hotels', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Отель успешно обновлен' }));
      },
    }),
    deleteHotel: build.mutation<{ success: boolean; id: number }, number>(
      {
        query: (id) => ({ url: `hotels/${id}`, method: 'DELETE' }),
        invalidatesTags: (result, error, id) => [{ type: 'Hotels', id }],
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(showPopupMessage({ text: 'Отель успешно удален' }));
        },
      }
    ),
    updateHotelMainPhoto: build.mutation<HotelModel, { id: number; data: { photo_id: number } }>({
      query: ({ id, data }) => ({
        url: `hotels/${id}/photo`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Hotels', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Главная фотография отеля успешно обновлен' }));
      },
    }),
  }),
});

export const {
  useListHotelsQuery,
  useGetHotelQuery,
  useLazyGetHotelQuery,
  useAddHotelMutation,
  useLazyListHotelsQuery,
  useUpdateHotelMutation,
  useDeleteHotelMutation,
  useUpdateHotelMainPhotoMutation,
} = hotelApi;
