import React, { FC } from 'react';
import {
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface FormFieldPhotosProps {
  htmlFor: string;
  onChange: (formData: FormData) => void;
}

const Input = styled('input')({
  display: 'none',
});

const FormFieldPhotos: FC<FormFieldPhotosProps> = ({
  htmlFor,
  onChange,
}) => {
  const onPhotosSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    Array.from(e.target.files as Iterable<File>).forEach((file: File) => {
      formData.append('files', file);
    });
    onChange(formData);
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h6" gutterBottom paddingY={2}>
        Фотографии
      </Typography>
      <label htmlFor={htmlFor}>
        <Input accept="image/*" id={htmlFor} multiple type="file" value="" onChange={onPhotosSelected} />
        <Button variant="contained" component="span">
          Добавить
        </Button>
      </label>
    </Stack>
  );
};

export default FormFieldPhotos;
