import { ProviderModel } from 'features/provider/types';
import { DateTime } from 'luxon';

export enum BED_TYPE {
  DOUBLE = 'double',
  TWIN = 'twin',
  SINGLE = 'single',
  DORM_BED = 'dorm_bed',
}

export const BedTypeMap = {
  [BED_TYPE.DOUBLE]: 'кровать на двоих',
  [BED_TYPE.TWIN]: 'раздельные кровати',
  [BED_TYPE.SINGLE]: 'одиночная кровать',
  [BED_TYPE.DORM_BED]: 'кровать в общей комнате',
};

export interface CountryModel {
  id?: number;
  name_ru: string;
  name_en: string;
  alpha2: string;
  alpha3: string;
  mappings: ProviderMapping[];
}

export interface ProviderMappingRequest { [key: string]: string }

export interface ProviderMapping {
  Provider?: ProviderModel;
  // entity_id: number;
  // id: number;
  provider_code: string;
  provider_id: number;
}

export interface CountryRequest {
  name_ru: string;
  name_en: string;
  alpha2: string;
  alpha3: string;
  mappings: ProviderMappingRequest | ProviderMapping[];
}

export interface CityModel {
  id?: number;
  country: CountryModel;
  region: RegionModel | null ;
  name_ru: string;
  name_en: string;
  updated_at: string;
  country_id: number;
  region_id: number | null;
  mappings: ProviderMapping[];
  photo: CityPhotoModel;
  photo_id: number;
  photos: CityPhotoModel[];
}

export interface CityPhotoModel {
  id: number;
  city_id: number;
  description_ru: string;
  description_en: string;
  url: string;
  width: number;
  height: number;
  file_size: number;
  mime_type: string;
  deleted_at?: string | null;
}

export interface CityRequest {
  name_ru: string;
  name_en: string;
  country_id: number;
  region_id: number | null;
  mappings: ProviderMappingRequest | ProviderMapping[];
}

export interface RegionModel {
  id?: number;
  country: CountryModel;
  name_ru: string;
  name_en: string;
  country_id: number;
  mappings: ProviderMapping[];
}

export interface RegionRequest {
  name_ru: string;
  name_en: string;
  country_id: number;
  mappings: ProviderMappingRequest | ProviderMapping[];
}

export type PhotoModel = HotelPhotoModel | CityPhotoModel | RoomPhotoModel;

export interface HotelPhotoModel {
  id: number;
  hotel_id: number;
  description_ru: string;
  description_en: string;
  url: string;
  width: number;
  height: number;
  file_size: number;
  mime_type: string;
  deleted_at?: string | null;
}

export interface PhotoRequest {
  description_ru: string;
}

export type PaymentSystems = 'mastercard' | 'visa' | 'mir' | 'cup' | 'jcb';

export interface HotelModel {
  id?: number;
  address_en: string;
  address_ru: string;
  category: number;
  city: CityModel;
  city_id: number;
  coordinates: {
    longitude: number | null;
    latitude: number | null;
  };
  description: {
    check_in_time: string | DateTime | null;
    check_out_time: string | DateTime | null;
    description_en: string;
    description_ru: string;
    email: string;
    phone: string;
    website: string;
  };
  deleted_at: string;
  mappings: ProviderMapping[];
  name_en: string;
  name_ru: string;
  photo: HotelPhotoModel;
  photo_id: number;
  photos: HotelPhotoModel[];
  options: number;
  type: HotelType;
  type_id: number;
  payment_systems: PaymentSystems[];
}

export interface HotelRequest {
  address_en: string;
  address_ru: string;
  category: number;
  city_id: number;
  coordinates: {
    latitude?: number | null;
    longitude?: number | null;
  };
  description: {
    check_in_time: string | DateTime;
    check_out_time: string | DateTime;
    description_en?: string | null;
    description_ru: string | null;
    email?: string | null;
    phone?: string | null;
    website?: string | null;
  };
  name_en: string;
  name_ru: string;
  mappings: ProviderMappingRequest | ProviderMapping[];
  type_id: number;
  payment_systems: PaymentSystems[];
}

export interface HotelType {
  id: number;
  name_en: string;
  name_ru: string;
}

export interface HotelTypeRequest {
  name_en: string;
  name_ru: string;
}

export interface FacilityCategoryModel {
  id?: number;
  name_ru: string;
  name_en: string;
}

export interface FacilityCategoryRequest {
  name_ru: string;
  name_en: string;
}

export type FacilityType = 'hotel' | 'room';

export interface FacilityModel {
  id?: number;
  code: string | null;
  category: FacilityCategoryModel | null;
  category_id: number | null;
  name_ru: string;
  name_en: string;
  type: FacilityType;
}

export interface FacilityRequest {
  name_ru: string;
  name_en: string;
  code: string | null;
  type: FacilityType;
  category_id: number | null;
}

export type FacilityTypeMapping = {
  [key in FacilityType]: string;
};

export interface HotelFacilitiesRequest {
  facilities: number[];
}

export interface HotelRoomModel {
  id: number;
  bed_type: BED_TYPE;
  deleted_at: string;
  description_en: string;
  description_ru: string;
  extra_bed_count: number;
  hotel_id: number;
  mappings: ProviderMapping[];
  name_en: string;
  name_ru: string;
  occupancy: number;
  photo: RoomPhotoModel;
  photo_id: number;
  room_type: RoomType;
  room_type_id: number;
  area?: number;
}

export interface RoomRequest {
  bed_type: BED_TYPE;
  description_en: string;
  description_ru: string;
  extra_bed_count: number;
  mappings: ProviderMappingRequest | ProviderMapping[];
  name_en: string;
  name_ru: string;
  occupancy: number;
  room_type_id: number;
  area?: number;
}

export interface RoomPhotoModel {
  id: number;
  room_id: number;
  description_ru: string;
  description_en: string;
  url: string;
  width: number;
  height: number;
  file_size: number;
  mime_type: string;
  deleted_at?: string | null;
}

export interface RoomType {
  id: number;
  name_en: string;
  name_ru: string;
}

export interface RoomTypeRequest {
  name_en: string;
  name_ru: string;
}

export interface RoomAmenity {
  id: number;
  code: string;
  deleted_at: string;
  name_en: string;
  name_ru: string;
  type: string;
}

export interface RoomAmenitiesRequest {
  amenities: number[];
}
