import React, {
  useEffect,
  useState,
} from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { PageContent } from 'features/common/layout/Styled';
import PageTopBar from 'features/common/layout/PageTopBar';
import {
  PartnerProviderModel,
  PartnerProviderRequest,
} from 'features/partner/types';
import {
  useAddPartnerProviderMutation,
  useLazyListPartnerProvidersQuery,
  useUpdatePartnerProviderCredentialsMutation,
  useUpdatePartnerProviderMutation,
} from 'features/partner/api/partnerProvider';
import { ConfirmDialog } from 'features/common/components/ConfirmDialog';
import PartnerProviderForm from 'features/partner/components/PartnerProviderForm';
import { useLazySearchProvidersQuery } from 'features/provider/api/provider';

interface PartnerProvidersProps {
  idPartner: number;
}

const PartnerProviders = (props: PartnerProvidersProps) => {
  const {
    idPartner,
  } = props;
  const displayMenu = true;

  const [ menuAnchor, setMenuAnchor ] = useState<null | HTMLElement>(null);
  const [ menuEntry, setMenuEntry ] = useState<PartnerProviderModel | null>(null);
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const [ dialogPartnerProviderOpen, setDialogPartnerProviderOpen ] = useState(false);
  const [ editPartnerProvider, seteditPartnerProvider ] = useState<PartnerProviderModel | null>(null);
  const isMenuOpen = Boolean(menuAnchor);

  const [ fetchProviders, providers ] = useLazySearchProvidersQuery();
  const [ fetchPartnerProviders, partnerProviders ] = useLazyListPartnerProvidersQuery();
  const [ updatePartnerProvider ] = useUpdatePartnerProviderMutation();
  const [ updatePartnerProviderCredentials ] = useUpdatePartnerProviderCredentialsMutation();
  const [ addPartnerProvider ] = useAddPartnerProviderMutation();

  useEffect(() => {
    fetchPartnerProviders(idPartner);
    fetchProviders('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdate = async (data: PartnerProviderRequest, credentials?: {[credentialsFieldName: string]: string} | null) => {
    await updatePartnerProvider(data);
    if (credentials) {
      await updatePartnerProviderCredentials(data);
    }
    setDialogPartnerProviderOpen(false);
  };

  const handleAdd = async (data: PartnerProviderRequest) => {
    data.partner_id = idPartner;
    await addPartnerProvider(data);
    setDialogPartnerProviderOpen(false);
  };

  const onMenuClick = (e: React.MouseEvent<HTMLElement>, entry: PartnerProviderModel) => {
    setMenuAnchor(e.currentTarget);
    setMenuEntry(entry);
  };

  const onCloseMenu = () => {
    setMenuAnchor(null);
    setMenuEntry(null);
  };

  const confirmDelete = async () => {
    setDialogOpen(false);
  };

  const onConfirmDialogOpen = () => {
    setDialogOpen(true);
    setMenuAnchor(null);
  };

  const handleEditEntry = (item: PartnerProviderModel) => {
    seteditPartnerProvider(item);
    setDialogPartnerProviderOpen(true);
    setMenuAnchor(null);
  };

  const handleAddEntry = () => {
    seteditPartnerProvider(null);
    setDialogPartnerProviderOpen(true);
    setMenuAnchor(null);
  };

  const mapEntityToRequest = (item: PartnerProviderModel, active?: boolean) => {
    const request = {
      id: item.id,
      active: active !== undefined ? active : item.active,
      partner_id: item.partner_id as number,
      credentials: item.credentials,
      provider_id: item.provider_id as number,
    };
    return request;
  };

  return (
    <>
      <PageTopBar
        name="Список поставщиков"
        actions={(
          <Button
            variant="contained"
            color="success"
            onClick={() => handleAddEntry()}
          >
            Добавить поставщика
          </Button>
        )}
      />
      <PageContent>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            {(partnerProviders.data && partnerProviders.data.length > 0) ? (
              <>
                <TableBody>
                  {
                    partnerProviders.data.map((item, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell>
                          {
                            providers.data?.find((provider) => (provider.id as number) === item.provider_id)?.name
                          }
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={item.active}
                                onChange={(e) =>
                                  handleUpdate(mapEntityToRequest(item, e.target.checked))
                                }
                                name={'active' + index} />
                            }
                            label="Включен"
                          />
                        </TableCell>
                        {
                          displayMenu &&
                          <TableCell>
                            <IconButton onClick={e => onMenuClick(e, item)}>
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                        }
                      </TableRow>
                    ))}
                </TableBody>
              </>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell className="description" colSpan={3}>
                    Объекты не найдены
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Menu
          anchorEl={menuAnchor}
          open={isMenuOpen}
          onClose={onCloseMenu}
        >
          <MenuItem onClick={() => handleEditEntry(menuEntry as PartnerProviderModel)} disableRipple>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>
              Редактировать
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => onConfirmDialogOpen()} disableRipple>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>
              Удалить
            </ListItemText>
          </MenuItem>
        </Menu>
        <ConfirmDialog
          isOpen={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onConfirm={confirmDelete}
        />
        <Dialog
          open={dialogPartnerProviderOpen}
          onClose={() => setDialogPartnerProviderOpen(false)}
          aria-labelledby="alert-dialog-filters-title"
          fullWidth
          maxWidth='md'
        >
          <DialogTitle id="alert-dialog-title">
            {'Фильтры'}
          </DialogTitle>
          <DialogContent>
            <PartnerProviderForm
              partnerId={idPartner}
              connectedProviders={partnerProviders.data as PartnerProviderModel[]}
              providerId={editPartnerProvider?.provider_id}
              partnerProviderId={editPartnerProvider?.id}
              onAdd={handleAdd}
              onEdit={handleUpdate}
              onCancel={() => setDialogPartnerProviderOpen(false)}
              isLoading={false}
            />
          </DialogContent>
        </Dialog>
      </PageContent>
    </>
  );
};

export default PartnerProviders;
