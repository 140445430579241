import {
  configureStore,
  combineReducers,
  PreloadedState,
} from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authApi } from 'features/auth/api';
import auth from 'features/auth/slice';
import common from 'features/common/slice';
import { providerApi } from 'features/provider/api/provider';
import { mappingTaskApi } from 'features/provider/api/mappingTask';
import { providerTaskApi } from 'features/provider/api/providerTask';
import { partnerApi } from 'features/partner/api/partner';
import { partnerProvidersApi } from 'features/partner/api/partnerProvider';
import { countryApi } from 'features/dictionary/api/country';
import { cityApi } from 'features/dictionary/api/city';
import { regionApi } from 'features/dictionary/api/region';
import { hotelApi } from 'features/dictionary/api/hotel';
import { providerLogApi } from 'features/provider/api/providerLog';
import { hotelPhotoApi } from 'features/dictionary/api/hotelPhoto';
import { facilityApi } from 'features/dictionary/api/facility';
import { hotelFacilityApi } from 'features/dictionary/api/hotelFacility';
import { cityPhotoApi } from 'features/dictionary/api/cityPhoto';
import { hotelRoomApi } from 'features/dictionary/api/hotelRoom';
import { userApi } from 'features/user/api/user';
import { roomTypesApi } from 'features/dictionary/api/roomTypes';
import { roomPhotoApi } from 'features/dictionary/api/roomPhoto';
import { roomAmenityApi } from 'features/dictionary/api/roomAmenity';
import { orderApi } from 'features/order/api/order';
import { facilityCategoryApi } from 'features/dictionary/api/facilityCategory';
import { hotelTypesApi } from 'features/dictionary/api/hotelTypes';
import { roleApi } from 'features/user/api/role';

const persistConfig = {
  key: 'auth',
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(persistConfig, auth),
  common,
  [authApi.reducerPath]: authApi.reducer,
  [countryApi.reducerPath]: countryApi.reducer,
  [regionApi.reducerPath]: regionApi.reducer,
  [cityApi.reducerPath]: cityApi.reducer,
  [providerApi.reducerPath]: providerApi.reducer,
  [mappingTaskApi.reducerPath]: mappingTaskApi.reducer,
  [providerTaskApi.reducerPath]: providerTaskApi.reducer,
  [providerLogApi.reducerPath]: providerLogApi.reducer,
  [partnerApi.reducerPath]: partnerApi.reducer,
  [partnerProvidersApi.reducerPath]: partnerProvidersApi.reducer,
  [hotelApi.reducerPath]: hotelApi.reducer,
  [hotelPhotoApi.reducerPath]: hotelPhotoApi.reducer,
  [facilityApi.reducerPath]: facilityApi.reducer,
  [hotelFacilityApi.reducerPath]: hotelFacilityApi.reducer,
  [cityPhotoApi.reducerPath]: cityPhotoApi.reducer,
  [hotelRoomApi.reducerPath]: hotelRoomApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [roomTypesApi.reducerPath]: roomTypesApi.reducer,
  [roomPhotoApi.reducerPath]: roomPhotoApi.reducer,
  [roomAmenityApi.reducerPath]: roomAmenityApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [facilityCategoryApi.reducerPath]: facilityCategoryApi.reducer,
  [hotelTypesApi.reducerPath]: hotelTypesApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authApi.middleware,
      countryApi.middleware,
      regionApi.middleware,
      cityApi.middleware,
      providerApi.middleware,
      mappingTaskApi.middleware,
      providerTaskApi.middleware,
      providerLogApi.middleware,
      partnerApi.middleware,
      partnerProvidersApi.middleware,
      hotelApi.middleware,
      hotelTypesApi.middleware,
      hotelPhotoApi.middleware,
      facilityApi.middleware,
      hotelFacilityApi.middleware,
      cityPhotoApi.middleware,
      hotelRoomApi.middleware,
      userApi.middleware,
      roleApi.middleware,
      roomTypesApi.middleware,
      roomPhotoApi.middleware,
      roomAmenityApi.middleware,
      orderApi.middleware,
      facilityCategoryApi.middleware,
    ]),
  preloadedState,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
