import React, {
  HTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
  CircularProgress,
  FilterOptionsState,
  TextField,
} from '@mui/material';
import { BaseModel } from 'features/common/types';
import {
  throttle,
  trim,
} from 'lodash';

export interface FormAutocompleteProps<T> {
  field: string;
  label: string;
  selectedOption?: T;
  list: T[];
  fetchList?: (search: string) => void;
  isFetching: boolean;
  error: boolean | undefined;
  helperText: string | false | undefined;
  disabled?: boolean;
  filterOptions: (options: T[], state: FilterOptionsState<T>) => T[];
  comparator: (option: T, value: T) => boolean;
  getOptionLabel: (option: T) => string;
  handleChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: T | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<T> | undefined
  ) => void;
  renderOption?: (props: HTMLAttributes<HTMLLIElement>, option: T, state: AutocompleteRenderOptionState) => ReactNode;
  disableClearable?: boolean;
}

const FormAutocomplete = <T extends BaseModel, >(fieldProps: FormAutocompleteProps<T>) => {
  const {
    field,
    label,
    selectedOption,
    error,
    helperText,
    disabled,
    filterOptions,
    comparator,
    getOptionLabel,
    handleChange,
    renderOption,
    fetchList,
    isFetching,
    list,
    disableClearable = false,
  } = fieldProps;

  const [inputValue, setInputValue] = useState('');

  const doFetch = useMemo(
    () =>
      throttle(async (search: string) => {
        if (fetchList) {
          await fetchList(search);
        }
      }, 50),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [module]
  );

  const onChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: T | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T> | undefined
  ) => {
    setInputValue(value ? getOptionLabel(value) : '');
    handleChange(event, value, reason, details);
  };

  const onInputChange = (event: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
    if (event && event.type === 'change' && fetchList) {
      setInputValue(newInputValue);
    }
  };

  useEffect(() => {
    setInputValue(selectedOption ? getOptionLabel(selectedOption) : '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInputValue, selectedOption]);


  useEffect(() => {
    const search = trim(inputValue);
    if (search === '' && inputValue.length > 0 || !fetchList) {
      return;
    }

    doFetch(search);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, fetch, selectedOption]);

  return (
    <Autocomplete
      id={field}
      isOptionEqualToValue={comparator}
      getOptionLabel={getOptionLabel}
      options={list as T[]}
      loading={isFetching}
      disabled={disabled}
      disableClearable={disableClearable}
      inputValue={inputValue}
      onInputChange={onInputChange}
      value={selectedOption || null}
      onChange={onChange}
      filterOptions={filterOptions}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default FormAutocomplete;
