import React, {
  useEffect,
  useState,
} from 'react';
import {
  CardContent,
  CardActions,
  Button,
  Card,
} from '@mui/material';
import { TableColumn } from 'features/common/components/DataTable';
import {
  BaseModel,
  Filter,
} from 'features/common/types';
import FilterForm from 'features/common/components/FilterForm';

interface FiltersProps<T> {
  filterable_columns: Array<TableColumn<T>>;
  filters: Filter[];
  defaultFilter: Filter;
  onEditFilters: (value: Filter[]) => void;
  onCleanFilters: () => void;
}

function FiltersCard<T extends BaseModel>(props: FiltersProps<T>) {
  const {
    filterable_columns,
    filters,
    defaultFilter,
    onEditFilters,
    onCleanFilters,
  } = props;

  const [filterList, setFilterList] = useState(filters);
  const [filterableColumns, setFilterableColumns] = useState<Array<TableColumn<T>>>(filterable_columns);

  useEffect(() => {
    setFilterableColumns(filterable_columns.filter(item => !!item.filterableOperators));
  }, [filterable_columns]);

  const handleFilters = (value: Filter, idx: number) => {
    const newFilters = filterList.map((item, index) => {
      if (index === idx) {
        item = value;
      }
      return item;
    });
    setFilterList(newFilters);
  };

  const handleAddFilter = () => {
    if (filterList.length === filterableColumns.length) {
      return;
    }
    setFilterList([...filterList, defaultFilter]);
  };

  const handleDeleteFilter = (idx: number) => {
    let newFilters = [...filterList];
    if (newFilters.length < 2) {
      newFilters = [];
      onCleanFilters();
    } else {
      newFilters.splice(idx, 1);
    }
    setFilterList(newFilters);
  };

  const handleCleanFilter = () => {
    onCleanFilters();
  };

  const confirmFilters = () => {
    onEditFilters(filterList);
  };

  return (
    <Card>
      <CardContent>
        {filterList.map((item, index) => (
          <FilterForm<T>
            key={item.filterField + index}
            filterIdx={index}
            filterable_columns={filterableColumns}
            filter={item}
            onEditFilter={handleFilters}
            onDeleteFilter={handleDeleteFilter}
          />
        ))}
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          color="success"
          disabled={filterList.length === filterableColumns.length}
          onClick={handleAddFilter} autoFocus
        >
          добавить фильтр
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleCleanFilter()}
        >
          Очистить
        </Button>
        <Button
          variant="contained"
          onClick={() => confirmFilters()}
        >
          Применить
        </Button>
      </CardActions>
    </Card>
  );
}

export default FiltersCard;
