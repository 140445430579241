import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import DataTable from 'features/common/components/DataTable';
import { usePaginatedQuery } from 'features/common/hooks';
import {
  Filter,
  FILTER_OPERATOR,
  FILTER_TYPE,
  Meta,
} from 'features/common/types';
import { PageContent } from 'features/common/layout/Styled';
import PageTopBar from 'features/common/layout/PageTopBar';
import {
  useAddCountryMutation,
  useLazyGetCountryQuery,
  useLazyListCountriesQuery,
  useUpdateCountryMutation,
} from 'features/dictionary/api/country';
import {
  CountryModel,
  CountryRequest,
  ProviderMapping,
} from 'features/dictionary/types';
import {
  Button,
  Drawer,
  Typography,
} from '@mui/material';
import CountryForm from 'features/dictionary/components/CountryForm';
import { drawerStyles } from 'app/Styled';

const drawerSx = drawerStyles();

const renderMappings = (mappings: { [key: string]: string } | ProviderMapping[]) => {
  if (Array.isArray(mappings)) {
    return mappings.map(item => <p key={item.provider_id}>{`${item.provider_code}(${item.Provider?.name})`}</p>);
  }

  return Object.keys(mappings).map(key => <p key={key}>{`${mappings[key]}(${key})`}</p>);
};

const columns = [
  {
    name: 'id',
    label: 'ID',
    sortName: 'co.id',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'co.id',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'mappings',
    label: 'Коды поставщиков',
    sortName: 'com',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'co.id',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
    format: (val: string, item: CountryModel) => (
      <>
        {item.mappings && renderMappings(item.mappings)}
      </>
    ),
  },
  {
    name: 'name',
    label: 'Название',
    sortName: 'co.name_ru',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'co.name_ru',
        filterType: FILTER_TYPE.INPUT,
      },
      {
        operator: FILTER_OPERATOR.SUBSTRING,
        filterPath: 'co.name_ru',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
    format: (val: string, item: CountryModel) => (
      <>
        <p>{item.name_ru}</p>
        <p>{item.name_en}</p>
      </>
    ),
  },
  {
    name: 'code',
    label: 'Код',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'co.alpha2',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
    format: (val: string, item: CountryModel) => (
      <>
        <p>Alpha2: {item.alpha2}</p>
        <p>Alpha3: {item.alpha3}</p>
      </>
    ),
  },
];

const defaultFilter: Filter = {
  filterOperator: FILTER_OPERATOR.FULL,
  filterValue: '',
  filterField: 'name',
  filterPath: 'co.name_ru',
};

const Countries: FC = () => {
  const {
    settings,
    filters,
    handleSelectedItemChange,
    handleFiltersChange,
    handleLimitChange,
    handlePageChange,
    handleSortChange,
  } = usePaginatedQuery('-co.id');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchCountries, countries] = useLazyListCountriesQuery();
  const [addCountry, { isSuccess: isAdded, isLoading: isLoadingAdd }] = useAddCountryMutation();
  const [updateCountry, { isSuccess: isUpdated, isLoading: isLoadingUpdate }] = useUpdateCountryMutation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [fetchCountry, { currentData: country }] = useLazyGetCountryQuery();
  const [ isCreating, setIsCreating ] = useState(false);

  const handleCreating = () => {
    setIsDrawerOpen(true);
    setIsCreating(true);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleDrawerClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'manual') => {
    if (reason !== 'manual') {
      return;
    }
    setIsCreating(false);
    setIsDrawerOpen(false);
    if (settings) {
      handleSelectedItemChange(undefined);
    }
  };

  useEffect(() => {
    handleDrawerClose({}, 'manual');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, isUpdated]);

  const handleItemEdit = async (item: Partial<CountryModel>) => {
    try {
      await fetchCountry(Number(item.id)).unwrap();
      setIsDrawerOpen(true);
      if (item.id) {
        handleSelectedItemChange(item.id);
      }
    // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleCountrySave = async (data: CountryRequest) => {
    if (!isCreating) {
      await updateCountry({ id: Number(country?.id), data });
    } else {
      await addCountry(data);
    }
  };

  useEffect(() => {
    if (settings) {
      fetchCountries(settings);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (settings?.selectedItem) {
      handleItemEdit({ id: settings?.selectedItem });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.selectedItem]);

  return (
    <>
      <PageTopBar
        name="Страны"
        actions={(
          <Button
            variant="contained"
            color="success"
            onClick={handleCreating}
          >
            Добавить
          </Button>
        )}
      />
      <PageContent>
        <DataTable<CountryModel>
          columns={columns}
          data={countries.data?.data ?? []}
          onEditEntry={handleItemEdit}
          sortedBy={settings?.sort ?? null}
          meta={countries.data?.meta as Meta}
          isLoading={countries.isLoading}
          allowDelete={false}
          onLimitChange={handleLimitChange}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          filters={filters}
          defaultFilter={defaultFilter}
          onEditFilters={handleFiltersChange}
        />
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          sx={drawerSx}
          variant="temporary"
        >
          <Typography variant="h5" gutterBottom paddingBottom={2}>
            {isCreating ? 'Создание страны' : 'Редактирование страны'}
          </Typography>
          <CountryForm
            isLoading={isLoadingAdd || isLoadingUpdate}
            onSuccess={handleCountrySave}
            onCancel={() => handleDrawerClose({}, 'manual')}
            model={country}
          />
        </Drawer>
      </PageContent>
    </>
  );
};

export default Countries;
