import { useAppSelector } from 'app/hooks';
import {
  FormErrors,
  selectFormErrors,
} from 'features/common/slice';
import {
  Filter,
  FILTER_OPERATOR,
} from 'features/common/types';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

export const useFormErrors = (setErrors: (errors: FormErrors) => void) => {
  const errors = useAppSelector(selectFormErrors);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    }
  }, [setErrors, errors]);
};

export const generateFilterString = (filters: Filter[]) => {
  const filterStr = filters.reduce((acc, config) => {
    if (acc.includes(config.filterPath) || config.filterValue === '') {
      return acc;
    }

    if (acc) {
      acc += '&';
    }

    let operator = '';
    let postOperator = '';

    switch (config.filterOperator) {
    case FILTER_OPERATOR.FULL:
      postOperator = '';
      operator = '=';
      break;
    case FILTER_OPERATOR.SUBSTRING:
      postOperator = '';
      operator = '?=';
      break;
    case FILTER_OPERATOR.NOT_EQUAL:
      postOperator = '';
      operator = '!=';
      break;
    case FILTER_OPERATOR.GT:
      operator = '>';
      postOperator = '';
      break;
    case FILTER_OPERATOR.GTE:
      operator = '>=';
      postOperator = '';
      break;
    case FILTER_OPERATOR.LT:
      operator = '<';
      postOperator = '';
      break;
    case FILTER_OPERATOR.LTE:
      operator = '<=';
      postOperator = '';
      break;
    case FILTER_OPERATOR.IS:
      operator = 'is';
      postOperator = '';
      break;
    case FILTER_OPERATOR.IS_NOT:
      operator = 'is not';
      postOperator = '';
      break;
    }

    acc += `${config.filterPath}${operator}${postOperator}${config.filterValue}`;
    return acc;
  }, '');

  if (filterStr) {
    return encodeURIComponent(filterStr);
  } else {
    return null;
  }
};

export const generateFiltersList = (filterStr: string) => {
  const filters = JSON.parse(filterStr);
  return Object.keys(filters)
    .filter((key) => filters[key] !== null && filters[key] !== undefined && filters[key] !== '')
    .map((key) => {
      const config = filters[key].split('/');
      return {
        filterPath: config[0],
        filterOperator: config[1],
        filterValue: config[2],
        filterField: key,
      };
    });
};

export const usePaginatedQuery = (sortField: string) => {
  const [inited, setInited] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [selectedItem, setSelectedItem] = useState<number>();
  const [sort, setSort] = useState<string | null>(null);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (inited) {
      const test: {[key: string]: string} = {};
      filters.forEach((item) => {
        if (item.filterValue !== '') {
          test[item.filterField] = `${item.filterPath}/${item.filterOperator}/${item.filterValue}`;
        }
      });
      const filtersObj: { [key: string]: string } = {
        page: String(page),
        limit: String(limit),
        sort: String(sort),
      };

      if (selectedItem) {
        filtersObj['selected_item'] = String(selectedItem);
      }

      if (Object.keys(test).length > 0) {
        filtersObj.filter = JSON.stringify(test);
      }

      setSearchParams(filtersObj, { replace: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, sort, filters, selectedItem]);

  useEffect(() => {
    if (searchParams.has('page')) {
      setPage(Number(searchParams.get('page')));
    }

    if (searchParams.has('limit')) {
      setLimit(Number(searchParams.get('limit')));
    }

    if (searchParams.has('selected_item')) {
      setSelectedItem(Number(searchParams.get('selected_item')));
    }

    if (searchParams.has('sort')) {
      setSort(searchParams.get('sort'));
    } else {
      setSort(sortField);
    }

    if (searchParams.has('filter')) {
      const str = searchParams.get('filter');
      if (str !== null) {
        setFilters(generateFiltersList(str));
      }
    }
    setInited(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => ({
    settings: inited ? {
      sort,
      limit,
      page,
      selectedItem,
      filter: generateFilterString(filters),
    } : undefined,
    filters,
    handleFiltersChange: (value: Filter[]) => setFilters(value),
    handleLimitChange: (value: number) => setLimit(value),
    handlePageChange: (value: number) => setPage(value),
    handleSortChange: (value: string) => setSort(value),
    handleSelectedItemChange: (value: number | undefined) => setSelectedItem(value),
  }), [sort, limit, selectedItem, page, filters, inited]);
};
