import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import DataTable from 'features/common/components/DataTable';
import { usePaginatedQuery } from 'features/common/hooks';
import {
  Filter,
  FILTER_OPERATOR,
  FILTER_TYPE,
  Meta,
} from 'features/common/types';
import { PageContent } from 'features/common/layout/Styled';
import PageTopBar from 'features/common/layout/PageTopBar';
import {
  ProviderMapping,
  RegionModel,
  RegionRequest,
} from 'features/dictionary/types';
import {
  Button,
  Drawer,
  Typography,
} from '@mui/material';
import {
  useAddRegionMutation,
  useLazyGetRegionQuery,
  useLazyListRegionsQuery,
  useUpdateRegionMutation,
} from 'features/dictionary/api/region';
import RegionForm from 'features/dictionary/components/RegionForm';
import { drawerStyles } from 'app/Styled';

const drawerSx = drawerStyles();

const renderMappings = (mappings: { [key: string]: string } | ProviderMapping[]) => {
  if (Array.isArray(mappings)) {
    return mappings.map(item => <p key={item.provider_id}>{`${item.provider_code}(${item.Provider?.name})`}</p>);
  }

  return Object.keys(mappings).map(key => <p key={key}>{`${mappings[key]}(${key})`}</p>);
};

const columns = [
  {
    name: 'id',
    label: 'ID',
    sortName: 'reg.id',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'reg.id',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'mappings',
    label: 'Коды поставщиков',
    sortName: 'com',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'reg.id',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
    format: (val: string, item: RegionModel) => (
      <>
        {item.mappings && renderMappings(item.mappings)}
      </>
    ),
  },
  {
    name: 'name',
    label: 'Название',
    sortName: 'reg.name_ru',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'reg.name_ru',
        filterType: FILTER_TYPE.INPUT,
      },
      {
        operator: FILTER_OPERATOR.SUBSTRING,
        filterPath: 'reg.name_ru',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
    format: (val: string, item: RegionModel) => (
      <>
        <p>{item.name_ru}</p>
        <p>{item.name_en}</p>
      </>
    ),
  },
  {
    name: 'country.name_ru',
    label: 'Страна',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'co.alpha2',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
];

const defaultFilter: Filter = {
  filterOperator: FILTER_OPERATOR.FULL,
  filterValue: '',
  filterField: 'name',
  filterPath: 'reg.name_ru',
};

const Regions: FC = () => {
  const {
    settings,
    filters,
    handleSelectedItemChange,
    handleFiltersChange,
    handleLimitChange,
    handlePageChange,
    handleSortChange,
  } = usePaginatedQuery('-reg.id');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ fetchRegions, regions ] = useLazyListRegionsQuery();
  const [updateRegion, { isSuccess: isUpdated, isLoading: isUpdateLoading }] = useUpdateRegionMutation();
  const [addRegion, { isSuccess: isAdded, isLoading: isAddLoading }] = useAddRegionMutation();
  const [ fetchRegion, { currentData: region } ] = useLazyGetRegionQuery();
  const [ isDrawerOpen, setIsDrawerOpen ] = useState<boolean>(false);
  const [ isCreating, setIsCreating ] = useState(false);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleDrawerClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'manual') => {
    if (reason !== 'manual') {
      return;
    }
    setIsCreating(false);
    setIsDrawerOpen(false);
    if (settings) {
      handleSelectedItemChange(undefined);
    }
  };
  useEffect(() => {
    handleDrawerClose({}, 'manual');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, isUpdated]);

  const handleItemEdit = async (item: Partial<RegionModel>) => {
    try {
      await fetchRegion(Number(item.id)).unwrap();
      setIsDrawerOpen(true);
      if (item.id) {
        handleSelectedItemChange(item.id);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleCreate = () => {
    setIsCreating(true);
    setIsDrawerOpen(true);
  };

  const handleRegionUpdate = async (data: RegionRequest) => await updateRegion({ id: Number(region?.id), data });
  const handleRegionAdd = async (data: RegionRequest) => await addRegion(data);

  useEffect(() => {
    if (settings) {
      fetchRegions(settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (settings?.selectedItem) {
      handleItemEdit({ id: settings?.selectedItem });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.selectedItem]);

  return (
    <>
      <PageTopBar
        name="Регионы"
        actions={(
          <Button
            variant="contained"
            color="success"
            onClick={handleCreate}
          >
            Добавить
          </Button>
        )}
      />
      <PageContent>
        <DataTable<RegionModel>
          columns={columns}
          data={regions.data?.data ?? []}
          onEditEntry={handleItemEdit}
          sortedBy={settings?.sort ?? null}
          meta={regions.data?.meta as Meta}
          isLoading={regions.isLoading}
          allowDelete={false}
          onLimitChange={handleLimitChange}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          filters={filters}
          defaultFilter={defaultFilter}
          onEditFilters={handleFiltersChange}
        />
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          sx={drawerSx}
          variant="temporary"
        >
          <Typography variant="h5" gutterBottom paddingBottom={2}>
            {isCreating ? 'Создание региона' : 'Редактирование региона'}
          </Typography>
          <RegionForm
            isLoading={isUpdateLoading || isAddLoading}
            onSuccess={isCreating ? handleRegionAdd : handleRegionUpdate}
            onCancel={() => handleDrawerClose({}, 'manual')}
            model={isCreating ? undefined : region}
          />
        </Drawer>
      </PageContent>
    </>
  );
};

export default Regions;
