import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  HotelRoomModel,
  RoomPhotoModel,
  RoomRequest,
} from 'features/dictionary/types';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  useDeleteHotelRoomMutation,
  useLazyGetRoomQuery,
  useLazyListHotelRoomsQuery,
  useUpdateHotelRoomMutation,
  useUploadHotelRoomsMutation,
} from 'features/dictionary/api/hotelRoom';
import getPhotoPreviewUrl from 'features/dictionary/utils/photoPreviewUrl';
import { ConfirmDialog } from 'features/common/components/ConfirmDialog';
import RoomForm from 'features/dictionary/components/RoomForm';
import { useUploadRoomPhotosMutation } from 'features/dictionary/api/roomPhoto';
import FormFieldPhotos from 'features/common/components/FormFieldPhotos';
import RoomPhotos from 'features/dictionary/components/RoomPhotos';
import { useListFacilitiesQuery } from 'features/dictionary/api/facility';
import {
  useLazyListRoomAmenitiesQuery,
  useUpdateRoomAmenitiesMutation,
} from 'features/dictionary/api/roomAmenity';
import { LoadingButton } from '@mui/lab';
import { Save as SaveIcon } from '@mui/icons-material';
import { useLazySearchRoomTypesQuery } from 'features/dictionary/api/roomTypes';
interface RoomsProps {
  hotelId: number;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const HotelRooms: FC<RoomsProps> = ({ hotelId }) => {
  const [ open, setOpen] = useState(false);
  const [ fetchRoom, { currentData: room } ] = useLazyGetRoomQuery();
  const [ addRoom, { isSuccess: isAdded, isLoading: isLoadingAdd } ] = useUploadHotelRoomsMutation();
  const [ updateRoom, { isSuccess: isUpdated, isLoading: isLoadingUpdate } ] = useUpdateHotelRoomMutation();
  const [ fetchHotelRooms, { data: rooms, isLoading } ] = useLazyListHotelRoomsQuery();
  const [ fetchRoomTypes, roomTypes ] = useLazySearchRoomTypesQuery();
  const [ fetchRoomAmenities, { data: roomAmenities } ] = useLazyListRoomAmenitiesQuery();
  const [ updateRoomAmenities, { isLoading: isLoadingRoomAmenities } ] = useUpdateRoomAmenitiesMutation();
  const [ uploadRoomPhotos ] = useUploadRoomPhotosMutation();
  const [ deleteHotelRoom ] = useDeleteHotelRoomMutation();
  const { data: facilityList, isLoading: isLoadingFacilities } = useListFacilitiesQuery({ sort: 'fac.name_ru', limit: 1000, page: 1 });
  const [ selectedFacilities, setSelectedFacilities ] = React.useState<number[]>([]);
  const [ isCreating, setIsCreating ] = useState(false);
  const [ displayedTab, setDisplayedTab ] = useState(0);
  const [ menuEntry, setMenuEntry ] = useState<HotelRoomModel | null>(null);
  const [ menuAnchor, setMenuAnchor ] = useState<null | HTMLElement>(null);
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const isMenuOpen = Boolean(menuAnchor);

  useEffect(() => {
    fetchHotelRooms({ hotelId });
    fetchRoomTypes('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleClose();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, isUpdated]);

  useEffect(() => {
    if (room) {
      fetchRoomAmenities({ roomId: room.id });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  useEffect(() => {
    if (roomAmenities && facilityList?.data) {
      setSelectedFacilities(roomAmenities.map(item => Number(item.id)));
    }
  }, [roomAmenities, facilityList?.data]);

  const onMenuClick = (e: React.MouseEvent<HTMLElement>, entry: HotelRoomModel) => {
    setMenuAnchor(e.currentTarget);
    setMenuEntry(entry);
  };
  const onCloseMenu = () => {
    setMenuAnchor(null);
    setMenuEntry(null);
  };

  const onConfirmDialogOpen = () => {
    setDialogOpen(true);
    setMenuAnchor(null);
    setMenuEntry(null);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setIsCreating(false);
    setOpen(false);
    setSelectedFacilities([]);
  };

  const handleCreate = () => {
    handleOpen();
    setIsCreating(true);
    setSelectedFacilities([]);
  };

  const handleEdit = async (id: number) => {
    setMenuAnchor(null);
    try {
      await fetchRoom(id);
      setOpen(true);
    // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleRoomSave = async (data: RoomRequest) => {
    if (!isCreating) {
      await updateRoom({ id: Number(room?.id), data });
    } else {
      await addRoom({ hotelId, data });
    }
  };

  const handleDelete = useCallback((id: number) => async () => {
    setMenuAnchor(null);
    await deleteHotelRoom(id);
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRoomPhotosSelected = (formData: FormData) => {
    uploadRoomPhotos({ roomId: room?.id as number, formData });
  };

  const handleChangeAmenities = (event: SelectChangeEvent<typeof selectedFacilities>) => {
    const { target: { value } } = event;
    Array.isArray(value) && setSelectedFacilities(value);
  };

  const handleAmenitiesSave = async () => {
    await updateRoomAmenities({ roomId: Number(room?.id), data: { amenities: selectedFacilities } });
  };

  const onChangeTab = (event: React.SyntheticEvent, newValue: number) => setDisplayedTab(newValue);

  const renderForm = () => (
    <RoomForm
      model={isCreating ? undefined : room}
      hotelId={hotelId}
      roomTypes={roomTypes.data ? roomTypes.data : []}
      onSuccess={handleRoomSave}
      onCancel={handleClose}
      isLoading={isLoadingAdd || isLoadingUpdate}
    />
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" gutterBottom paddingY={2}>
          Комнаты
        </Typography>
        <Button variant="contained" component="span" onClick={handleCreate}>
          Добавить
        </Button>
      </Stack>
      {isLoading || !rooms ? (
        <Grid container wrap="nowrap">
          Нет закрепленных комнат
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {(rooms.data as HotelRoomModel[]).map((item) => (
            <Grid item container spacing={3} key={item.id}>
              <Grid item>
                {item.photo ? (<img
                  src={`${getPhotoPreviewUrl(item.photo.url, '')}`}
                  alt={item.photo.description_ru}
                  loading="lazy"
                  width={'150px'}
                />
                ) : (
                  <Skeleton sx={{ width: 150, height: 100 }} variant="rectangular" />
                )}
              </Grid>
              <Grid item xs={12} sm>
                <Typography gutterBottom variant="subtitle1" component="div">
                  {item.name_ru}
                </Typography>
                {item.room_type && (
                  <Typography variant="body2" gutterBottom>
                    Тип номера: {item.room_type.name_ru}
                  </Typography>
                )}
                {item.area && (
                  <Typography variant="body2" gutterBottom>
                    Площадь: {item.area}
                  </Typography>
                )}
                {!!item.occupancy && (
                  <Typography variant="body2" gutterBottom>
                    Вместимость: {item.occupancy}
                  </Typography>
                )}
              </Grid>
              <Grid item height={'40px'}>
                <IconButton onClick={e => onMenuClick(e, item)}>
                  <MoreVertIcon />
                </IconButton>
              </Grid>
              <Menu
                anchorEl={menuAnchor}
                open={isMenuOpen}
                onClose={onCloseMenu}
              >
                <MenuItem onClick={() => handleEdit((menuEntry as HotelRoomModel).id)} disableRipple>
                  <EditIcon />Редактировать
                </MenuItem>
                <MenuItem onClick={() => onConfirmDialogOpen()} disableRipple>
                  <DeleteIcon />Удалить
                </MenuItem>
              </Menu>
              <ConfirmDialog
                isOpen={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onConfirm={() => handleDelete(item.id)}
              />
            </Grid>
          ))}
          <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="lg">
            <DialogTitle>{!isCreating ? ' Редактировать комнату' : 'Создать комнату'}</DialogTitle>
            <DialogContent>
              {!isCreating ? (
                <>
                  <div className="mb-4">
                    <Tabs value={displayedTab} onChange={onChangeTab}>
                      <Tab label="Общая информация" value={0} />
                      <Tab label="Фотографии" value={1} />
                      <Tab label="Удобства" value={2} />
                    </Tabs>
                  </div>
                  {displayedTab === 0 && renderForm()}
                  {displayedTab === 1 && (
                    <>
                      <FormFieldPhotos htmlFor={'roomPhoto-uploader'} onChange={onRoomPhotosSelected} />
                      <RoomPhotos
                        mainPhoto={room?.photo as RoomPhotoModel}
                        roomId={room?.id as number}
                      />
                    </>
                  )}
                  {displayedTab === 2 && (
                    <>
                      <Grid container marginTop="10px">
                        {isLoadingFacilities ? <CircularProgress /> : (
                          <FormControl fullWidth={true}>
                            <InputLabel id="facilities-multiple-checkbox-label">Список удобств</InputLabel>
                            <Select
                              labelId="facilities-multiple-checkbox-label"
                              id="facilities-multiple-checkbox-label"
                              multiple
                              value={selectedFacilities}
                              onChange={handleChangeAmenities}
                              input={<OutlinedInput label="Список удобств" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => {
                                    const res = facilityList?.data.find(facility => Number(facility.id) === value);
                                    return <Chip key={value} sx={{ fontSize: '1rem' }} label={res ? res.name_ru : 'не найдено'} />;
                                  })}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {facilityList && facilityList.data.map((facility) => (
                                <MenuItem key={facility.id} value={facility.id}>
                                  <Checkbox checked={selectedFacilities.indexOf(Number(facility.id)) > -1} />
                                  <ListItemText primary={facility.name_ru} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Grid>
                      <Stack direction="row" spacing={2} paddingTop={4}>
                        <LoadingButton
                          color="success"
                          loadingPosition="start"
                          variant="contained"
                          type="button"
                          onClick={handleAmenitiesSave}
                          startIcon={<SaveIcon />}
                          loading={isLoadingRoomAmenities}
                        >
                          Сохранить
                        </LoadingButton>
                      </Stack>
                    </>
                  )}
                </>
              ) : renderForm()}
            </DialogContent>
          </Dialog>
        </Grid>
      )}
    </>
  );
};

export default HotelRooms;
