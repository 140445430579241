import { styled } from '@mui/material';

export const FilterFormContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin: 0 -12px;

  .control {
    width: 265px;
    padding: 12px;
  }
`;
