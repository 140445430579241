import React, { FC } from 'react';
import {
  Box,
  Button,
  Stack,
} from '@mui/material';
import {
  ProviderLogModel,
  ProviderLogTypeMap,
} from 'features/provider/types';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { Buffer } from 'buffer';
import { useLazyDownloadProviderLogQuery } from 'features/provider/api/providerLog';

interface ProviderLogInfoProps {
  providerId: number;
  item: ProviderLogModel;
}

const ProviderLogInfo: FC<ProviderLogInfoProps> = ({ item, providerId }) => {
  const [downloadProviderLog] = useLazyDownloadProviderLogQuery();

  const date1 = DateTime.fromFormat(item.requested_at.substring(0, item.requested_at.length - 4), 'yyyy-MM-dd hh:mm:ss ZZZ');
  const date2 = DateTime.fromFormat(item.responded_at.substring(0, item.responded_at.length - 4), 'yyyy-MM-dd hh:mm:ss ZZZ');

  const diff = date2.diff(date1, [ 'hours', 'minutes', 'seconds']);

  const renderHeaders = (headers: string) =>  (
    <Stack spacing={1} marginLeft="12px">
      {headers.split('\n').map((header, idx) => {
        const fullHeader = header.split(' ');
        const name = fullHeader?.at(0);
        fullHeader.splice(0, 1);
        const content = fullHeader.join(' ');
        return (
          <Stack key={idx}><b>{name}</b><div>{content}</div></Stack>
        );
      })}
    </Stack>
  );

  const handleLogDownload = async () => {
    try {
      const response = await downloadProviderLog({ id: providerId, logId: Number(item.id) }).unwrap();

      const blob = new Blob([response], { type: 'text/plain' });
      const reader = new FileReader();

      // This fires after the blob has been read/loaded.
      reader.addEventListener('loadend', () => {
        const element = document.createElement('a');
        const text = Buffer.from(reader.result as string, 'base64');
        const file = new File(
          [text],
          `log_search_${item.id}_${item.requested_at}`,
          {
            type: 'text/plain',
            lastModified: new Date().getTime(),
          });

        element.href = URL.createObjectURL(file);
        element.download = file.name;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      });

      // Start reading the blob as text.
      reader.readAsBinaryString(blob);
    // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  return (
    <>
      <Stack spacing={1} maxWidth="500px" sx={{ wordBreak: 'break-word' }}>
        <div><b>id</b>: {item.id}</div>
        <div><b>время ответа</b>: {`${diff.seconds} секунд`}</div>
        <div><b>requestedAt</b>: {item.requested_at}</div>
        <div><b>respondedAt</b>: {item.responded_at}</div>
        <div><b>type</b>: {get(ProviderLogTypeMap, item.type)}</div>
        <div><b>url</b>: <a target='_blank' href={item.url} rel="noreferrer">{item.url}</a></div>
        <br />
        <div><b>⯆Request Headers</b></div>
        <div>{renderHeaders(item.request_header)}</div>
        <div><b>⯆Response Headers</b></div>
        <div>{renderHeaders(item.response_header)}</div>
        <div><b>Request Body</b></div>
        <div>{item.request_body}</div>
        <div><b>Response Body</b></div>
        <div>{item.response_body}</div>
      </Stack>
      <Box marginTop={4}>
        <Button onClick={handleLogDownload} variant="contained">Скачать файл</Button>
      </Box>
    </>
  );
};

export default ProviderLogInfo;
