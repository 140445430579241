import React, { useState } from 'react';
import {
  FormControl,
  IconButton,
  TextField,
  MenuItem,
  createFilterOptions,
  Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { TableColumn } from 'features/common/components/DataTable';
import {
  BaseModel,
  Filter,
  FILTER_OPERATOR,
  FILTER_TYPE,
  ListResponse,
} from 'features/common/types';
import { FilterFormContainer } from 'features/common/components/Styled';
import FormAutocomplete from 'features/common/components/FormAutocomplete';
import { useAppSelector } from 'app/hooks';
import { selectAuthToken } from 'features/auth/slice';
import { DateTimePicker } from '@mui/lab';
import { DateTime } from 'luxon';

interface FilterProps<T> {
  filterIdx: number;
  filterable_columns: Array<TableColumn<T>>;
  filter: Filter;
  onEditFilter: (value: Filter, idx: number) => void;
  onDeleteFilter: (idx: number) => void;
}

export const FILTER_OPERATOR_LABELS = {
  [FILTER_OPERATOR.FULL]: '=',
  [FILTER_OPERATOR.SUBSTRING]: 'LIKE',
  [FILTER_OPERATOR.NOT_EQUAL]: 'не равно',
  [FILTER_OPERATOR.GT]: 'больше',
  [FILTER_OPERATOR.GTE]: 'больше, либо равно',
  [FILTER_OPERATOR.LT]: 'меньше',
  [FILTER_OPERATOR.LTE]: 'меньше, либо равно',
  [FILTER_OPERATOR.IS]: 'IS',
  [FILTER_OPERATOR.IS_NOT]: 'IS NOT',
};

function FilterForm<T extends BaseModel>(props: FilterProps<T>) {
  const {
    filterIdx,
    filterable_columns,
    filter,
    onEditFilter,
    onDeleteFilter,
  } = props;

  const getColumn = (field: string) => {
    const index = filterable_columns.findIndex((column) => column.name === field);
    return filterable_columns[index];
  };

  const [formData, setFormData] = useState(filter);
  const [column, setColumnData] = useState(getColumn(formData.filterField));
  const [searchResults, setSearchResults] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = useAppSelector(selectAuthToken);
  const module = column.options?.autocompleteSource as string;
  const handleFetch = async (search: string) => {
    setIsLoading(true);
    const res = await fetch(`/api/${search === '' ? module : `?term=${search}`}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    setIsLoading(false);
    if (res.ok) {
      const result = await res.json() as ListResponse<T>;
      setSearchResults(result.data);
    }
  };

  const getfilterableOperator = (operator: FILTER_OPERATOR) => {
    const index = column.filterableOperators?.findIndex((item) => item.operator === operator);
    return column.filterableOperators ? column.filterableOperators[index as number] : null;
  };

  const handleSelectFilterField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const field = event.target.value;
    const op = getfilterableOperator(formData.filterOperator);
    const path = op?.filterPath;
    setFormData((data) => ({ ...data, filterField: field, filterPath: path as string, filterType: op?.filterType }));
    setColumnData(getColumn(field));
  };

  const handleSelectFilterOperator = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const operator = event.target.value as FILTER_OPERATOR;
    const path = getfilterableOperator(operator)?.filterPath;
    const item = { ...formData, filterOperator: operator, filterPath: path as string };
    onEditFilter(item, filterIdx);
    setFormData(item);
  };

  const handleFilterValue = (
    value: string
  ) => {
    const path = getfilterableOperator(formData.filterOperator)?.filterPath;
    const item = { ...formData, filterValue: value, filterPath: path as string };
    onEditFilter(item, filterIdx);
    setFormData(item);
  };

  const handleDeleteFilter = () => {
    onDeleteFilter(filterIdx);
  };

  return (
    <FilterFormContainer>
      <div className="control">
        <TextField
          fullWidth
          id="filterField"
          name="filterField"
          label="Поле"
          select={true}
          value={formData.filterField}
          onChange={handleSelectFilterField}
        >
          {filterable_columns.map((col) => (
            <MenuItem key={col.name} value={col.name}>
              {col.label || col.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="control">
        <TextField
          fullWidth
          id="operator"
          name="operator"
          label="Оператор"
          select={true}
          value={formData.filterOperator}
          onChange={handleSelectFilterOperator}
        >
          <MenuItem key={0} value={0}>
            Не выбрана
          </MenuItem>
          {column.filterableOperators?.map((item) => (
            <MenuItem key={item.operator} value={item.operator}>
              {FILTER_OPERATOR_LABELS[item.operator]}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {(getfilterableOperator(formData.filterOperator)?.filterType === FILTER_TYPE.INPUT) && (
        <div className="control">
          <TextField
            fullWidth
            id="filterValue"
            label="Значение"
            value={formData.filterValue}
            onChange={(e) => handleFilterValue(e.target.value)}
          />
        </div>
      )}
      {(getfilterableOperator(formData.filterOperator)?.filterType === FILTER_TYPE.DATE) && (
        <div className="control">
          <DateTimePicker
            label="Дата"
            openTo="day"
            views={['day', 'hours', 'minutes']}
            inputFormat="yyyy-MM-dd HH:mm"
            value={formData.filterValue}
            onChange={(val) => {
              const dt = DateTime.fromISO(String(val));
              handleFilterValue(dt.toFormat('yyyy-MM-dd HH:mm:ss'));
            }}
            mask="__.__.____"
            renderInput={(params) => (<TextField
              fullWidth
              {...params}
            />)}
          />
        </div>
      )}
      {(getfilterableOperator(formData.filterOperator)?.filterType === FILTER_TYPE.SELECT) && (
        <div className="control">
          <TextField
            fullWidth
            id="filterValue"
            name="filterValue"
            label="Значение"
            select={true}
            value={formData.filterValue}
            onChange={(e) => handleFilterValue(e.target.value)}
          >
            <MenuItem key={0} value={0}>
              Не выбрана
            </MenuItem>
            {column.options?.selectOpts?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      )}
      {(getfilterableOperator(formData.filterOperator)?.filterType === FILTER_TYPE.AUTOCOMPLETE) && (
        <div className="control">
          <FormAutocomplete<T>
            field="search"
            label="Поиск"
            error={false}
            helperText={false}
            list={searchResults}
            fetchList={handleFetch}
            isFetching={isLoading}
            disabled={false}
            selectedOption={undefined}
            filterOptions={
              column.options?.autocompleteFilterOpts
                ? column.options?.autocompleteFilterOpts
                : createFilterOptions<T>({
                  stringify: (option) => `id: ${option.id}`,
                })
            }
            comparator={
              (option, value) => column.options?.autocompleteLabel
                ? (column.options?.autocompleteLabel(option) === column.options?.autocompleteLabel(value))
                : false
            }
            getOptionLabel={(option) => column.options?.autocompleteLabel
              ? column.options?.autocompleteLabel(option)
              : `${option.id}`}
            renderOption={(elem, option) => (
              <Box component="li" {...elem} key={option.id}>
                {column.options?.boxText ? column.options?.boxText(option) : null}
              </Box>
            )}
            handleChange={(event, value) => value ? handleFilterValue(`${value.id}`) : handleFilterValue('')}
          />
        </div>
      )}
      <FormControl>
        <IconButton aria-label="Delete" title="Delete" onClick={handleDeleteFilter} size="small">
          <Close sx={{ color: 'red' }} />
        </IconButton>
      </FormControl>
    </FilterFormContainer>
  );
}

export default FilterForm;
