import React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import { createTheme } from '@mui/material';

export const drawerStyles = (width = 640, p = 4, scrollable = true) => ({
  width,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width,
    padding: p,
    overflowY: scrollable ? 'auto' : 'hidden',
  },
});

export const customTheme = createTheme({
  colors: {
    background: '#f2f2f2',
    primary: '#17bb4f',
    text: '#11142D',
    textLight: '#5B6372',
    borderColorLight: 'rgba(221, 227, 237, 0.68)',
  },
});

export const inputGlobalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      color: theme.colors.text,
      body: {
        fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        padding: 0,
        margin: 0,
        backgroundColor: theme.colors.background,
      },
      '*': { boxSizing: 'border-box' },
      a: { color: 'inherit', textDecoration: 'none' },
      p: { padding: 0, margin: 0 },
    })}
  />
);
