import { styled } from '@mui/material';

export const Block = styled('div')((props) => ({
  background: props.theme.palette.background.default,
  padding: 24,
  borderRadius: 16,
}));

export const LayoutStyled = styled('div')`
  align-items: stretch;
  display: flex;
  min-height: 100vh;

  .workspace {
    flex: 1;
    display: flex;
    flex-flow: column;
    overflow: auto;
  }

  .content {
    flex: 1;
    box-sizing: border-box;
    padding: 24px;
    overflow: auto;
  }

  
  .sidebar {
    background: #fbfbfb;
    min-width: 250px;
    width: 250px;
    border-right: 1px solid ${({ theme }) => theme.colors.borderColorLight};

    &-logo {
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      display: flex;
      height: 48px;
      justify-content: center;
      text-transform: uppercase;
    }
  }
`;

export const HeaderStyled = styled('header')`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColorLight};
  display: flex;
  justify-content: space-between;
  height: 48px;
  background: #fbfbfb;
  padding: 0 24px;
  flex: 0 48px;

  &.right {
    align-items: center;
    display: flex;
  }

  &.user {
    align-items: center;
    display: flex;
    margin-right: 18px;
  }
`;

export const MenuItemStyled = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColorLight};
  padding: 12px 0;

  .title {
    align-items: center;
    color: ${({ theme }) => theme.colors.textLight};
    display: flex;
    font-size: 16px;
    padding: 0 18px;
  }

  .icon {
    margin-right: 8px;
  }

  .link {
    transition: all .2s ease-in;
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .children {
    padding: 8px 12px 0;
  }

  .sub-link {
    color: ${({ theme }) => theme.colors.primary};
    display: block;
    padding: 4px 0 4px 30px;
  }
`;

export const PageTopBarStyled = styled('div')`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 0 24px 18px;
`;

export const PageContent = styled('div')`
  background: #fff;
  padding: 24px;
  border-radius: 16px;
`;
