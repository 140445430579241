import { HotelModel } from 'features/dictionary/types';

export interface PriceModel {
  price: string;
  currency: string;
  is_vat_included: boolean;
  vat_amount: string;
  price_breakdown: number[];
}

export interface PartnerModel {
  id: number;
  code: string;
  name: string;
}

export interface CancelConditionsPolicy {
  date: string;
  amount: string;
  percent: string;
}

export interface CancelConditionsModel {
  free_cancellation: boolean;
  policies: CancelConditionsPolicy[];
}

export interface OrderGuestsModel {
  first_name: string;
  last_name: string;
  middle_name: string | null;
  is_child: boolean;
  age: number | null;
  sex: string;
}

export interface OrderRoomModel {
  code: string;
  description: string;
  guests: OrderGuestsModel[];
  name: string;
  price: PriceModel;
}

export interface OrderMealsModel {
  code: string;
  included: boolean;
  name: string;
  price: PriceModel;
}

export interface OrderItem {
  arrival_date: string;
  departure_date: string;
  arrival_time: string;
  departure_time: string;
  booking_type: string;
  cancel_conditions: CancelConditionsModel;
  code: string;
  extras: [];
  meals: OrderMealsModel[];
  hotel_id: number;
  penalty: string;
  price: PriceModel;
  provider: {
    id: number;
    code: string;
    name: string;
  };
  provider_id: number;
  refund: string;
  status: number;
  rooms: OrderRoomModel[];
}

export interface OrderModel {
  id: number;
  code: string;
  partner: PartnerModel;
  arrival_date: string;
  departure_date: string;
  created_at: string;
  customer_first_name: string;
  customer_last_name: string;
  customer_middle_name: string | null;
  customer_email: string;
  customer_phone: string | null;
  hotel_id: number;
  hotel: HotelModel;
  penalty: string;
  price: PriceModel;
  refund: string;
  status: number;
  updated_at: string;
  order_items: OrderItem[];
}

export interface Status {
  color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  code: number;
  label: string;
}

export const orderStatuses: Status[] = [
  {
    code: 0,
    label: 'Новый',
    color: 'default',
  },
  {
    code: 1,
    label: 'Забронирован',
    color: 'success',
  },
  {
    code: 2,
    label: 'Отменён',
    color: 'error',
  },
];
