import React from 'react';
import InviteForm from 'features/auth/components/InviteForm';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  AuthNoLogo,
  LoginErrorMessage,
  LoginFormContainer,
} from 'features/auth/pages/Styled';
import { Block } from 'features/common/layout/Styled';
import { useAppDispatch } from 'app/hooks';
import { useAcceptInviteMutation } from 'features/auth/api';
import { QueryError } from 'app/api';
import { AcceptInviteRequest } from 'features/auth/types';
import { setCredentials } from 'features/auth/slice';

const Invite = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [ accept, { isLoading, isError, error } ] = useAcceptInviteMutation();

  if (!searchParams.has('email') || !searchParams.has('code')) {
    return <></>;
  }

  let errorMessage = '';
  if (isError) {
    const err = error as QueryError;
    if (err.status === 400) {
      errorMessage = err.data.message;
    }

    if (err.status === 422 && err.data.errors.invite_code) {
      errorMessage = 'Неверный код';
    }

    if (errorMessage == '') {
      errorMessage = 'Ошибка';
    }
  }

  const handleSubmit = async (values: AcceptInviteRequest) => {
    try {
      const result = await accept(values).unwrap();
      dispatch(setCredentials(result));
      navigate('/');
    // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  return (
    <LoginFormContainer>
      <AuthNoLogo>
        Agregator
      </AuthNoLogo>
      <Block style={{ width: '400px' }}>
        <h2 style={{ padding: '0 12px 12px 12px' }}>Регистрация по приглашению</h2>
        <InviteForm
          email={String(searchParams.get('email'))}
          code={String(searchParams.get('code'))}
          isLoading={isLoading}
          onSubmit={handleSubmit}
        />
        {errorMessage && (
          <LoginErrorMessage>
            {errorMessage}
          </LoginErrorMessage>
        )}
      </Block>
    </LoginFormContainer>
  );
};

export default Invite;
