import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import { ListSettings } from 'features/common/types';
import { ProviderLogModel } from 'features/provider/types';

export const providerLogApi = createApi({
  reducerPath: 'providerLogsApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['ProviderLogs'],
  endpoints: (build) => ({
    listProviderLogs: build.query<ProviderLogModel[], { id: number; settings: ListSettings }>(
      {
        query: ({ id, settings }) => ({ url: `providers/${id}/logs`, method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'ProviderLogs', id } as const)),
              { type: 'ProviderLogs', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'ProviderLogs', id: 'LIST' }` is invalidated
            [{ type: 'ProviderLogs', id: 'LIST' }],
      }
    ),
    getProviderLog: build.query<ProviderLogModel, { id: number; logId: number }>({
      query: ({ id, logId }) => ({ url: `providers/${id}/logs/${logId}`, method: 'GET' }),
      providesTags: (result, error, { logId }) => [{ type: 'ProviderLogs', logId }],
    }),
    downloadProviderLog: build.query<BlobPart, { id: number; logId: number }>({
      query: ({ id, logId }) => ({ url: `providers/${id}/logs/${logId}/download`, method: 'GET' }),
    }),
  }),
});

export const {
  useListProviderLogsQuery,
  useLazyListProviderLogsQuery,
  useGetProviderLogQuery,
  useLazyGetProviderLogQuery,
  useLazyDownloadProviderLogQuery,
} = providerLogApi;
