import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import {
  ListResponse,
  ListSettings,
} from 'features/common/types';
import {
  FacilityCategoryModel,
  FacilityCategoryRequest,
} from 'features/dictionary/types';
import { showPopupMessage } from 'features/common/slice';

export const facilityCategoryApi = createApi({
  reducerPath: 'facilityCategoriesApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['FacilityCategories'],
  endpoints: (build) => ({
    listFacilityCategories: build.query<ListResponse<FacilityCategoryModel>, ListSettings>(
      {
        query: (settings) => ({ url: 'facilityCategories', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ?
            [
              ...result.data.map(({ id }) => ({ type: 'FacilityCategories', id } as const)),
              { type: 'FacilityCategories', id: 'LIST' },
            ]
            :
            [{ type: 'FacilityCategories', id: 'LIST' }],
      }
    ),
    addFacilityCategory: build.mutation<FacilityCategoryModel, FacilityCategoryRequest>({
      query: (data) => ({ url: 'facilityCategories', method: 'POST', data }),
      invalidatesTags: [{ type: 'FacilityCategories', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Категория удобств успешно добавлена' }));
      },
    }),
    getFacilityCategory: build.query<FacilityCategoryModel, number>({
      query: (id) => ({ url: `facilityCategories/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'FacilityCategories', id }],
    }),
    updateFacilityCategory: build.mutation<FacilityCategoryModel, { id: number; data: FacilityCategoryRequest }>({
      query: ({ id, data }) => ({
        url: `facilityCategories/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: () => [{ type: 'FacilityCategories', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Категория удобств успешно обновлена' }));
      },
    }),
    deleteFacilityCategory: build.mutation<{ success: boolean; id: number }, number>(
      {
        query: (id) => ({ url: `facilityCategories/${id}`, method: 'DELETE' }),
        invalidatesTags: () => [{ type: 'FacilityCategories', id: 'LIST' }],
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(showPopupMessage({ text: 'Категория удобств успешно удалена' }));
        },
      }),
    searchFacilityCategories: build.query<FacilityCategoryModel[], string>(
      {
        query: (search) => ({ url: search.length > 0 ? `facilityCategories/by-name?term=${search}` : 'facilityCategories?all=1', method: 'GET' }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'FacilityCategories', id } as const)),
              { type: 'FacilityCategories', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'FacilityCategories', id: 'LIST' }` is invalidated
            [{ type: 'FacilityCategories', id: 'LIST' }],
      }
    ),
  }),
});

export const {
  useLazyListFacilityCategoriesQuery,
  useLazyGetFacilityCategoryQuery,
  useAddFacilityCategoryMutation,
  useDeleteFacilityCategoryMutation,
  useUpdateFacilityCategoryMutation,
  useLazySearchFacilityCategoriesQuery,
} = facilityCategoryApi;
