import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useFormErrors } from 'features/common/hooks';
import {
  ProviderCredential,
  ProviderModel,
} from 'features/provider/types';
import {
  useSearchProviderCredentialsMutation,
  useLazySearchProvidersQuery,
} from 'features/provider/api/provider';
import {
  PartnerProviderModel,
  PartnerProviderRequest,
} from 'features/partner/types';
import { useLazyGetPartnerProvidersQuery } from 'features/partner/api/partnerProvider';

interface PartnerProviderFormProps {
  partnerId: number;
  providerId?: number;
  partnerProviderId?: number;
  connectedProviders: PartnerProviderModel[];
  onEdit: (model: PartnerProviderRequest, credentials?: {[credentialsFieldName: string]: string} | null) => void;
  onAdd: (model: PartnerProviderRequest) => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const initialPartnerProviderValues = {
  active: false,
  partner_id: null,
  credentials: null,
  provider_id: null,
};

const validationSchema = yup.object({
  provider: yup
    .number()
    .moreThan(0, 'Выберите поставщика'),
});

const PartnerProviderForm: FC<PartnerProviderFormProps> = ({
  connectedProviders,
  partnerId,
  providerId,
  partnerProviderId,
  onEdit,
  onAdd,
  onCancel,
  isLoading,
}) => {
  const [ fetchProviders, providers ] = useLazySearchProvidersQuery();
  const [ fetchPartnerProvider, partnerProvider ] = useLazyGetPartnerProvidersQuery();
  const [ updateProviderCredentials ] = useSearchProviderCredentialsMutation();
  const [ credentials, setCredentials ] = useState<ProviderCredential[] | undefined>();
  const [ leftProviders, setProviders ] = useState(providers.data);
  const [ initialValues, setInitialValues ] = useState<PartnerProviderRequest>(initialPartnerProviderValues);

  const formik = useFormik<PartnerProviderRequest>({
    initialValues,
    validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  useEffect(() => {
    fetchProviders('');
    if (partnerProviderId) {
      fetchPartnerProvider({ id_partner: partnerId, id: partnerProviderId as number });
    }

    if (providerId) {
      (async () => {
        const result = await updateProviderCredentials(providerId);
        setCredentials((result as { data: ProviderCredential[] }).data);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const model = partnerProvider.data ? {
      active: partnerProvider.data.active,
      partner_id: partnerProvider.data.partner_id as number,
      credentials: partnerProvider.data.credentials,
      provider_id: partnerProvider.data.provider_id as number,
    } : {
      active: false,
      partner_id: null,
      credentials: null,
      provider_id: null,
    };
    setInitialValues(model);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerProvider]);

  useEffect(() => {
    formik.setValues(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    setProviders(() => {
      const newProvider = ((providers.data as ProviderModel[]) || []).filter((res) =>
        !(connectedProviders || []).find((item) => item.provider_id === res.id) || (res.id as number) === providerId
      );
      return newProvider;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers]);

  const onSubmit = async(item: PartnerProviderRequest) => {
    if (partnerProviderId) {
      item.id = partnerProviderId;
      onEdit(item, item.credentials != initialValues.credentials ? item.credentials : null);
    } else {
      onAdd(item);
    }
  };

  const onChangeProvider = async(id: number) => {
    formik.setFieldValue('provider_id', id);
    const result = await updateProviderCredentials(id);
    const newCredentials = (result as { data: ProviderCredential[] }).data;
    setCredentials(newCredentials);
    const formCredentials = newCredentials.reduce((obj: {[credentialsFieldName: string]: string}, item) => {
      obj[item.name] = '';
      return obj;
    }, {});
    formik.setFieldValue('credentials', formCredentials);
  };

  useFormErrors(formik.setErrors);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="provider"
            name="provider"
            label="Поставщик"
            select={true}
            value={formik.values.provider_id ? formik.values.provider_id : ''}
            onChange={(e) => onChangeProvider(Number(e.target.value))}
            error={formik.touched.provider_id && Boolean(formik.errors.provider_id)}
            helperText={formik.touched.provider_id && formik.errors.provider_id}
          >
            {
              (leftProviders && leftProviders.length > 0) ? leftProviders.map((provider) => (
                <MenuItem key={provider.id} value={provider.id}>
                  {provider.name}
                </MenuItem>
              )) :
                <MenuItem key={0} value={0}>
                  Не выбрано
                </MenuItem>
            }
          </TextField>
        </Grid>
        {formik.values.credentials &&
          credentials?.map((item, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <TextField
                fullWidth
                id={item.name}
                label={item.label}
                value={(formik.values.credentials as {[credentialsFieldName: string]: string})[item.name]}
                onChange={(e) => formik.setFieldValue(`credentials[${item.name}]`, e.target.value)}
              />
            </Grid>
          ))
        }
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch checked={formik.values.active} onChange={formik.handleChange} name="active" />
            }
            label="Включен"
          />
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2} marginTop={2}>
        <LoadingButton
          color="success"
          loadingPosition="start"
          variant="contained"
          type="submit"
          startIcon={<SaveIcon />}
          loading={isLoading}
        >
          Создать
        </LoadingButton>
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          Вернуться назад
        </Button>
      </Stack>
    </Box>
  );
};

export default PartnerProviderForm;
