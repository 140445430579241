import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { PhotoModel } from 'features/dictionary/types';
import {
  IconButton,
  Box,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { useWidth } from 'app/useWidth';
import useEventListener from 'app/useEventListener';

interface PhotoGalleryProps {
  photoIndex: number;
  photos: PhotoModel[];
  onClose: () => void;
}

const ImagePadding = 40;

const PhotoGallery: FC<PhotoGalleryProps> = ({
  photoIndex,
  photos,
  onClose,
}) => {
  const [activePhoto, setActivePhoto] = useState(photoIndex);
  const outerEl = useRef<HTMLDivElement>(null);
  const windowWidth = useWidth();
  const [transform, setTransform] = useState<string>();

  const handleClickLeft = () => setActivePhoto(idx => (idx + photos.length - 1) % photos.length);
  const handleClickRight = () => setActivePhoto(idx => (idx + 1) % photos.length);

  useEventListener({
    type: 'keydown',
    listener: (event) => {
      if (event instanceof KeyboardEvent) {
        switch (event.key) {
        case 'ArrowLeft': handleClickLeft(); break;
        case 'ArrowRight': handleClickRight(); break;
        case 'Escape': onClose(); break;
        }
      }
    },
    options: { passive: true },
  });

  const getFitSizes = (width: number, height: number, stretch: boolean) => {
    const boxSize = outerEl && outerEl.current ? outerEl.current.getBoundingClientRect() : { height, width };
    let maxHeight = boxSize.height - ImagePadding * 2;
    let maxWidth = boxSize.width - ImagePadding * 2;

    if (!stretch) {
      maxHeight = Math.min(maxHeight, height);
      maxWidth = Math.min(maxWidth, width);
    }

    const maxRatio = maxWidth / maxHeight;
    const srcRatio = width / height;

    if (maxRatio > srcRatio) {
      // height is the constraining dimension of the photo
      return {
        width: (width * maxHeight) / height,
        height: maxHeight,
      };
    }

    return {
      width: maxWidth,
      height: (height * maxWidth) / width,
    };
  };

  useEffect(() => {
    if (!outerEl) {
      return;
    }

    const { width, height } = photos[activePhoto];
    const targetWidth = getFitSizes(width, height, false).width;
    let nextX = 0;
    const y = 0;
    const zoom = 1;

    if (width > windowWidth) {
      nextX += (windowWidth - width) / 2;
    }
    const scaleFactor = zoom * (targetWidth / width);
    setTransform(`translate3d(${nextX}px,${y}px,0) scale3d(${scaleFactor},${scaleFactor},1)`);
  }, [windowWidth, activePhoto, photos, outerEl]);

  return (
    <>
      <IconButton
        sx={{ position: 'absolute', top: 0, right: 0, color: '#fff', zIndex: 9999, m: 1 }}
        onClick={() => onClose()}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
      <IconButton
        sx={{ position: 'absolute', left: 0, top: '50%', color: '#fff', zIndex: 9999, ml: 1 }}
        onClick={() => handleClickLeft()}
      >
        <ChevronLeftIcon fontSize="large" />
      </IconButton>
      <IconButton
        sx={{ position: 'absolute', right: 0, top: '50%', color: '#fff', zIndex: 9999, mr: 1 }}
        onClick={() => handleClickRight()}
      >
        <ChevronRightIcon fontSize="large" />
      </IconButton>
      <Box sx={{
        position: 'absolute',
        right: 0,
        bottom: 0,
        color: '#fff',
        zIndex: 9999,
        p: 1,
        mr: 2,
        mb: 2,
      }}>{`${activePhoto + 1}/${photos.length}`}</Box>
      <Box
        ref={outerEl}
        sx={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        {outerEl && (
          <img
            src={photos[activePhoto].url}
            alt=""
            style={{
              position: 'absolute',
              margin: 'auto',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              transform,
            }}
          />
        )}
      </Box>
    </>
  );
};

export default PhotoGallery;
