import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  PhotoRequest,
  RoomPhotoModel,
} from 'features/dictionary/types';
import {
  Box,
  Grid,
  ImageList,
  Stack,
  Skeleton,
  Modal,
} from '@mui/material';
import PhotoItem from 'features/dictionary/components/PhotoItem';
import PhotoGallery from 'features/dictionary/components/PhotoGallery';
import {
  useDeleteRoomPhotoMutation,
  useLazyListRoomPhotosQuery,
  useUpdateRoomPhotoMutation,
} from 'features/dictionary/api/roomPhoto';
import { useUpdateHotelRoomMainPhotoMutation } from 'features/dictionary/api/hotelRoom';

interface RoomPhotosProps {
  mainPhoto: RoomPhotoModel | null;
  roomId: number;
}

const RoomPhotos: FC<RoomPhotosProps> = ({ mainPhoto, roomId }) => {
  const [deleteRoomPhoto] = useDeleteRoomPhotoMutation();
  const [fetchRoomPhotos, { data: photos, isLoading }] = useLazyListRoomPhotosQuery();
  const [updateRoomPhoto] = useUpdateRoomPhotoMutation();
  const [setRoomMainPhoto] = useUpdateHotelRoomMainPhotoMutation();
  const [photoGalleryIsOpen, setPhotoGalleryIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    fetchRoomPhotos({ roomId });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  const onPhotoDelete = (id: number) => {
    deleteRoomPhoto({ roomId, id });
  };

  const onPhotoUpdate = (id: number, data: PhotoRequest) => {
    updateRoomPhoto({ roomId, id, data });
  };

  const onSetRoomMainPhoto = (photoId: number) => {
    setRoomMainPhoto({ id: roomId, data: { photo_id: photoId } });
  };

  const onOpenPhotoModal = useCallback((index: number) => {
    setPhotoGalleryIsOpen(true);
    setPhotoIndex(index);
  }, []);

  return (
    <>
      {
        isLoading || !photos ? (
          <Grid container wrap="nowrap">
            {
              Array.from(new Array(3)).map((item, index) => (
                <Box key={index} sx={{ width: 210, marginRight: 0.5, my: 5 }}>
                  <Skeleton variant="rectangular" width={210} height={118} />
                  <Stack direction="row" mt={1} spacing={1}>
                    <Skeleton width={160}/>
                    <Skeleton width={40} />
                  </Stack>
                </Box>
              ))
            }
          </Grid>
        ) : photos.length > 0 && (
          <>
            <Modal
              open={photoGalleryIsOpen}
              onClose={() => setPhotoGalleryIsOpen(false)}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <PhotoGallery
                photoIndex={photoIndex}
                photos={photos}
                onClose={() => setPhotoGalleryIsOpen(false)}
              />
            </Modal>
            <ImageList cols={3} gap={12}>
              {(photos as RoomPhotoModel[]).map((photo, index) => (
                <PhotoItem
                  key={photo.id}
                  photo={photo}
                  photoIndex={index}
                  isMain={mainPhoto && mainPhoto.id === photo.id}
                  onDelete={onPhotoDelete}
                  onUpdate={onPhotoUpdate}
                  onOpenPhotoModal={onOpenPhotoModal}
                  onSetMain={onSetRoomMainPhoto}
                  urlSuffix={''}
                />
              ))}
            </ImageList>
          </>
        )}
    </>
  );
};

export default RoomPhotos;
