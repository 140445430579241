import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import {
  ListResponse,
  ListSettings,
} from 'features/common/types';
import {
  FacilityModel,
  FacilityRequest,
} from 'features/dictionary/types';
import { showPopupMessage } from 'features/common/slice';

export const facilityApi = createApi({
  reducerPath: 'facilitiesApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Facilities'],
  endpoints: (build) => ({
    listFacilities: build.query<ListResponse<FacilityModel>, ListSettings>(
      {
        query: (settings) => ({ url: 'facilities', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ?
            [
              ...result.data.map(({ id }) => ({ type: 'Facilities', id } as const)),
              { type: 'Facilities', id: 'LIST' },
            ]
            :
            [{ type: 'Facilities', id: 'LIST' }],
      }
    ),
    addFacility: build.mutation<FacilityModel, FacilityRequest>({
      query: (data) => ({ url: 'facilities', method: 'POST', data }),
      invalidatesTags: [{ type: 'Facilities', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Удобство успешно добавлено' }));
      },
    }),
    getFacility: build.query<FacilityModel, number>({
      query: (id) => ({ url: `facilities/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'Facilities', id }],
    }),
    updateFacility: build.mutation<FacilityModel, { id: number; data: FacilityRequest }>({
      query: ({ id, data }) => ({
        url: `facilities/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: () => [{ type: 'Facilities', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Удобство успешно обновлено' }));
      },
    }),
    deleteFacility: build.mutation<{ success: boolean; id: number }, number>(
      {
        query: (id) => ({ url: `facilities/${id}`, method: 'DELETE' }),
        invalidatesTags: () => [{ type: 'Facilities', id: 'LIST' }],
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(showPopupMessage({ text: 'Удобство успешно удалено' }));
        },
      }),
  }),
});

export const {
  useListFacilitiesQuery,
  useLazyListFacilitiesQuery,
  useLazyGetFacilityQuery,
  useAddFacilityMutation,
  useDeleteFacilityMutation,
  useUpdateFacilityMutation,
} = facilityApi;
