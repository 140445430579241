import React from 'react';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MenuItem from 'features/common/layout/MenuItem';
import { GroupOutlined } from '@mui/icons-material';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';

const SideNav = (): React.ReactElement => {
  const items = [
    {
      label: 'Заказы',
      icon: <ListAltOutlinedIcon />,
      subItems: [
        {
          label: 'Заказы',
          url: '/orders',
        },
      ],
    },
    {
      label: 'Поставщики',
      icon: <BusinessCenterOutlinedIcon />,
      subItems: [
        {
          label: 'Управление поставщиками',
          url: '/providers/list',
        },
        {
          label: 'Задачи импорта',
          url: '/providers/import-tasks',
        },
        {
          label: 'Задачи маппинга',
          url: '/providers/mapping-tasks',
        },
      ],
    },
    {
      label: 'Партнеры',
      icon: <BusinessCenterOutlinedIcon />,
      subItems: [
        {
          label: 'Управление партнерами',
          url: '/partners',
        },
      ],
    },
    {
      label: 'Словари',
      icon: <LibraryBooksOutlinedIcon />,
      subItems: [
        {
          label: 'Страны',
          url: '/countries',
        },
        {
          label: 'Регионы',
          url: '/regions',
        },
        {
          label: 'Города',
          url: '/cities',
        },
        {
          label: 'Отели',
          url: '/hotels',
        },
        {
          label: 'Типы отелей',
          url: '/hotel-types',
        },
        {
          label: 'Удобства',
          url: '/facilities',
        },
        {
          label: 'Категории удобств',
          url: '/facility-categories',
        },
        {
          label: 'Типы комнат',
          url: '/room-types',
        },
      ],
    },
    {
      label: 'Логи',
      icon: <ArticleOutlinedIcon />,
      subItems: [
        {
          label: 'Поставщики',
          url: '/provider-logs',
        },
      ],
    },
    {
      label: 'Пользователи',
      icon: <GroupOutlined />,
      subItems: [
        {
          label: 'Пользователи',
          url: '/users',
        },
      ],
    },
  ];
  return (
    <aside className="sidebar open">
      <div style={{ textAlign: 'center' }} >
        <a className="sidebar-logo" href={'/'} >
          Agregator
        </a>
      </div>
      <nav className="sidebar-menu">
        {
          items.map((item, index) => (
            <MenuItem key={index} item={item} />
          ))
        }
      </nav>
    </aside>
  );
};

export default SideNav;
