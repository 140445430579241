import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import { showPopupMessage } from 'features/common/slice';
import {
  ListSettings,
  ListResponse,
} from 'features/common/types';
import {
  ProviderTaskRequest,
  ProviderTaskModel,
} from 'features/provider/types';

export const providerTaskApi = createApi({
  reducerPath: 'providerTasksApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['ProviderTasks'],
  endpoints: (build) => ({
    listProviderTasks: build.query<ListResponse<ProviderTaskModel>, ListSettings>(
      {
        query: (settings) => ({ url: 'providerTasks', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.data.map(({ id }) => ({ type: 'ProviderTasks', id } as const)),
              { type: 'ProviderTasks', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'ProviderTasks', id: 'LIST' }` is invalidated
            [{ type: 'ProviderTasks', id: 'LIST' }],
      }
    ),
    getProviderTask: build.query<ProviderTaskModel, number>({
      query: (id) => ({ url: `providerTasks/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'ProviderTasks', id }],
    }),
    addProviderTask: build.mutation<ProviderTaskModel, ProviderTaskRequest>({
      query: (data) => ({ url: 'providerTasks', method: 'POST', data }),
      invalidatesTags: [{ type: 'ProviderTasks', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Задача успешно добавлена' }));
      },
    }),
  }),
});

export const {
  useListProviderTasksQuery,
  useLazyListProviderTasksQuery,
  useGetProviderTaskQuery,
  useLazyGetProviderTaskQuery,
  useAddProviderTaskMutation,
} = providerTaskApi;
