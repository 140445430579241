import React, { useState } from 'react';

import {
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  Logout,
  Settings,
} from '@mui/icons-material';
import { HeaderStyled } from 'features/common/layout/Styled';
import { useAppDispatch } from 'app/hooks';
import { logout } from 'features/auth/slice';

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = {
    name: 'test',
    last_name: 'test',
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleLogout = () => {
    dispatch(logout());
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (!user) {
    return null;
  }

  const onClickSettings = () => {
    handleMenuClose();
  };

  return (
    <HeaderStyled>
      <div />
      <div className="header-right">
        <div className="header-user">
          <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
            {user.name}
            {' '}
            {user.last_name}
          </Button>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={isMenuOpen}
            onClose={handleMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={onClickSettings}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Изменить данные
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Выйти
            </MenuItem>
          </Menu>
        </div>
      </div>
    </HeaderStyled>
  );
};
export default Header;
