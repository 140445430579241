import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Filter,
  FILTER_OPERATOR,
  FILTER_TYPE,
  Meta,
} from 'features/common/types';
import { usePaginatedQuery } from 'features/common/hooks';
import PageTopBar from 'features/common/layout/PageTopBar';
import {
  Button,
  Drawer,
  Typography,
} from '@mui/material';
import { PageContent } from 'features/common/layout/Styled';
import DataTable from 'features/common/components/DataTable';
import {
  UserModel,
  UserRequest,
} from 'features/user/types';
import {
  useCreateUserMutation,
  useLazyGetUserQuery,
  useLazyListUsersQuery,
  useResendInvitationMutation,
  useUpdateUserMutation,
} from 'features/user/api/user';
import { drawerStyles } from 'app/Styled';
import UserForm from 'features/user/components/UserForm';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const drawerSx = drawerStyles(860);

const columns = [
  {
    name: 'id',
    label: 'ID',
    sortName: 'u.id',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'u.id',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'email',
    label: 'Email',
    sortName: 'u.email',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'u.email',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'name',
    label: 'Имя',
    sortName: 'u.name',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'u.name',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'last_name',
    label: 'Фамилия',
    sortName: 'u.last_name',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'u.last_name',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'phone',
    label: 'Телефон',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'u.phone',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
];

const defaultFilter: Filter = {
  filterOperator: FILTER_OPERATOR.FULL,
  filterValue: '',
  filterField: 'name',
  filterPath: 'u.name',
};

const Users: FC = () => {
  const {
    settings,
    filters,
    handleSelectedItemChange,
    handleFiltersChange,
    handleLimitChange,
    handlePageChange,
    handleSortChange,
  } = usePaginatedQuery('-u.id');

  const [fetchUsers, users] = useLazyListUsersQuery();
  const [fetchUser, { currentData: user }] = useLazyGetUserQuery();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [createUser, { isSuccess: isCreated, isLoading: isLoadingCreate }] = useCreateUserMutation();
  const [updateUser, { isSuccess: isUpdated, isLoading: isLoadingUpdate }] = useUpdateUserMutation();
  const [resendInvitation] = useResendInvitationMutation();

  const handleCreate = () => {
    setIsDrawerOpen(true);
    setIsCreating(true);
  };

  const handleItemEdit = async (item: UserModel) => {
    try {
      await fetchUser(Number(item.id));
      setIsDrawerOpen(true);
      if (item.id) {
        handleSelectedItemChange(item.id);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleDrawerClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'manual') => {
    if (reason !== 'manual') {
      return;
    }
    setIsDrawerOpen(false);
    setIsCreating(false);
    if (settings) {
      handleSelectedItemChange(undefined);
    }
  };

  const handleSaveUser = async (data: UserRequest) => {
    if (!isCreating) {
      await updateUser({ id: Number(user?.id), data });
    } else {
      await createUser(data);
    }
  };

  const onSendInvitation = (entry: UserModel) => {
    resendInvitation({ id: entry.id });
  };

  const menuItems = [
    {
      icon: <MailOutlineIcon />,
      name: 'Отправить приглашение',
      callback: onSendInvitation,
    },
  ];

  useEffect(() => {
    handleDrawerClose({}, 'manual');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreated, isUpdated]);

  useEffect(() => {
    if (settings) {
      fetchUsers(settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return (
    <>
      <PageTopBar
        name="Пользователи"
        actions={(
          <Button
            variant="contained"
            color="success"
            onClick={handleCreate}
          >
            Добавить
          </Button>
        )}
      />
      <PageContent>
        <DataTable<UserModel>
          columns={columns}
          data={users.data ? users.data.data : []}
          onEditEntry={handleItemEdit}
          sortedBy={settings?.sort ?? null}
          meta={users.data?.meta as Meta}
          isLoading={users.isLoading}
          allowDelete={false}
          onLimitChange={handleLimitChange}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          filters={filters}
          defaultFilter={defaultFilter}
          onEditFilters={handleFiltersChange}
          menuItems={menuItems}
        />
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          sx={drawerSx}
          variant="temporary"
        >
          <Typography variant="h5" gutterBottom paddingBottom={2}>
            {isCreating ? 'Создание пользователя' : 'Редактирование пользователя'}
          </Typography>
          <UserForm
            isLoading={isLoadingCreate || isLoadingUpdate}
            onSuccess={handleSaveUser}
            onCancel={() => handleDrawerClose({}, 'manual')}
            model={isCreating ? undefined : user}
          />
        </Drawer>
      </PageContent>
    </>
  );
};

export default Users;
