import styled from 'styled-components';

export const LoginFormContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const AuthNoLogo = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  text-transform: uppercase;
`;

export const LoginErrorMessage = styled.div`
  padding: 14px 0;
  height: 17px;
  color: #D90026;
`;
