import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TableFooter,
  Menu,
  IconButton,
  MenuItem,
  TablePagination,
  Box,
  FilterOptionsState,
  Button,
  Collapse,
  Badge,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  MoreVert as MoreVertIcon,
  FilterList as FilterListIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { get } from 'lodash';
import {
  Filter,
  FilterableOperator,
  Meta,
} from 'features/common/types';
import FiltersCard from 'features/common/components/FiltersCard';
import { ConfirmDialog } from 'features/common/components/ConfirmDialog';

export interface ISelect {
  value: string | number;
  label: string;
}

export interface FilterOptions<T> {
  autocompleteSource?: string;
  selectOpts?: ISelect[];
  autocompleteFilterOpts?: (options: T[], state: FilterOptionsState<T>) => T[];
  autocompleteLabel?: (data: T) => string;
  boxText?: (data: T) => string;
}
export interface TableColumn<T> {
  name: string;
  label: string;
  minWidth?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: (val: any, item: T) => JSX.Element;
  style?: React.CSSProperties;
  sortName?: string;
  filterableOperators?: FilterableOperator[];
  options?: FilterOptions<T>;
}
export interface IMenuItem<T> {
  icon?: React.ReactElement;
  name: string;
  callback: (entry: T) => void;
}

interface DataTableProps<T> {
  columns: Array<TableColumn<T>>;
  data: T[];
  onEditEntry?: (data: T) => void;
  onDeleteEntry?: (entry: T) => void;
  onClick?: (entry: T) => void;
  allowDelete?: boolean;
  meta: Meta;
  isLoading?: boolean;
  filters?: Filter[];
  defaultFilter?: Filter;
  onEditFilters?: (filters: Filter[]) => void;
  onLimitChange: (limit: number) => void;
  onPageChange: (page: number) => void;
  onSortChange: (sortColumn: string) => void;
  sortedBy: string | null;
  menuItems?: Array<IMenuItem<T>>;
  displayMenu?: boolean;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1 && count !== -1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

const DataTable = <T, >(props: DataTableProps<T>) => {
  const {
    columns,
    data,
    onEditEntry,
    onDeleteEntry,
    onClick,
    allowDelete = true,
    meta,
    filters,
    defaultFilter,
    onEditFilters,
    isLoading = false,
    onLimitChange,
    onPageChange,
    onSortChange,
    sortedBy,
    menuItems = [],
    displayMenu = true,
  } = props;
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [menuEntry, setMenuEntry] = useState<T | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const isMenuOpen = Boolean(menuAnchor);

  let sortDirection = 'asc';
  let sortedByName = sortedBy;

  if (sortedBy && sortedBy.charAt(0) === '-') {
    sortDirection = 'desc';
    sortedByName = sortedBy.substring(1);
  }

  const onMenuClick = (e: React.MouseEvent<HTMLElement>, entry: T) => {
    setMenuAnchor(e.currentTarget);
    setMenuEntry(entry);
  };

  const onMenuItemClick = (item: IMenuItem<T>) => {
    item.callback(menuEntry as T);
    onCloseMenu();
  };

  const onCloseMenu = () => {
    setMenuAnchor(null);
    setMenuEntry(null);
  };

  const confirmDelete = async () => {
    if (onDeleteEntry && menuEntry) {
      await onDeleteEntry(menuEntry);
    }
    setDialogOpen(false);
  };

  const onConfirmDialogOpen = () => {
    setDialogOpen(true);
    setMenuAnchor(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onPageChange(newPage + 1);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onLimitChange(Number(e.target.value));
  };

  const handleSort = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, sortName: string) => {
    if (sortedByName === sortName && sortDirection === 'asc') {
      sortName = '-' + sortName;
    }
    onSortChange(sortName);
  };

  const handleFilters = (newFilters: Filter[]) => {
    if (onEditFilters) {
      onEditFilters(newFilters);
    }
  };

  const handleCleanFilter = () => {
    if (onEditFilters) {
      setExpanded(!expanded);
      onEditFilters([]);
    }
  };

  const handleEditEntry = () => {
    if (menuEntry && onEditEntry) {
      onEditEntry(menuEntry);
      onCloseMenu();
    }
  };

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={columns.length + 1}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress sx={{ marginRight: 2 }}/> Загружаем...
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    return (data && data.length > 0) ? (
      <TableBody>
        {
          data.map((item, index) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={index} onClick={() => onClick ? onClick(item) : {}}>
              {columns.map((column) => {
                const value = get(item, column.name);
                return (
                  <TableCell key={column.name}>
                    {
                      column.format
                        ? column.format(value, item)
                        : value
                    }
                  </TableCell>
                );
              })}
              {
                displayMenu &&
                <TableCell>
                  <IconButton onClick={e => onMenuClick(e, item)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              }
            </TableRow>
          ))}
      </TableBody>
    ) : (
      <TableBody>
        <TableRow>
          <TableCell className="description" colSpan={columns.length + 1}>
            Объекты не найдены
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return (
    <>
      {filters && defaultFilter && (
        <>
          <Button
            variant="outlined"
            onClick={() => handleExpandClick()}
            startIcon={
              <Badge badgeContent={filters.length} color="primary">
                <FilterListIcon />
              </Badge>
            }
          >
          Фильтры
          </Button>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <FiltersCard
              filterable_columns={columns}
              filters={filters.length > 0 ? filters : [defaultFilter]}
              defaultFilter={defaultFilter}
              onEditFilters={handleFilters}
              onCleanFilters={handleCleanFilter}
            />
          </Collapse>
        </>
      )}
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.name}
                  style={{ minWidth: column.minWidth }}
                >
                  {
                    column.sortName
                      ? <TableSortLabel
                        active={column.sortName === sortedByName}
                        direction={column.sortName === sortedByName ? sortDirection as 'asc' | 'desc' : 'asc'}
                        onClick={(e) => handleSort(e, column.sortName as string)}
                      >
                        {column.label}
                      </TableSortLabel>
                      : column.label
                  }
                </TableCell>
              ))}
              {
                displayMenu &&
                <TableCell></TableCell>
              }
            </TableRow>
          </TableHead>
          {renderTableBody()}
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage={'Записей на странице'}
                rowsPerPageOptions={[10, 25, 50]}
                colSpan={columns.length + 1}
                count={meta?.total_count ?? -1}
                rowsPerPage={meta?.limit ?? -1}
                page={meta?.page ? meta.page - 1 : 1}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'Записей на странице',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Menu
        anchorEl={menuAnchor}
        open={isMenuOpen}
        onClose={onCloseMenu}
      >
        {onEditEntry && menuEntry && (
          <MenuItem onClick={handleEditEntry} disableRipple>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>
              Редактировать
            </ListItemText>
          </MenuItem>
        )}
        {
          menuItems.map((item) => (
            <MenuItem key={item.name} onClick={() => onMenuItemClick(item)} disableRipple>
              {item.icon && (
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
              )}
              <ListItemText>{item.name}</ListItemText>
            </MenuItem>
          ))
        }
        {allowDelete && (
          <MenuItem onClick={() => onConfirmDialogOpen()} disableRipple>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>
              Удалить
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
      <ConfirmDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={confirmDelete}
      />
    </>
  );
};

export default DataTable;
