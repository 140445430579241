import getPhotoPreviewUrl from 'features/dictionary/utils/photoPreviewUrl';
import {
  Chip,
  ImageListItem,
  ImageListItemBar,
  Stack,
  TextField,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import {
  PhotoModel,
  PhotoRequest,
} from 'features/dictionary/types';
import React, {
  FC,
  useState,
} from 'react';

interface PhotoItemProps {
  photo: PhotoModel;
  photoIndex: number;
  isMain: boolean | null;
  onDelete: (id: number) => void;
  onUpdate: (id: number, data: PhotoRequest) => void;
  onSetMain: (photoId: number) => void;
  onOpenPhotoModal: (photoId: number) => void;
  urlSuffix: string;
}

interface PhotoFormProps {
  photo: PhotoModel;
  onUpdate: (id: number, data: PhotoRequest) => void;
}

const PhotoForm: FC<PhotoFormProps> = ({ photo, onUpdate }) => {
  const [description, setDescription] = useState(photo.description_ru);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onUpdate(photo.id, { description_ru: description });
  };

  return (
    <>
      <Stack direction="row" spacing={1} mt={1}>
        <TextField
          name="photo_description"
          label="Описание..."
          value={description || ''}
          size="small"
          onChange={(e) => setDescription(e.target.value)}
        />
        <IconButton color="primary" type="submit" onClick={handleSubmit}>
          <SaveOutlinedIcon />
        </IconButton>
      </Stack>
    </>
  );
};

const PhotoItem: FC<PhotoItemProps> = ({
  photo,
  photoIndex,
  isMain,
  onDelete,
  onUpdate,
  onSetMain,
  urlSuffix,
  onOpenPhotoModal,
}) => {
  const handleSetMainPhoto = () => {
    onSetMain(photo.id);
  };

  return (
    <ImageListItem key={photo.id}>
      <img
        onClick={() => onOpenPhotoModal(photoIndex)}
        src={`${getPhotoPreviewUrl(photo.url, urlSuffix)}?w=248&fit=crop&auto=format`}
        alt={photo.description_ru}
        style={{ cursor: 'pointer' }}
        loading="lazy"
      />
      <ImageListItemBar
        sx={{
          background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        }}
        position="top"
        actionIcon={
          <Stack direction="row" alignItems="center">
            {
              isMain ?
                <Chip label="Главная" size="small" color="warning" sx={{ lineHeight: '1.4' }} />
                : <IconButton
                  sx={{ color: 'white', ':hover': { color: 'gold' } }}
                  onClick={handleSetMainPhoto}
                >
                  <StarOutlineIcon />
                </IconButton>
            }
            <IconButton
              sx={{ color: 'white', ':hover': { color: 'red' } }}
              onClick={() => onDelete(photo.id)}
            >
              <DeleteIcon  />
            </IconButton>
          </Stack>
        }
        actionPosition="right"
      />
      <ImageListItemBar
        position="below"
      />
      <PhotoForm
        photo={photo}
        onUpdate={onUpdate}
      />
    </ImageListItem>
  );
};

export default PhotoItem;
