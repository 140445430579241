import React, {
  FC,
  useEffect,
  useState,
} from 'react';

import { PageContent } from 'features/common/layout/Styled';
import PageTopBar from 'features/common/layout/PageTopBar';
import { useSearchProvidersQuery } from 'features/provider/api/provider';
import {
  Tab,
  Tabs,
} from '@mui/material';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

const ProviderLogsSelector: FC = () => {
  const { data: providers, isLoading: isProvidersLoading } = useSearchProvidersQuery('');
  const navigate = useNavigate();
  const [prevUrl, setPrevUrl] = useState<string>();
  const { search } = useLocation();
  const { id } = useParams();

  if (!id && prevUrl) {
    navigate(prevUrl);
  }

  useEffect(() => {
    if (providers && providers.length > 0 && id === undefined) {
      const uri = Number(providers.at(0)?.id).toString();
      navigate(uri);
      setPrevUrl(uri);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers]);

  if (isProvidersLoading || id === undefined) {
    return <div>Загрузка...</div>;
  }

  const onChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    const url = `${newValue}/${search}`;
    navigate(url, { replace: true });
    setPrevUrl(url);
  };

  return (
    <>
      <PageTopBar name="Логи поставщиков" />
      <PageContent>
        <Tabs value={Number(id)} onChange={onChangeTab}>
          {providers?.map(provider => <Tab label={provider.name} key={provider.id} value={provider.id}/>)}
        </Tabs>
        <Outlet />
      </PageContent>
    </>
  );
};

export default ProviderLogsSelector;
