import React from 'react';
import PageTopBar from 'features/common/layout/PageTopBar';
import { PageContent } from 'features/common/layout/Styled';
import PartnerForm from 'features/partner/components/PartnerForm';
import { useNavigate } from 'react-router';
import { useAddPartnerMutation } from 'features/partner/api/partner';
import { PartnerModel } from 'features/partner/types';

const PartnerCreate = () => {
  const navigate = useNavigate();
  const [addPartner, { isSuccess, isLoading, data }] = useAddPartnerMutation();
  if (isSuccess) {
    navigate(`/partners/${data?.id}`);
  }

  const handleSubmit = async (item: PartnerModel) => await addPartner(item);

  return (
    <>
      <PageTopBar name={'Добавление партнера'}/>
      <PageContent>
        <PartnerForm
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onCancel={() => navigate('/partners')}
        />
      </PageContent>
    </>
  );
};

export default PartnerCreate;
