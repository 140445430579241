import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import { showPopupMessage } from 'features/common/slice';
import {
  ListResponse,
  ListSettings,
} from 'features/common/types';
import {
  HotelType,
  HotelTypeRequest,
} from 'features/dictionary/types';

export const hotelTypesApi = createApi({
  reducerPath: 'hotelTypesApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['HotelTypes'],
  endpoints: (build) => ({
    listHotelTypes: build.query<ListResponse<HotelType>, ListSettings>(
      {
        query: (settings) => ({ url: 'hotelTypes', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.data.map(({ id }) => ({ type: 'HotelTypes', id } as const)),
              { type: 'HotelTypes', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'HotelTypes', id: 'LIST' }` is invalidated
            [{ type: 'HotelTypes', id: 'LIST' }],
      }
    ),
    searchHotelTypes: build.query<HotelType[], string>(
      {
        query: (search) => ({ url: search.length > 0 ? `hotelTypes/by-name?term=${search}` : 'hotelTypes?all=1', method: 'GET' }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'HotelTypes', id } as const)),
              { type: 'HotelTypes', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'HotelTypes', id: 'LIST' }` is invalidated
            [{ type: 'HotelTypes', id: 'LIST' }],
      }
    ),
    addHotelType: build.mutation<HotelType, HotelTypeRequest>({
      query: (data) => ({ url: 'hotelTypes', method: 'POST', data }),
      invalidatesTags: [{ type: 'HotelTypes', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Тип отеля успешно добавлен' }));
      },
    }),
    getHotelType: build.query<HotelType, number>({
      query: (id) => ({ url: `hotelTypes/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'HotelTypes', id }],
    }),
    updateHotelType: build.mutation<HotelType, { id: number; data: HotelTypeRequest }>({
      query: ({ id, data }) => ({
        url: `hotelTypes/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'HotelTypes', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Тип отеля успешно обновлен' }));
      },
    }),
    deleteHotelType: build.mutation<{ success: boolean; id: number }, number>(
      {
        query: (id) => ({ url: `hotelTypes/${id}`, method: 'DELETE' }),
        invalidatesTags: (result, error, id) => [{ type: 'HotelTypes', id }],
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(showPopupMessage({ text: 'Тип отеля успешно удален' }));
        },
      }
    ),
  }),
});

export const {
  useLazyGetHotelTypeQuery,
  useAddHotelTypeMutation,
  useLazyListHotelTypesQuery,
  useLazySearchHotelTypesQuery,
  useUpdateHotelTypeMutation,
  useDeleteHotelTypeMutation,
} = hotelTypesApi;
