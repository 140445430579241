import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import {
  ListSettings,
  ListResponse,
} from 'features/common/types';
import { ProviderMappingTasksModel } from 'features/provider/types';

export const mappingTaskApi = createApi({
  reducerPath: 'mappingTasksApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['MappingTasks'],
  endpoints: (build) => ({
    listMappingTasks: build.query<ListResponse<ProviderMappingTasksModel>, ListSettings>(
      {
        query: (settings) => ({ url: 'autoMappingTasks', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.data.map(({ id }) => ({ type: 'MappingTasks', id } as const)),
              { type: 'MappingTasks', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Providers', id: 'LIST' }` is invalidated
            [{ type: 'MappingTasks', id: 'LIST' }],
      }
    ),
  }),
});

export const {
  useLazyListMappingTasksQuery,
} = mappingTaskApi;
