import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import { showPopupMessage } from 'features/common/slice';
import {
  ListResponse,
  ListSettings,
} from 'features/common/types';
import {
  RegionModel,
  RegionRequest,
} from 'features/dictionary/types';

export const regionApi = createApi({
  reducerPath: 'regionsApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Regions'],
  endpoints: (build) => ({
    listRegions: build.query<ListResponse<RegionModel>, ListSettings>(
      {
        query: (settings) => ({ url: 'regions', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.data.map(({ id }) => ({ type: 'Regions', id } as const)),
              { type: 'Regions', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Countries', id: 'LIST' }` is invalidated
            [{ type: 'Regions', id: 'LIST' }],
      }
    ),
    searchRegions: build.query<RegionModel[], string>(
      {
        query: (search) => ({ url: search.length > 0 ? `regions/by-name?term=${search}` : 'regions?all=1', method: 'GET' }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'Regions', id } as const)),
              { type: 'Regions', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Countries', id: 'LIST' }` is invalidated
            [{ type: 'Regions', id: 'LIST' }],
      }
    ),
    addRegion: build.mutation<RegionModel, RegionRequest>({
      query: (data) => ({ url: 'regions', method: 'POST', data }),
      invalidatesTags: [{ type: 'Regions', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Регион успешно добавлен' }));
      },
    }),
    getRegion: build.query<RegionModel, number>({
      query: (id) => ({ url: `regions/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'Regions', id }],
    }),
    updateRegion: build.mutation<RegionModel, { id: number; data: RegionRequest }>({
      query: ({ id, data }) => ({
        url: `regions/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Regions', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Регион успешно обновлен' }));
      },
    }),
    deleteRegion: build.mutation<{ success: boolean; id: number }, number>(
      {
        query: (id) => ({ url: `regions/${id}`, method: 'DELETE' }),
        invalidatesTags: (result, error, id) => [{ type: 'Regions', id }],
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(showPopupMessage({ text: 'Регион успешно удален' }));
        },
      }),
  }),
});

export const {
  useListRegionsQuery,
  useGetRegionQuery,
  useLazyGetRegionQuery,
  useAddRegionMutation,
  useLazyListRegionsQuery,
  useUpdateRegionMutation,
  useDeleteRegionMutation,
  useLazySearchRegionsQuery,
} = regionApi;
