export interface BaseModel {
  id?: number;
}

export interface Meta {
  total_count: number;
  page: number;
  total_pages: number;
  limit: number;
}

export interface ListSettings {
  sort: string | null;
  limit: number;
  page: number;
  first_id?: number;
  last_id?: number;
  type?: number;
}

export interface ListResponse<T> {
  data: T[];
  meta?: Meta;
  settings?: ListSettings;
}

export enum FILTER_OPERATOR {
  FULL = '=',
  SUBSTRING = '?=',
  NOT_EQUAL = '!=',
  GT = '>',
  GTE = '>=',
  LT = '<',
  LTE = '<=',
  IS = 'is',
  IS_NOT = 'is not',
}

export enum FILTER_TYPE {
  SELECT = 'select',
  INPUT = 'input',
  DATE = 'date',
  AUTOCOMPLETE = 'autocomplete',
}

export interface FilterableOperator {
  operator: FILTER_OPERATOR;
  filterPath: string;
  filterType: FILTER_TYPE;
}

export interface Filter {
  filterOperator: FILTER_OPERATOR;
  filterType?: FILTER_TYPE;
  filterValue: string;
  filterField: string;
  filterPath: string;
}
