import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import DataTable from 'features/common/components/DataTable';
import { usePaginatedQuery } from 'features/common/hooks';
import {
  Filter,
  FILTER_OPERATOR,
  FILTER_TYPE,
  Meta,
} from 'features/common/types';
import { PageContent } from 'features/common/layout/Styled';
import PageTopBar from 'features/common/layout/PageTopBar';
import {
  HotelFacilitiesRequest,
  HotelModel,
  HotelRequest,
  ProviderMapping,
} from 'features/dictionary/types';
import {
  Button,
  Drawer,
  Typography,
} from '@mui/material';
import {
  useAddHotelMutation,
  useLazyGetHotelQuery,
  useLazyListHotelsQuery,
  useUpdateHotelMutation,
} from 'features/dictionary/api/hotel';
import HotelForm from 'features/dictionary/components/HotelForm';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { drawerStyles } from 'app/Styled';
import {
  useLazyListHotelFacilitiesQuery,
  useUpdateHotelFacilitiesMutation,
} from 'features/dictionary/api/hotelFacility';
import { useLazySearchHotelTypesQuery } from 'features/dictionary/api/hotelTypes';


const drawerSx = drawerStyles(960, 0, false);

const renderMappings = (mappings: { [key: string]: string } | ProviderMapping[]) => {
  if (Array.isArray(mappings)) {
    return mappings.map(item => <p key={item.provider_id}>{`${item.provider_code}(${item.Provider?.name})`}</p>);
  }

  return Object.keys(mappings).map(key => <p key={key}>{`${mappings[key]}(${key})`}</p>);
};

const columns = [
  {
    name: 'id',
    label: 'ID',
    sortName: 'ho.id',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'ho.id',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'mappings',
    label: 'Коды поставщиков',
    sortName: 'com',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'ho.id',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
    format: (val: string, item: HotelModel) => (
      <>
        {item.mappings && renderMappings(item.mappings)}
      </>
    ),
  },
  {
    name: 'name',
    label: 'Название',
    sortName: 'ho.name_ru',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'ho.name_ru',
        filterType: FILTER_TYPE.INPUT,
      },
      {
        operator: FILTER_OPERATOR.SUBSTRING,
        filterPath: 'ho.name_ru',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
    format: (val: string, item: HotelModel) => (
      <>
        <p>{item.name_ru}</p>
        <p>{item.name_en}</p>
      </>
    ),
  },
  {
    name: 'type',
    label: 'Тип отеля',
    sortName: 'ht.type_id',
    filterableOperators: [],
    format: (val: string, item: HotelModel) => (
      <>
        {item.type ? item.type.name_ru : <>&mdash;</>}
      </>
    ),
  },
];

const defaultFilter: Filter = {
  filterOperator: FILTER_OPERATOR.FULL,
  filterValue: '',
  filterField: 'name',
  filterPath: 'ho.name_ru',
};

const Hotels: FC = () => {
  const {
    settings,
    filters,
    handleSelectedItemChange,
    handleFiltersChange,
    handleLimitChange,
    handlePageChange,
    handleSortChange,
  } = usePaginatedQuery('-ho.id');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ fetchHotels, hotels ] = useLazyListHotelsQuery();
  const [ addHotel, { isSuccess: isAdded, isLoading: isLoadingAdd } ] = useAddHotelMutation();
  const [ updateHotel, { isSuccess: isUpdated, isLoading: isLoadingUpdate } ] = useUpdateHotelMutation();
  const [ updateHotelFacilities, { isLoading: isFacilitiesUpdating } ] = useUpdateHotelFacilitiesMutation();
  const [ fetchHotel, { currentData: hotel } ] = useLazyGetHotelQuery();
  const [ fetchHotelFacilities, { data: hotelFacilities } ] = useLazyListHotelFacilitiesQuery();
  const [ fetchHotelTypes, hotelTypes ] = useLazySearchHotelTypesQuery();
  const [ isDrawerOpen, setIsDrawerOpen ] = useState(false);
  const [ isCreating, setIsCreating ] = useState(false);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleDrawerClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'manual') => {
    if (reason !== 'manual') {
      return;
    }
    setIsDrawerOpen(false);
    setIsCreating(false);
    if (settings) {
      handleSelectedItemChange(undefined);
    }
  };

  const handleCreate = () => {
    setIsDrawerOpen(true);
    setIsCreating(true);
  };

  useEffect(() => {
    handleDrawerClose({}, 'manual');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, isUpdated]);

  const handleItemEdit = async (item: Partial<HotelModel>) => {
    try {
      await Promise.all([
        fetchHotel(Number(item.id)),
        fetchHotelFacilities({ hotelId: Number(item.id) }),
        fetchHotelTypes(''),
      ]);
      if (item.id) {
        handleSelectedItemChange(item.id);
      }
      setIsDrawerOpen(true);
    // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleHotelSave = async (data: HotelRequest, facilities?: HotelFacilitiesRequest) => {
    if (!isCreating) {
      await updateHotel({ id: Number(hotel?.id), data });
      if (facilities) {
        await updateHotelFacilities({ id: Number(hotel?.id), data: facilities  });
      }
    } else {
      await addHotel(data);
    }
  };

  useEffect(() => {
    if (settings) {
      fetchHotels(settings);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (settings?.selectedItem) {
      handleItemEdit({ id: settings?.selectedItem });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.selectedItem]);

  return (
    <>
      <PageTopBar
        name="Отели"
        actions={(
          <Button
            variant="contained"
            color="success"
            onClick={handleCreate}
          >
            Добавить
          </Button>
        )}
      />
      <PageContent>
        <DataTable<HotelModel>
          columns={columns}
          data={hotels.data ? hotels.data.data : []}
          onEditEntry={handleItemEdit}
          sortedBy={settings?.sort ?? null}
          meta={hotels.data?.meta as Meta}
          isLoading={hotels.isLoading}
          allowDelete={false}
          onLimitChange={handleLimitChange}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          filters={filters}
          defaultFilter={defaultFilter}
          onEditFilters={handleFiltersChange}
        />
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          sx={drawerSx}
          variant="temporary"
        >
          <Typography variant="h5" gutterBottom padding="30px 30px 8px 30px">
            {hotel ? 'Редактирование отеля' : 'Создание отеля'}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterLuxon} locale="ru">
            <HotelForm
              isLoading={isLoadingAdd || isLoadingUpdate || isFacilitiesUpdating}
              onSuccess={handleHotelSave}
              onCancel={() => handleDrawerClose({}, 'manual')}
              model={isCreating ? undefined : hotel}
              facilities={isCreating ? undefined : hotelFacilities}
              hotelTypes={hotelTypes.data ? hotelTypes.data : []}
            />
          </LocalizationProvider>
        </Drawer>
      </PageContent>
    </>
  );
};

export default Hotels;
