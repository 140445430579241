import React, { FC } from 'react';
import { LoginRequest } from 'features/auth/types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useFormErrors } from 'features/common/hooks';
import { LoadingButton } from '@mui/lab';
import {
  Stack,
  TextField,
} from '@mui/material';

interface LoginFormProps {
  isLoading: boolean;
  onSubmit: (values: LoginRequest) => void;
}

const validationSchema = yup.object({
  email: yup
    .string()
    .required('Введите email'),
  password: yup
    .string()
    .required('Введите пароль'),
});

const LoginForm: FC<LoginFormProps> = ({
  isLoading,
  onSubmit,
}) => {
  const formik = useFormik<LoginRequest>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  useFormErrors(formik.setErrors);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2} marginY={3}>
        <TextField
          fullWidth
          id="email"
          label="Введите email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          id="password"
          label="Введите пароль"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      </Stack>
      <LoadingButton
        variant="contained"
        color="success"
        size="large"
        type="submit"
        fullWidth
        disableElevation
        loading={isLoading}
      >
        Войти
      </LoadingButton>
    </form>
  );
};

export default LoginForm;
