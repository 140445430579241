import React, {
  FC,
  useEffect,
} from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
  TableContainer,
  Table,
  TableBody,
  Paper,
  TableRow,
  Chip,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useLazyGetOrderQuery } from 'features/order/api/order';
import { useParams } from 'react-router';
import { orderStatuses } from 'features/order/types';
import { formatMoney } from 'features/order/utils/numbers';
import { p11n } from 'features/order/utils/p11';
import { formatDate } from 'features/common/utils/dateTime';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Order: FC = () => {
  const [fetchOrder, { data: order, isFetching } ] = useLazyGetOrderQuery();
  const { code } = useParams();

  useEffect(() => {
    fetchOrder(code as string);
  }, [code]);

  if (isFetching || !order) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress sx={{ marginRight: 2 }}/> Загружаем...
      </Box>
    );
  }

  const status = orderStatuses.find((item) => item.code === order.status);

  return (
    <>
      <Link component={RouterLink} underline="hover" to="/orders">
        Вернуться к списку заказов
      </Link>
      <Typography variant="h6" paddingY={2}>
        Заказ № {order.code}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" paddingY={1}>
            Общая информация
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: '100%' }}>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>Номер заказа</StyledTableCell>
                  <StyledTableCell>{order.code}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Дата и время заказа</StyledTableCell>
                  <StyledTableCell>
                    {
                      formatDate(order.created_at, 'f')
                    }
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Партнер</StyledTableCell>
                  <StyledTableCell>{order.partner.name}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Статус</StyledTableCell>
                  <StyledTableCell>
                    <Chip label={status?.label} color={status?.color} />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Стоимость</StyledTableCell>
                  <StyledTableCell>{formatMoney(Number(order.price.price), order.price.currency)}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Клиент</StyledTableCell>
                  <StyledTableCell>
                    <p>{order.customer_first_name} {order.customer_last_name} {order.customer_middle_name}</p>
                    <p>{order.customer_email}</p>
                    <p>{order.customer_phone}</p>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Дата заезда</StyledTableCell>
                  <StyledTableCell>
                    {
                      formatDate(order.arrival_date, 'D')
                    }
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Дата выезда</StyledTableCell>
                  <StyledTableCell>
                    {
                      formatDate(order.departure_date, 'D')
                    }
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" paddingY={1}>
            Информация об отеле
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: '100%' }}>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>Название</StyledTableCell>
                  <StyledTableCell>{order.hotel.name_ru}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Расположение</StyledTableCell>
                  <StyledTableCell>{order.hotel.city.name_ru}, {order.hotel.city.country.name_ru}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Адрес</StyledTableCell>
                  <StyledTableCell>{order.hotel.address_ru}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Телефон</StyledTableCell>
                  <StyledTableCell>{order.hotel.description.phone}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {
        order.order_items.map((item, index) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" paddingY={1}>
                Проживание
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: '100%' }}>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>Поставщик</StyledTableCell>
                      <StyledTableCell>{item.provider.name}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Код заказа поставщика</StyledTableCell>
                      <StyledTableCell>{item.code}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Время заезда</StyledTableCell>
                      <StyledTableCell>{item.arrival_time}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Время выезда</StyledTableCell>
                      <StyledTableCell>{item.departure_time}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Питание</StyledTableCell>
                      <StyledTableCell>
                        {
                          item.meals.map((meal, ind) => (
                            <p key={ind}>
                              {meal.name},
                              {
                                meal.included
                                  ? ' включено в стоимость'
                                  : formatMoney(Number(meal.price.price), meal.price.currency)
                              }
                            </p>
                          ))
                        }
                        {
                          item.meals.length === 0 && 'Без питания'
                        }
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Условия отмены</StyledTableCell>
                      <StyledTableCell>
                        <p>
                          {
                            item.cancel_conditions.free_cancellation
                              ? 'Бесплатная отмена'
                              : 'Отмена со штрафом'
                          }
                        </p>
                        {
                          item.cancel_conditions.policies.map((policy) => (
                            <p key={policy.date}>
                              с { formatDate(policy.date, 'f')
                              } штраф {formatMoney(Number(policy.amount), item.price.currency)}
                            </p>
                          ))
                        }
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" paddingY={1}>
                Комнаты
              </Typography>
              {
                item.rooms.map((room) => (
                  <TableContainer component={Paper} key={room.code}>
                    <Table sx={{ minWidth: '100%' }}>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell>Название номера</StyledTableCell>
                          <StyledTableCell>{room.name}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell>Гости</StyledTableCell>
                          <StyledTableCell>
                            {
                              room.guests.map((guest, guestIdx) => (
                                <p key={guestIdx}>
                                  {guest.last_name} {guest.first_name} {guest.middle_name}
                                  {
                                    guest.is_child && ` (${p11n(guest.age as number, 'год~года~лет')})`
                                  }
                                </p>
                              ))
                            }
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ))
              }
            </Grid>
          </Grid>
        ))
      }
    </>
  );
};

export default Order;
