import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from 'app/api';
import {
  HotelPhotoModel,
  PhotoRequest,
} from 'features/dictionary/types';
import { showPopupMessage } from 'features/common/slice';

export const hotelPhotoApi = createApi({
  reducerPath: 'hotelPhotoApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Photos'],
  endpoints: (build) => ({
    listHotelPhotos: build.query<HotelPhotoModel[], { hotelId: number }>(
      {
        query: ({ hotelId }) => ({ url: `hotels/${hotelId}/photos`, method: 'GET' }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'Photos', id } as const)),
              { type: 'Photos', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'HotelPhotos', id: 'LIST' }` is invalidated
            [{ type: 'Photos', id: 'LIST' }],
      }
    ),
    deleteHotelPhoto: build.mutation<{ success: boolean; id: number }, number>(
      {
        query: (id) => ({ url: `hotelPhotos/${id}`, method: 'DELETE' }),
        invalidatesTags: () => [{ type: 'Photos', id: 'LIST' }],
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(showPopupMessage({ text: 'Фотография успешно удалена' }));
        },
      }
    ),
    updateHotelPhoto: build.mutation<HotelPhotoModel, { id: number; data: PhotoRequest }>({
      query: ({ id, data }) => ({
        url: `hotelPhotos/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: () => [{ type: 'Photos', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Описание фотографии обновлено' }));
      },
    }
    ),
    uploadHotelPhotos: build.mutation<HotelPhotoModel, { hotelId: number; formData: FormData }>({
      query: ({ hotelId, formData }) => ({
        url: `hotels/${hotelId}/photos`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }),
      invalidatesTags: () => [{ type: 'Photos', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Новая фотография загружена' }));
      },
    }
    ),
  }),
});

export const {
  useLazyListHotelPhotosQuery,
  useDeleteHotelPhotoMutation,
  useUpdateHotelPhotoMutation,
  useUploadHotelPhotosMutation,
} = hotelPhotoApi;
