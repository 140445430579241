import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import { drawerStyles } from 'app/Styled';
import {
  Filter,
  FILTER_OPERATOR,
  FILTER_TYPE,
  Meta,
} from 'features/common/types';
import {
  HotelType,
  HotelTypeRequest,
} from 'features/dictionary/types';
import { usePaginatedQuery } from 'features/common/hooks';
import PageTopBar from 'features/common/layout/PageTopBar';
import {
  Button,
  Drawer,
  Typography,
} from '@mui/material';
import { PageContent } from 'features/common/layout/Styled';
import DataTable from 'features/common/components/DataTable';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import HotelTypeForm from 'features/dictionary/components/HotelTypeForm';
import {
  useAddHotelTypeMutation,
  useDeleteHotelTypeMutation,
  useLazyGetHotelTypeQuery,
  useLazyListHotelTypesQuery,
  useUpdateHotelTypeMutation,
} from 'features/dictionary/api/hotelTypes';

const drawerSx = drawerStyles(860);

const columns = [
  {
    name: 'id',
    label: 'ID',
    sortName: 'ht.id',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'ht.id',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'name',
    label: 'Название',
    sortName: 'ht.name_ru',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'ht.name_ru',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
    format: (val: string, item: HotelType) => (
      <>
        <p>{item.name_ru}</p>
        <p>{item.name_en}</p>
      </>
    ),
  },
];

const defaultFilter: Filter = {
  filterOperator: FILTER_OPERATOR.FULL,
  filterValue: '',
  filterField: 'name',
  filterPath: 'ht.name_ru',
};

const HotelTypes: FC = () => {
  const {
    settings,
    filters,
    handleSelectedItemChange,
    handleFiltersChange,
    handleLimitChange,
    handlePageChange,
    handleSortChange,
  } = usePaginatedQuery('-ht.id');

  const [ fetchListHotelTypes, hotelTypes ] = useLazyListHotelTypesQuery();
  const [ addHotelType, { isSuccess: isAdded, isLoading: isLoadingAdd } ] = useAddHotelTypeMutation();
  const [ updateHotelType, { isSuccess: isUpdated, isLoading: isLoadingUpdate } ] = useUpdateHotelTypeMutation();
  const [ fetchHotelType, { currentData: hotelType }] = useLazyGetHotelTypeQuery();
  const [ deleteHotelType ] = useDeleteHotelTypeMutation();
  const [ isDrawerOpen, setIsDrawerOpen ] = useState(false);
  const [ isCreating, setIsCreating ] = useState(false);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleDrawerClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'manual') => {
    if (reason !== 'manual') {
      return;
    }
    setIsDrawerOpen(false);
    setIsCreating(false);
    if (settings) {
      handleSelectedItemChange(undefined);
    }
  };

  const handleCreate = () => {
    setIsDrawerOpen(true);
    setIsCreating(true);
  };

  useEffect(() => {
    handleDrawerClose({}, 'manual');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, isUpdated]);

  const handleItemEdit = async (item: Partial<HotelType>) => {
    try {
      await fetchHotelType(Number(item.id));
      setIsDrawerOpen(true);
      if (item.id) {
        handleSelectedItemChange(item.id);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleRoomTypeSave = async (data: HotelTypeRequest) => {
    if (!isCreating) {
      await updateHotelType({ id: Number(hotelType?.id), data });
    } else {
      await addHotelType(data);
    }
  };

  const handleDeleteRoomType = async (item: Partial<HotelType>) => {
    await deleteHotelType(Number(item.id));
  };

  useEffect(() => {
    if (settings) {
      fetchListHotelTypes(settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (settings?.selectedItem) {
      handleItemEdit({ id: settings?.selectedItem });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.selectedItem]);

  return (
    <>
      <PageTopBar
        name="Типы комнат"
        actions={(
          <Button
            variant="contained"
            color="success"
            onClick={handleCreate}
          >
            Добавить
          </Button>
        )}
      />
      <PageContent>
        <DataTable<HotelType>
          columns={columns}
          data={hotelTypes.data ? hotelTypes.data.data : []}
          onEditEntry={handleItemEdit}
          sortedBy={settings?.sort ?? null}
          meta={hotelTypes.data?.meta as Meta}
          isLoading={hotelTypes.isLoading}
          allowDelete={true}
          onLimitChange={handleLimitChange}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          filters={filters}
          defaultFilter={defaultFilter}
          onEditFilters={handleFiltersChange}
          onDeleteEntry={handleDeleteRoomType}
        />
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          sx={drawerSx}
          variant="temporary"
        >
          <Typography variant="h5" gutterBottom padding="30px 30px 8px 30px">
            {hotelType ? 'Редактирование Типа комнаты' : 'Создание Типа комнаты'}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterLuxon} locale="ru">
            <HotelTypeForm
              isLoading={isLoadingAdd || isLoadingUpdate }
              onSuccess={handleRoomTypeSave}
              onCancel={() => handleDrawerClose({}, 'manual')}
              model={isCreating ? undefined : hotelType}
            />
          </LocalizationProvider>
        </Drawer>
      </PageContent>
    </>
  );
};

export default HotelTypes;
