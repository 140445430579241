import React, {
  FC,
  useEffect,
} from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router';
import PageTopBar from 'features/common/layout/PageTopBar';
import { PageContent } from 'features/common/layout/Styled';
import { PartnerModel } from 'features/partner/types';
import {
  useLazyGetPartnerQuery,
  useUpdatePartnerMutation,
} from 'features/partner/api/partner';
import PartnerForm from 'features/partner/components/PartnerForm';
import PartnerProviders from 'features/partner/pages/PartnerProviders';
import { Stack } from '@mui/material';

const Partner: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ fetchPartner, partner ] = useLazyGetPartnerQuery();
  const [ updatePartner, { isSuccess, isLoading } ] = useUpdatePartnerMutation();

  useEffect(() => {
    if (id) {
      fetchPartner(Number(id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isSuccess) {
    navigate('/partners');
  }

  if (!partner.data) {
    return (<div>Загрузка...</div>);
  }

  const handleSubmit = async (data: PartnerModel) => await updatePartner(data);

  return (
    <>
      <PageTopBar name={`Редактировать поставщика ${partner.data?.name}`} />
      <PageContent>
        <PartnerForm
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onCancel={() => navigate('/partners')}
          model={partner.data}
        />
        <Stack marginTop={2}>
          <PartnerProviders
            idPartner={Number(id)}
          />
        </Stack>
      </PageContent>
    </>
  );
};

export default Partner;
