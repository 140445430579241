import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import { showPopupMessage } from 'features/common/slice';
import {
  ListSettings,
  ListResponse,
} from 'features/common/types';
import { PartnerModel } from 'features/partner/types';

export const partnerApi = createApi({
  reducerPath: 'partnersApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Partners'],
  endpoints: (build) => ({
    listPartners: build.query<ListResponse<PartnerModel>, ListSettings>(
      {
        query: (settings) => ({ url: 'partners', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.data.map(({ id }) => ({ type: 'Partners', id } as const)),
              { type: 'Partners', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Partners', id: 'LIST' }` is invalidated
            [{ type: 'Partners', id: 'LIST' }],
      }
    ),
    getPartner: build.query<PartnerModel, number>({
      query: (id) => ({ url: `partners/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'Partners', id }],
    }),
    updatePartner: build.mutation<PartnerModel, PartnerModel>({
      query: (data) => {
        const { id, ...payload } = data;
        return {
          url: `partners/${id}`,
          method: 'PUT',
          data: payload,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Partners', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Партнер успешно обновлен' }));
      },
    }),
    addPartner: build.mutation<PartnerModel, PartnerModel>({
      query: (data) => ({ url: 'partners', method: 'POST', data }),
      invalidatesTags: [{ type: 'Partners', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Партнер успешно добавлен' }));
      },
    }),
  }),
});

export const {
  useListPartnersQuery,
  useLazyListPartnersQuery,
  useGetPartnerQuery,
  useLazyGetPartnerQuery,
  useUpdatePartnerMutation,
  useAddPartnerMutation,
} = partnerApi;
