import React, {
  ReactElement,
  MouseEvent,
} from 'react';
import {
  Link,
  NavLink,
  useLocation,
} from 'react-router-dom';
import { MenuItemStyled } from 'features/common/layout/Styled';

interface IMenuItem {
  label: string;
  icon: JSX.Element;
  url?: string;
  subItems?: ISubMenuItem[];
}

interface ISubMenuItem {
  label: string;
  url: string;
}

interface IMenuProps {
  item: IMenuItem;
}

const activeStyle = {
  textDecoration: 'underline',
};

const MenuItem = ({ item }: IMenuProps): ReactElement => {
  const location = useLocation();
  let titleLink = (<>
    <span className="icon">
      {item.icon}
    </span>
    {item.label}
  </>);

  if (item.url) {
    titleLink = (
      <Link to={item.url}>
        <a className="link">
          {titleLink}
        </a>
      </Link>);
  }

  const handleClick = (url: string) => (e: MouseEvent) => {
    if (url === location.pathname) {
      e.preventDefault();
    }
  };

  return (
    <MenuItemStyled>
      <div className="title">
        {titleLink}
      </div>
      {item.subItems && (
        <div className="children">
          {
            item.subItems.map((subItem, index) => (
              <div key={index} className="sub">
                <NavLink
                  end={true}
                  to={subItem.url}
                  onClick={handleClick(subItem.url)}
                  style={() => location.pathname.includes(subItem.url) ? activeStyle : {}}
                >
                  <div className="sub-link">{ subItem.label }</div>
                </NavLink>
              </div>
            ))
          }
        </div>
      )}
    </MenuItemStyled>
  );
};

export default MenuItem;
