import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from 'app/store';

interface AuthState {
  token?: string;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {} as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>
    ) => {
      state.token = token;
    },
    logout: (state) => {
      state.token = undefined;
    },
  },
});

export const {
  setCredentials,
  logout,
} = authSlice.actions;
export default authSlice.reducer;

// export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectLoggedIn = (state: RootState) => !!state.auth.token;
export const selectAuthToken = (state: RootState) => state.auth.token;
