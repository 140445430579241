import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from 'app/api';
import {
  FacilityModel,
  HotelFacilitiesRequest,
} from 'features/dictionary/types';
import { showPopupMessage } from 'features/common/slice';

export const hotelFacilityApi = createApi({
  reducerPath: 'hotelFacilityApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['HotelFacilities'],
  endpoints: (build) => ({
    listHotelFacilities: build.query<FacilityModel[], { hotelId: number }>(
      {
        query: ({ hotelId }) => ({ url: `hotels/${hotelId}/facilities`, method: 'GET' }),
        providesTags: (result) =>
          result
            ?
            [
              ...result.map(({ id }) => ({ type: 'HotelFacilities', id } as const)),
              { type: 'HotelFacilities', id: 'LIST' },
            ]
            :
            [{ type: 'HotelFacilities', id: 'LIST' }],
      }
    ),
    updateHotelFacilities: build.mutation<HotelFacilitiesRequest, { id: number; data: HotelFacilitiesRequest }>({
      query: ({ id, data }) => ({
        url: `hotels/${id}/facilities`,
        method: 'POST',
        data,
      }),
      invalidatesTags: () => [{ type: 'HotelFacilities', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Список удобства отеля обновлен' }));
      },
    }),
  }),
});

export const {
  useLazyListHotelFacilitiesQuery,
  useUpdateHotelFacilitiesMutation,
} = hotelFacilityApi;
