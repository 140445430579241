import { QueryError } from 'app/api';
import { useAppDispatch } from 'app/hooks';
import avatar from 'assets/avatar_default.png';
import { useLoginMutation } from 'features/auth/api';
import LoginForm from 'features/auth/components/LoginForm';
import { LoginRequest } from 'features/auth/types';
import {
  AuthNoLogo,
  LoginErrorMessage,
  LoginFormContainer,
} from 'features/auth/pages/Styled';
import { setCredentials } from 'features/auth/slice';
import { Block } from 'features/common/layout/Styled';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [ login, { isLoading, isError, error } ] = useLoginMutation();

  let errorMessage = '';
  if (isError) {
    const err = error as QueryError;
    if (err.status === 400) {
      errorMessage = err.data.message;
    }

    if (errorMessage === '') {
      errorMessage = 'Ошибка';
    }
  }

  const handleSubmit = async (values: LoginRequest) => {
    try {
      const result = await login(values).unwrap();
      dispatch(setCredentials(result));
      navigate('/');
    // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  return (
    <LoginFormContainer>
      <AuthNoLogo>
        Agregator
      </AuthNoLogo>
      <Block style={{ width: '400px' }}>
        <div style={{ textAlign: 'center' }} className="mb-2">
          <img
            src={avatar}
            width={100}
            height={100}
            alt="profile-img"
            className="profile-imgCard"
          />
        </div>
        <LoginForm
          isLoading={isLoading}
          onSubmit={handleSubmit}
        />
        {errorMessage && (
          <LoginErrorMessage>
            {errorMessage}
          </LoginErrorMessage>
        )}
      </Block>
    </LoginFormContainer>
  );
};

export default Login;
