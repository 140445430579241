import React, { FC } from 'react';
import { PageTopBarStyled } from 'features/common/layout/Styled';
import { Typography } from '@mui/material';

interface PageTopBarProps {
  name: string;
  actions?: JSX.Element;
}

const PageTopBar: FC<PageTopBarProps> = ({
  name,
  actions,
}) => (
  <PageTopBarStyled>
    <Typography variant="h5" gutterBottom>
      {name}
    </Typography>
    {actions && (
      <div>
        {actions}
      </div>
    )}
  </PageTopBarStyled>
);

export default PageTopBar;
