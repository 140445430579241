import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  Box,
  Button,
  createFilterOptions,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import {
  BedTypeMap,
  BED_TYPE,
  HotelRoomModel,
  ProviderMapping,
  ProviderMappingRequest,
  RoomRequest,
  RoomType,
} from 'features/dictionary/types';
import { useLazySearchProvidersQuery } from 'features/provider/api/provider';
import {
  FormikErrors,
  FormikTouched,
  useFormik,
} from 'formik';
import { useFormErrors } from 'features/common/hooks';
import FormAutocomplete from 'features/common/components/FormAutocomplete';
import { ProviderModel } from 'features/provider/types';
import { LoadingButton } from '@mui/lab';
import { Save as SaveIcon } from '@mui/icons-material';

interface IRoomFormProps {
  onSuccess: (model: RoomRequest) => void;
  onCancel: () => void;
  isLoading: boolean;
  hotelId: number;
  roomTypes: RoomType[];
  model?: HotelRoomModel;
}

const validationSchema = yup.object({
  mappings: yup.array().of(yup.object({
    provider_id: yup.number().required('Выберите поставщика'),
    provider_code: yup.string().required('Введите код'),
  })),
  room_type_id: yup
    .number(),
  name_ru: yup
    .string()
    .required('Введите название комнаты'),
  name_en: yup
    .string()
    .required('Введите название комнаты'),
  description_ru: yup
    .string(),
  description_en: yup
    .string(),
  bed_type: yup
    .string()
    .nullable()
    .oneOf([
      BED_TYPE.DOUBLE,
      BED_TYPE.SINGLE,
      BED_TYPE.TWIN,
      BED_TYPE.DORM_BED,
    ])
    .required('Укажите тип кровати'),
  occupancy: yup
    .number()
    .required('Введите вместимость комнаты'),
  extra_bed_count: yup
    .number()
    .min(0)
    .required('Введите кол-во допкроватей'),
  area: yup
    .number()
    .nullable(),
});


const RoomForm: FC<IRoomFormProps> = ({
  isLoading,
  roomTypes,
  onSuccess,
  onCancel,
  model,
}) => {

  const [ fetchTaskProviders, providers ] = useLazySearchProvidersQuery();

  useEffect(() => {
    fetchTaskProviders('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = model ? { ...model } : {
    room_type_id: 0,
    name_ru: '',
    name_en: '',
    description_ru: '',
    description_en: '',
    bed_type: BED_TYPE.TWIN,
    occupancy: 0,
    extra_bed_count: 0,
    area: 0,
    mappings: [] as ProviderMapping[],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const mappings = values.mappings.reduce<ProviderMappingRequest>((ret, value) => {
        ret[value.provider_id] = value.provider_code;

        return ret;
      }, {});

      const request = {
        ...values,
        mappings,
      };
      onSuccess(request);
    },
  });

  useFormErrors(formik.setErrors);

  const handleProviderAdd = () => {
    const provs = getAvailableProviders();
    if (provs.length === 0) {
      return;
    }

    formik.setValues(values => ({
      ...values,
      mappings: [
        ...values.mappings,
        {
          provider_id: Number(provs[0].id),
          provider_code: '',
        },
      ],
    }));
  };

  const handleRemoveMapping = (key: number) => () => {
    formik.setValues(values => ({
      ...values,
      mappings: values.mappings.filter((_, idx) => idx !== key),
    }));
  };

  const getAvailableProviders = useCallback((id?: number) => providers.data?.filter(item => item.id === id
    || !formik.values.mappings.find(val => val.provider_id === item.id)
  ) || [], [formik.values.mappings, providers.data]);

  const mappingsEl = useMemo(() => (formik.values.mappings as ProviderMapping[]).map((mapping, idx) => (
    <Fragment key={mapping.provider_id}>
      <Grid item xs={12} sm={5}>
        <FormAutocomplete<ProviderModel>
          field={`mappings[${idx}].provider_id`}
          label="Поставщик"
          isFetching={providers.isLoading}
          list={getAvailableProviders(mapping.provider_id)}
          selectedOption={providers.data?.find(item => item.id === mapping.provider_id)}
          disableClearable={true}
          error={false}
          helperText=""
          filterOptions={createFilterOptions<ProviderModel>({
            stringify: ({ name }) => name,
          })}
          comparator={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.name}
            </Box>
          )}
          handleChange={(event, value) => formik.setFieldValue(`mappings[${idx}].provider_id`, value ? value.id : 0)}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          fullWidth
          id={`mappings[${idx}].provider_code`}
          label="Код поставщика"
          value={formik.values.mappings[idx].provider_code}
          onChange={formik.handleChange}
          error={(formik.touched.mappings?.at(idx) as FormikTouched<ProviderMapping>)?.provider_code
            && Boolean((formik.errors.mappings?.at(idx) as FormikErrors<ProviderMapping>)?.provider_code)}
          helperText={(formik.touched.mappings?.at(idx) as FormikTouched<ProviderMapping>)?.provider_code
            && (formik.errors.mappings?.at(idx) as FormikErrors<ProviderMapping>)?.provider_code}
        />
      </Grid>
      <Grid item xs={12} sm={2} display="flex" alignItems="center">
        <Button onClick={handleRemoveMapping(idx)}>Удалить</Button>
      </Grid>
    </Fragment>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  )), [getAvailableProviders, handleRemoveMapping, providers.data, providers.isLoading]);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Stack direction="row" justifyContent="space-between" paddingY={2}>
        <Typography variant="h6" gutterBottom paddingBottom={3}>
          Коды поставщиков
        </Typography>
        <Button
          size="medium"
          disabled={getAvailableProviders().length === 0}
          sx={{ height: '50%' }}
          variant="contained"
          onClick={handleProviderAdd}
        >
        Добавить
        </Button>
      </Stack>
      <Grid container spacing={2}>
        {mappingsEl}
      </Grid>
      <Grid container spacing={2} marginTop="10px">
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="room_type_id"
            name="room_type_id"
            label="Тип комнаты"
            select={true}
            value={formik.values.room_type_id}
            onChange={formik.handleChange}
            error={formik.touched.room_type_id && Boolean(formik.errors.room_type_id)}
            helperText={formik.touched.room_type_id && formik.errors.room_type_id}
          >
            <MenuItem key={0} value={0}>
                Не выбран
            </MenuItem>
            {roomTypes.map((room) => (
              <MenuItem key={room.id} value={room.id}>
                {room.name_ru}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="bed_type"
            name="bed_type"
            label="Тип кровати"
            select={true}
            value={formik.values.bed_type}
            onChange={formik.handleChange}
            error={formik.touched.bed_type && Boolean(formik.errors.bed_type)}
            helperText={formik.touched.bed_type && formik.errors.bed_type}
          >
            {Object.values(BED_TYPE).map((type) => (
              <MenuItem key={type} value={type}>
                {BedTypeMap[type]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="name_ru"
            label="Название комнаты на русском"
            value={formik.values.name_ru}
            onChange={formik.handleChange}
            error={formik.touched.name_ru && Boolean(formik.errors.name_ru)}
            helperText={formik.touched.name_ru && formik.errors.name_ru}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="name_en"
            label="Название комнаты на английском"
            value={formik.values.name_en}
            onChange={formik.handleChange}
            error={formik.touched.name_en && Boolean(formik.errors.name_en)}
            helperText={formik.touched.name_en && formik.errors.name_en}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="description_ru"
            label="Описание комнаты на русском"
            value={formik.values.description_ru}
            onChange={formik.handleChange}
            error={formik.touched.description_ru && Boolean(formik.errors.description_ru)}
            helperText={formik.touched.description_ru && formik.errors.description_ru}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="description_en"
            label="Описание комнаты на английском"
            value={formik.values.description_en}
            onChange={formik.handleChange}
            error={formik.touched.description_en && Boolean(formik.errors.description_en)}
            helperText={formik.touched.description_en && formik.errors.description_en}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="occupancy"
            label="Вместимость комнаты"
            type="number"
            value={formik.values.occupancy}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={formik.handleChange}
            error={formik.touched.occupancy && Boolean(formik.errors.occupancy)}
            helperText={formik.touched.occupancy && formik.errors.occupancy}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="extra_bed_count"
            label="Количество допкроватей"
            type="number"
            value={formik.values.extra_bed_count}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={formik.handleChange}
            error={formik.touched.extra_bed_count && Boolean(formik.errors.extra_bed_count)}
            helperText={formik.touched.extra_bed_count && formik.errors.extra_bed_count}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="area"
            label="Площадь комнаты"
            value={formik.values.area}
            onChange={formik.handleChange}
            error={formik.touched.area && Boolean(formik.errors.area)}
            helperText={formik.touched.area && formik.errors.area}
          />
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2} paddingTop={4}>
        <LoadingButton
          color="success"
          loadingPosition="start"
          variant="contained"
          type="submit"
          startIcon={<SaveIcon />}
          loading={isLoading}
        >
          {model ? 'Редактировать' : 'Создать'}
        </LoadingButton>
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          Вернуться назад
        </Button>
      </Stack>
    </Box>
  );
};

export default RoomForm;
