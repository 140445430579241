import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import { showPopupMessage } from 'features/common/slice';
import {
  ListResponse,
  ListSettings,
} from 'features/common/types';
import {
  CityModel,
  CityRequest,
} from 'features/dictionary/types';

export const cityApi = createApi({
  reducerPath: 'citiesApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Cities'],
  endpoints: (build) => ({
    listCities: build.query<ListResponse<CityModel>, ListSettings>(
      {
        query: (settings) => ({ url: 'cities', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.data.map(({ id }) => ({ type: 'Cities', id } as const)),
              { type: 'Cities', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Cities', id: 'LIST' }` is invalidated
            [{ type: 'Cities', id: 'LIST' }],
      }
    ),
    searchCities: build.query<CityModel[], string>(
      {
        query: (search) => ({ url: search.length > 0 ? `cities/by-name?term=${search}` : 'cities?all=1', method: 'GET' }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'Cities', id } as const)),
              { type: 'Cities', id: 'LIST' },
            ]
            :
            [{ type: 'Cities', id: 'LIST' }],
        transformResponse: (response: CityModel[] | ListResponse<CityModel>) => 'data' in response ? response.data : response,
      }
    ),
    addCity: build.mutation<CityModel, CityRequest>({
      query: (data) => ({ url: 'cities', method: 'POST', data }),
      // Invalidates all City-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created city could show up in any lists.
      invalidatesTags: [{ type: 'Cities', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Город успешно добавлен' }));
      },
    }),
    getCity: build.query<CityModel, number>({
      query: (id) => ({ url: `cities/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'Cities', id }],
    }),
    updateCity: build.mutation<CityModel, { id: number; data: CityRequest }>({
      query: ({ id, data }) => ({
        url: `cities/${id}`,
        method: 'PUT',
        data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [{ type: 'Cities', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Город успешно обновлен' }));
      },
    }),
    deleteCity: build.mutation<{ success: boolean; id: number }, number>(
      {
        query: (id) => ({ url: `cities/${id}`, method: 'DELETE' }),
        invalidatesTags: (result, error, id) => [{ type: 'Cities', id }],
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(showPopupMessage({ text: 'Город успешно удален' }));
        },
      }
    ),
    updateCityMainPhoto: build.mutation<CityModel, { id: number; data: { photo_id: number } }>({
      query: ({ id, data }) => ({
        url: `cities/${id}/photo`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Cities', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Главная фотография города успешно обновлена' }));
      },
    }),
  }),
});

export const {
  useListCitiesQuery,
  useGetCityQuery,
  useLazyGetCityQuery,
  useLazySearchCitiesQuery,
  useAddCityMutation,
  useLazyListCitiesQuery,
  useUpdateCityMutation,
  useDeleteCityMutation,
  useUpdateCityMainPhotoMutation,
} = cityApi;
