import { useAppSelector } from 'app/hooks';
import { injectNavigate } from 'app/api';
import Login from 'features/auth/pages/Login';
import { selectLoggedIn } from 'features/auth/slice';
import { Layout } from 'features/common/layout/Layout';
import React, { FC } from 'react';
import {
  useRoutes,
  useNavigate,
  RouteObject,
  Link,
  Navigate,
} from 'react-router-dom';
import Provider from 'features/provider/pages/Provider';
import useNotifier from 'app/useNotifier';
import Partners from 'features/partner/pages/Partners';
import Partner from 'features/partner/pages/Partner';
import PartnerCreate from 'features/partner/pages/PartnerCreate';
import MappingTasks from 'features/provider/pages/MappingTasks';
import ProviderTasks from 'features/provider/pages/ProviderTasks';
import ProviderTaskCreate from 'features/provider/pages/ProviderTaskCreate';
import Countries from 'features/dictionary/pages/Countries';
import Cities from 'features/dictionary/pages/Cities';
import Regions from 'features/dictionary/pages/Regions';
import Hotels from 'features/dictionary/pages/Hotels';
import ProviderLogs from 'features/provider/pages/ProviderLogs';
import ProviderLogsSelector from 'features/provider/pages/ProviderLogsSelector';
import Providers from 'features/provider/pages/Providers';
import Facilities from 'features/dictionary/pages/Facilities';
import Users from 'features/user/pages/Users';
import RoomTypes from 'features/dictionary/pages/RoomTypes';
import Orders from 'features/order/pages/Orders';
import Order from 'features/order/pages/Order';
import FacilityCategories from 'features/dictionary/pages/FacilityCategories';
import Invite from 'features/auth/pages/Invite';
import HotelTypes from 'features/dictionary/pages/HotelTypes';

const App = () => {
  useNotifier();
  const navigate = useNavigate();
  injectNavigate(navigate);

  const isLoggedIn = useAppSelector(selectLoggedIn);

  const routes: RouteObject[] = [
    {
      path: '/',
      element: isLoggedIn ? <Layout /> : <Navigate to="/login" />,
      children: [
        {
          path: '/orders',
          children: [
            {
              path: '',
              element: <Orders />,
            },
            {
              path: ':code',
              element: <Order />,
            },
          ],
        },
        {
          path: '/providers',
          children: [
            { path: 'list', element: <Providers /> },
            { path: 'list/:id', element: <Provider /> },
            { path: 'mapping-tasks', element: <MappingTasks /> },
            { path: 'import-tasks', element: <ProviderTasks /> },
            { path: 'import-tasks/create', element: <ProviderTaskCreate /> },
          ],
        },
        {
          path: '/partners',
          element: <Partners />,
        },
        {
          path: '/partners/:id',
          element: <Partner />,
        },
        {
          path: '/partners/create',
          element: <PartnerCreate />,
        },
        {
          path: '/provider-logs',
          element: <ProviderLogsSelector />,
          children: [
            { index: true, path: '/provider-logs/:id', element: <ProviderLogs /> },
          ],
        },
        { path: '/countries', element: <Countries /> },
        { path: '/regions', element: <Regions /> },
        { path: '/cities', element: <Cities /> },
        { path: '/hotels', element: <Hotels /> },
        { path: '/hotel-types', element: <HotelTypes /> },
        { path: '/facilities', element: <Facilities /> },
        { path: '/facility-categories', element: <FacilityCategories /> },
        { path: '/room-types', element: <RoomTypes /> },
        { path: '/users', element: <Users /> },
        { path: '*', element: <NoMatch /> },
      ],
    },
    {
      path: '/login',
      element: !isLoggedIn ? <Login /> : <Navigate to="/" />,
    },
    {
      path: '/invite',
      element: <Invite />,
    },
  ];

  // The useRoutes() hook allows you to define your routes as JavaScript objects
  // instead of <Routes> and <Route> elements. This is really just a style
  // preference for those who prefer to not use JSX for their routes config.
  // const element = useRoutes(routes);
  return useRoutes(routes);
};

const NoMatch: FC = () => (
  <div>
    <h2>Страница не найдена...</h2>
    <p>
      <Link to="/">Вернуться на главную страницу</Link>
    </p>
  </div>
);

export default App;
