export interface ProviderModel {
  id?: number;
  code: string;
  name: string;
  active: boolean;
  url: string;
  db_schema: string;
  options: number;
}

export interface ProviderMappingTasksModel {
  id?: number;
  name: string;
  provider_task: {
    id: number;
    type: string;
    provider: {
      id: number;
      name: string;
    };
  };
  status: string;
  started_at: string;
  finished_at: string | null;
  inserted_count: number;
}

export interface ProviderCredential{
  label: string;
  name: string;
}

export enum ProviderTaskType {
  Hotel   = 'hotel',
  City    = 'city',
  Country = 'country',
  Region  = 'region',
  Meal    = 'meal_type',
  Room    = 'room_type',
}

export const ProviderTaskTypeMap = {
  [ProviderTaskType.Hotel]: 'Отель',
  [ProviderTaskType.City]: 'Город',
  [ProviderTaskType.Country]: 'Страна',
  [ProviderTaskType.Region]: 'Регион',
  [ProviderTaskType.Meal]: 'Питание',
  [ProviderTaskType.Room]: 'Комната',
};

export enum ProviderTaskStatus {
  Pending    = 'pending',
  Processing = 'processing',
  Completed  = 'completed',
  Failed     = 'failed',
}

export const ProviderTaskStatusMap = {
  [ProviderTaskStatus.Pending]: 'Ожидает',
  [ProviderTaskStatus.Processing]: 'В обработке',
  [ProviderTaskStatus.Completed]: 'Завершена',
  [ProviderTaskStatus.Failed]: 'Ошибка',
};

export interface ProviderTaskParameters {
  country_code: string | null;
  region_code: string | null;
  city_code: string | null;
  hotel_code: string | null;
}

export interface ProviderTaskModel {
  id?: number;
  provider_id: number;
  provider: ProviderModel;
  type: ProviderTaskType;
  status: ProviderTaskStatus;
  started_at: string;
  finished_at: string;
  inserted_count: number;
  updated_count: number;
  deleted_count: number;
  parameters: ProviderTaskParameters;
}

export interface ProviderTaskRequest {
  provider_id: number;
  type: ProviderTaskType;
  parameters: ProviderTaskParameters;
}

export enum ProviderLogQueryType {
  Other = 0,
  Search = 1,
  Actualize = 2,
  Book = 3,
  Cancel = 4,
  Info = 5
}

export interface ProviderLogModel {
  id?: number;
	// Тип запроса
	// 0 - other 1 - search 2 - actualize 3 - book 4 - cancel 5 - info
	type: ProviderLogQueryType;
	// Адресная строк
	// example: 1
	url: string;
	// Код ответа
	// example: 400
	response_status_code: number;
	// Время запроса
	// example: 2022-09-28 10:27:03
	requested_at: string;
	// Время ответа
	// example: 2022-09-28 10:27:04
	responded_at: string;

  // Заголовок запроса
	// example: Allow POST ...
	request_header: string;
	// Заголовок ответа
	// example: Allow POST ...
	response_header: string;
	// Тело запроса
	// example: { "hotels": [ ... }
	request_body: string;
	// Тело ответа
	// example: { "error": null, ... }
	response_body: string;
}

export const ProviderLogTypeMap = {
  [ProviderLogQueryType.Other]: 'Other',
  [ProviderLogQueryType.Actualize]: 'Actualize',
  [ProviderLogQueryType.Book]: 'Book',
  [ProviderLogQueryType.Cancel]: 'Cancel',
  [ProviderLogQueryType.Info]: 'Info',
  [ProviderLogQueryType.Search]: 'Search',
};
