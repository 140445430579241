import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import { showPopupMessage } from 'features/common/slice';
import {
  ListResponse,
  ListSettings,
} from 'features/common/types';
import {
  CountryModel,
  CountryRequest,
} from 'features/dictionary/types';

export const countryApi = createApi({
  reducerPath: 'countriesApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Countries'],
  endpoints: (build) => ({
    listCountries: build.query<ListResponse<CountryModel>, ListSettings>(
      {
        query: (settings) => ({ url: 'countries', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.data.map(({ id }) => ({ type: 'Countries', id } as const)),
              { type: 'Countries', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Countries', id: 'LIST' }` is invalidated
            [{ type: 'Countries', id: 'LIST' }],
      }
    ),
    searchCountries: build.query<CountryModel[], string>(
      {
        query: (search) => ({ url: search.length > 0 ? `countries/by-name?term=${search}` : 'countries?all=1', method: 'GET' }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'Countries', id } as const)),
              { type: 'Countries', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Countries', id: 'LIST' }` is invalidated
            [{ type: 'Countries', id: 'LIST' }],
      }
    ),
    addCountry: build.mutation<CountryModel, CountryRequest>({
      query: (data) => ({ url: 'countries', method: 'POST', data }),
      invalidatesTags: [{ type: 'Countries', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Страна успешно добавлена' }));
      },
    }),
    getCountry: build.query<CountryModel, number>({
      query: (id) => ({ url: `countries/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'Countries', id }],
    }),
    updateCountry: build.mutation<CountryModel, { id: number; data: CountryRequest }>({
      query: ({ id, data }) => ({
        url: `countries/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Countries', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Страна успешно обновлена' }));
      },
    }),
    deleteCountry: build.mutation<{ success: boolean; id: number }, number>(
      {
        query: (id) => ({ url: `countries/${id}`, method: 'DELETE' }),
        invalidatesTags: (result, error, id) => [{ type: 'Countries', id }],
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(showPopupMessage({ text: 'Страна успешно удалена' }));
        },
      }
    ),
  }),
});

export const {
  useListCountriesQuery,
  useGetCountryQuery,
  useLazyGetCountryQuery,
  useAddCountryMutation,
  useLazyListCountriesQuery,
  useLazySearchCountriesQuery,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
} = countryApi;
