import React, {
  FC,
  useEffect,
} from 'react';
import DataTable from 'features/common/components/DataTable';
import { usePaginatedQuery } from 'features/common/hooks';
import {
  Filter,
  FILTER_OPERATOR,
  FILTER_TYPE,
  Meta,
} from 'features/common/types';
import { useNavigate } from 'react-router-dom';
import { PageContent } from 'features/common/layout/Styled';
import PageTopBar from 'features/common/layout/PageTopBar';
import {
  ProviderTaskModel,
  ProviderTaskParameters,
  ProviderTaskStatus,
  ProviderTaskStatusMap,
  ProviderTaskType,
  ProviderTaskTypeMap,
} from 'features/provider/types';
import { useLazyListProviderTasksQuery } from 'features/provider/api/providerTask';
import {
  Box,
  Button,
} from '@mui/material';

const hasParams = (params: ProviderTaskParameters) => params.city_code !== null
  || params.country_code !== null
  || params.region_code !== null
  || params.hotel_code !== null;

const columns = [
  {
    name: 'id',
    label: 'ID',
    sortName: 'pt.id',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'pt.id',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'provider',
    label: 'Поставщик',
    sortName: 'pt.provider_id',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'pt.provider_id',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
    format: (val: string, item: ProviderTaskModel) => (
      <>{item.provider?.name}</>
    ),
  },
  {
    name: 'type',
    label: 'Тип',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'pt.type',
        filterType: FILTER_TYPE.SELECT,
      },
    ],
    options: {
      selectOpts: [
        {
          value: ProviderTaskType.City,
          label: 'Город',
        },
        {
          value: ProviderTaskType.Country,
          label: 'Страна',
        },
        {
          value: ProviderTaskType.Hotel,
          label: 'Отель',
        },
        {
          value: ProviderTaskType.Region,
          label: 'Регион',
        },
      ],
    },
    format: (val: string, item: ProviderTaskModel) => (
      <>{ProviderTaskTypeMap[item.type]}</>
    ),
  },
  {
    name: 'status',
    label: 'Статус',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'pt.status',
        filterType: FILTER_TYPE.SELECT,
      },
    ],
    options: {
      selectOpts: [
        {
          value: ProviderTaskStatus.Completed,
          label: ProviderTaskStatusMap[ProviderTaskStatus.Completed],
        },
        {
          value: ProviderTaskStatus.Pending,
          label: ProviderTaskStatusMap[ProviderTaskStatus.Pending],
        },
        {
          value: ProviderTaskStatus.Processing,
          label: ProviderTaskStatusMap[ProviderTaskStatus.Processing],
        },
        {
          value: ProviderTaskStatus.Failed,
          label: ProviderTaskStatusMap[ProviderTaskStatus.Failed],
        },
      ],
    },
    format: (val: string, item: ProviderTaskModel) => (
      <>{ProviderTaskStatusMap[item.status]}</>
    ),
  },
  {
    name: 'parameters',
    label: 'Параметры',
    format: (params: ProviderTaskParameters) => hasParams(params) ? (
      <>
        {params.country_code && <Box component="p" whiteSpace="nowrap">{`Код страны: ${params.country_code}`}</Box>}
        {params.region_code && <Box component="p" whiteSpace="nowrap">{`Код региона: ${params.region_code}`}</Box>}
        {params.city_code && <Box component="p" whiteSpace="nowrap">{`Код города: ${params.city_code}`}</Box>}
        {params.hotel_code && <Box component="p" whiteSpace="nowrap">{`Код отеля: ${params.hotel_code}`}</Box>}
      </>
    ) : <Box textAlign="center">&#8212;</Box>,
  },
  {
    name: 'started_at',
    label: 'Начата',
    sortName: 'pt.started_at',
  },
  {
    name: 'finished_at',
    label: 'Завершена',
    sortName: 'pt.finished_at',
  },
  {
    name: 'inserted_count',
    label: 'Добавлено',
    sortName: 'pt.inserted_count',
  },
  {
    name: 'updated_count',
    label: 'Обновлено',
    sortName: 'pt.updated_count',
  },
  {
    name: 'deleted_count',
    label: 'Удалено',
    sortName: 'pt.deleted_count',
  },
];

const defaultFilter: Filter = {
  filterOperator: FILTER_OPERATOR.FULL,
  filterValue: '',
  filterField: 'provider',
  filterPath: 'pt.provider_id',
};

const ProviderTasks: FC = () => {
  const {
    settings,
    filters,
    handleFiltersChange,
    handleLimitChange,
    handlePageChange,
    handleSortChange,
  } = usePaginatedQuery('-p.id');
  const [ fetchProviderTasks, providerTasks ] = useLazyListProviderTasksQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (settings) {
      fetchProviderTasks(settings);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return (
    <>
      <PageTopBar
        name="Задачи импорта"
        actions={(
          <Button
            variant="contained"
            color="success"
            onClick={() => navigate('/providers/import-tasks/create')}
          >
            Добавить
          </Button>
        )}
      />
      <PageContent>
        <DataTable<ProviderTaskModel>
          columns={columns}
          data={providerTasks.data?.data ?? []}
          sortedBy={settings?.sort ?? null}
          meta={providerTasks.data?.meta as Meta}
          isLoading={providerTasks.isLoading}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onLimitChange={handleLimitChange}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          filters={filters}
          defaultFilter={defaultFilter}
          onEditFilters={handleFiltersChange}
          allowDelete={false}
          displayMenu={false}
        />
      </PageContent>
    </>
  );
};

export default ProviderTasks;
