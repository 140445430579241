import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from 'app/api';
import {
  HotelRoomModel,
  RoomRequest,
} from 'features/dictionary/types';
import { showPopupMessage } from 'features/common/slice';
import { ListResponse } from 'features/common/types';

export const hotelRoomApi = createApi({
  reducerPath: 'hotelRoomApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Rooms'],
  endpoints: (build) => ({
    listHotelRooms: build.query<ListResponse<HotelRoomModel>, { hotelId: number }>({
      query: ({ hotelId }) => ({ url: `hotels/${hotelId}/rooms`, method: 'GET' }),
      providesTags: (result) =>
        result
          ? // successful query
          [
            ...result.data.map(({ id }) => ({ type: 'Rooms', id } as const)),
            { type: 'Rooms', id: 'LIST' },
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'HotelRooms', id: 'LIST' }` is invalidated
          [{ type: 'Rooms', id: 'LIST' }],
    }),
    getRoom: build.query<HotelRoomModel, number>({
      query: (id) => ({ url: `rooms/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'Rooms', id }],
    }),
    deleteHotelRoom: build.mutation<{ success: boolean; id: number }, number>({
      query: (id) => ({ url: `rooms/${id}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: 'Rooms', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Комната успешно удалена' }));
      },
    }),
    updateHotelRoom: build.mutation<HotelRoomModel, { id: number; data: RoomRequest }>({
      query: ({ id, data }) => ({
        url: `rooms/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: () => [{ type: 'Rooms', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Комната отеля обновлена' }));
      },
    }),
    uploadHotelRooms: build.mutation<HotelRoomModel, { hotelId: number; data: RoomRequest }>({
      query: ({ hotelId, data }) => ({
        url: `hotels/${hotelId}/rooms`,
        method: 'POST',
        data,
      }),
      invalidatesTags: () => [{ type: 'Rooms', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Новая комната загружена' }));
      },
    }),
    updateHotelRoomMainPhoto: build.mutation<HotelRoomModel, { id: number; data: { photo_id: number } }>({
      query: ({ id, data }) => ({
        url: `rooms/${id}/photo`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Rooms', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Главная фотография комнаты успешно обновлен' }));
      },
    }),
  }),
});

export const {
  useLazyListHotelRoomsQuery,
  useGetRoomQuery,
  useLazyGetRoomQuery,
  useDeleteHotelRoomMutation,
  useUpdateHotelRoomMutation,
  useUploadHotelRoomsMutation,
  useUpdateHotelRoomMainPhotoMutation,
} = hotelRoomApi;
