import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { SnackbarKey } from 'notistack';

type MessageVariant = 'success' | 'warning' | 'error' | 'info';

export interface FormErrors { [key: string]: string[] }
export interface PopupMessage {
  key: SnackbarKey;
  message: string;
  type: MessageVariant;
  dismissed: boolean;
}

interface ICommonState {
  popupMessages: PopupMessage[];
  formErrors: FormErrors;
}

const initialState = {
  popupMessages: [],
  formErrors: {},
} as ICommonState;

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    showPopupMessage(state, action: { payload: { text: string; type?: MessageVariant } }) {
      state.popupMessages.push({
        key: String(new Date().getTime() + Math.random()),
        message: action.payload.text,
        type: action.payload.type ?? 'success',
        dismissed: false,
      });
    },
    closePopupMessage(state, action: { payload: { key: SnackbarKey } }) {
      state.popupMessages.forEach(item => {
        if (item.key === action.payload.key) {
          item.dismissed = true;
        }
      });
    },
    removePopupMessage(state, action: { payload: { key: SnackbarKey } }) {
      state.popupMessages = state.popupMessages.filter(item => item.key !== action.payload.key);
    },
    setFormErrors(state, action: { payload: FormErrors }) {
      state.formErrors = action.payload;
    },
    clearFormErrors(state) {
      state.formErrors = {};
    },
  },
});

export const {
  closePopupMessage,
  removePopupMessage,
  showPopupMessage,
  setFormErrors,
  clearFormErrors,
} = commonSlice.actions;
export default commonSlice.reducer;

export const selectFormErrors = (state: RootState) => state.common.formErrors;
export const selectPopupMessages = (state: RootState) => state.common.popupMessages || [];
