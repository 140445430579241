import React, {
  FC,
  useEffect,
} from 'react';
import {
  Box,
  Button,
  createFilterOptions,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useFormErrors } from 'features/common/hooks';
import {
  ProviderTaskRequest,
  ProviderTaskType,
  ProviderTaskTypeMap,
  ProviderModel,
} from 'features/provider/types';
import FormAutocomplete from 'features/common/components/FormAutocomplete';
import { useLazySearchProvidersQuery } from 'features/provider/api/provider';

interface ProviderFormProps {
  onSubmit: (model: ProviderTaskRequest) => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const initialProviderTaskValues = {
  provider_id: 0,
  type: ProviderTaskType.City,
  parameters: {
    country_code: null,
    region_code: null,
    city_code: null,
    hotel_code: null,
  },
};

const validationSchema = yup.object({
  provider_id: yup
    .number()
    .moreThan(0, 'Выберите поставщика'),
});

const ProviderTaskForm: FC<ProviderFormProps> = ({
  onSubmit,
  onCancel,
  isLoading,
}) => {
  const [ fetchTaskProviders, providers ] = useLazySearchProvidersQuery();

  useEffect(() => {
    fetchTaskProviders('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik<ProviderTaskRequest>({
    initialValues: { ...initialProviderTaskValues },
    validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  useFormErrors(formik.setErrors);

  const renderParams = () => {
    let components = <></>;
    switch (formik.values.type) {
    case ProviderTaskType.Country:
      components = (
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="parameters.country_code"
            label="Код страны"
            value={formik.values.parameters.country_code}
            onChange={formik.handleChange}
            error={formik.touched.parameters?.country_code && Boolean(formik.errors.parameters?.country_code)}
            helperText={formik.touched.parameters?.country_code && formik.errors.parameters?.country_code}
          />
        </Grid>
      ); break;
    case ProviderTaskType.Region:
      components = (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="parameters.country_code"
              label="Код страны"
              value={formik.values.parameters.country_code}
              onChange={formik.handleChange}
              error={formik.touched.parameters?.country_code && Boolean(formik.errors.parameters?.country_code)}
              helperText={formik.touched.parameters?.country_code && formik.errors.parameters?.country_code}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="parameters.region_code"
              label="Код региона"
              value={formik.values.parameters.region_code}
              onChange={formik.handleChange}
              error={formik.touched.parameters?.region_code && Boolean(formik.errors.parameters?.region_code)}
              helperText={formik.touched.parameters?.region_code && formik.errors.parameters?.region_code}
            />
          </Grid>
        </>
      ); break;
    case ProviderTaskType.City:
      components = (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="parameters.country_code"
              label="Код страны"
              value={formik.values.parameters.country_code}
              onChange={formik.handleChange}
              error={formik.touched.parameters?.country_code && Boolean(formik.errors.parameters?.country_code)}
              helperText={formik.touched.parameters?.country_code && formik.errors.parameters?.country_code}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="parameters.city_code"
              label="Код города"
              value={formik.values.parameters.city_code}
              onChange={formik.handleChange}
              error={formik.touched.parameters?.city_code && Boolean(formik.errors.parameters?.city_code)}
              helperText={formik.touched.parameters?.city_code && formik.errors.parameters?.city_code}
            />
          </Grid>
        </>
      ); break;
    case ProviderTaskType.Hotel:
      components = (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="parameters.country_code"
              label="Код страны"
              value={formik.values.parameters.country_code}
              onChange={formik.handleChange}
              error={formik.touched.parameters?.country_code && Boolean(formik.errors.parameters?.country_code)}
              helperText={formik.touched.parameters?.country_code && formik.errors.parameters?.country_code}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="parameters.region_code"
              label="Код региона"
              value={formik.values.parameters.region_code}
              onChange={formik.handleChange}
              error={formik.touched.parameters?.region_code && Boolean(formik.errors.parameters?.region_code)}
              helperText={formik.touched.parameters?.region_code && formik.errors.parameters?.region_code}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="parameters.city_code"
              label="Код города"
              value={formik.values.parameters.city_code}
              onChange={formik.handleChange}
              error={formik.touched.parameters?.city_code && Boolean(formik.errors.parameters?.city_code)}
              helperText={formik.touched.parameters?.city_code && formik.errors.parameters?.city_code}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="parameters.hotel_code"
              label="Код отеля"
              value={formik.values.parameters.hotel_code}
              onChange={formik.handleChange}
              error={formik.touched.parameters?.hotel_code && Boolean(formik.errors.parameters?.hotel_code)}
              helperText={formik.touched.parameters?.hotel_code && formik.errors.parameters?.hotel_code}
            />
          </Grid>
        </>
      ); break;
    }

    return (
      <Grid container spacing={2} marginTop={1}>
        {components}
      </Grid>
    );
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormAutocomplete<ProviderModel>
            field="provider_id"
            label="Поставщик"
            isFetching={providers.isLoading}
            list={providers.data || []}
            error={formik.touched.provider_id && Boolean(formik.errors.provider_id)}
            helperText={formik.touched.provider_id && formik.errors.provider_id}
            filterOptions={createFilterOptions<ProviderModel>({
              stringify: ({ name }) => name,
            })}
            comparator={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id}>
                {option.name}
              </Box>
            )}
            handleChange={(event, value) => formik.setFieldValue('provider_id', value ? value.id : 0)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="type"
            name="type"
            label="Тип задачи"
            select={true}
            value={formik.values.type}
            onChange={formik.handleChange}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && formik.errors.type}
          >
            {Object.values(ProviderTaskType).map((type) => (
              <MenuItem key={type} value={type}>
                {ProviderTaskTypeMap[type]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      {renderParams()}
      <Stack direction="row" spacing={2} marginTop={2}>
        <LoadingButton
          color="success"
          loadingPosition="start"
          variant="contained"
          type="submit"
          startIcon={<SaveIcon />}
          loading={isLoading}
        >
          Создать
        </LoadingButton>
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          Вернуться назад
        </Button>
      </Stack>
    </Box>
  );
};

export default ProviderTaskForm;
