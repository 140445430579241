import App from 'app/App';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { injectStore } from 'app/api';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import {
  inputGlobalStyles,
  customTheme,
} from 'app/Styled';
import { setupStore } from 'app/store';
import { persistStore } from 'redux-persist';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';

let appReady = Promise.resolve();

if (process.env.USE_MSW === 'true') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  appReady = worker.start({
    serviceWorker: {
      url: '/mockServiceWorker.js',
    },
  });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);
const store = setupStore();
const persistor = persistStore(store);

appReady.then(() =>
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <SnackbarProvider>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <ThemeProvider theme={customTheme}>
                {inputGlobalStyles}
                <LocalizationProvider dateAdapter={AdapterLuxon} locale="ru">
                  <App />
                </LocalizationProvider>
              </ThemeProvider>
            </BrowserRouter>
          </PersistGate>
        </SnackbarProvider>
      </Provider>
    </React.StrictMode>
  )
);

injectStore(store);
