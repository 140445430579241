import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import { ListResponse } from 'features/common/types';
import { UserRoleModel } from 'features/user/types';

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Roles'],
  endpoints: (build) => ({
    listRoles: build.query<ListResponse<UserRoleModel>, void>(
      {
        query: () => ({ url: 'roles', method: 'GET' }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.data.map(({ id }) => ({ type: 'Roles', id } as const)),
              { type: 'Roles', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Roles', id: 'LIST' }` is invalidated
            [{ type: 'Roles', id: 'LIST' }],
      }
    ),
  }),
});

export const {
  useListRolesQuery,
} = roleApi;
