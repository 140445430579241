import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import { showPopupMessage } from 'features/common/slice';
import {
  PartnerProviderModel,
  PartnerProviderRequest,
} from 'features/partner/types';

export const partnerProvidersApi = createApi({
  reducerPath: 'partnerProvidersApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['PartnerProviders'],
  endpoints: (build) => ({
    listPartnerProviders: build.query<PartnerProviderModel[], number>(
      {
        query: (id_partner) => ({ url: `partners/${id_partner}/providers`, method: 'GET', undefined }),
        providesTags: (result) =>
          (result && result.length > 0)
            ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'PartnerProviders', id } as const)),
              { type: 'PartnerProviders', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Partners', id: 'LIST' }` is invalidated
            [{ type: 'PartnerProviders', id: 'LIST' }],
      }
    ),
    getPartnerProviders: build.query<PartnerProviderModel, { id_partner: number; id: number }>({
      query: (data) => ({ url: `partners/${data.id_partner}/providers/${data.id}`, method: 'GET' }),
      providesTags: (result, error, { id }) => [{ type: 'PartnerProviders', id }],
    }),
    updatePartnerProvider: build.mutation<PartnerProviderModel, PartnerProviderRequest>({
      query: (data) => {
        const { id, partner_id, ...payload } = data;
        return {
          url: `partners/${partner_id}/providers/${id}`,
          method: 'PUT',
          data: payload,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'PartnerProviders', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Партнер успешно обновлен' }));
      },
    }),
    addPartnerProvider: build.mutation<PartnerProviderModel, PartnerProviderRequest>({
      query: (data) => ({ url: `partners/${data.partner_id}/providers`, method: 'POST', data }),
      invalidatesTags: [{ type: 'PartnerProviders', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Партнер успешно добавлен' }));
      },
    }),
    updatePartnerProviderCredentials: build.mutation<PartnerProviderModel, PartnerProviderRequest>({
      query: (data) => {
        const { id, partner_id, ...payload } = data;
        return {
          url: `partners/${partner_id}/providers/${id}/credentials`,
          method: 'PUT',
          data: { credentials: payload.credentials },
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'PartnerProviders', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Партнер успешно обновлен' }));
      },
    }),
  }),
});

export const {
  useListPartnerProvidersQuery,
  useLazyListPartnerProvidersQuery,
  useGetPartnerProvidersQuery,
  useLazyGetPartnerProvidersQuery,
  useUpdatePartnerProviderMutation,
  useAddPartnerProviderMutation,
  useUpdatePartnerProviderCredentialsMutation,
} = partnerProvidersApi;
