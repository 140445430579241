import {
  SnackbarKey,
  useSnackbar,
} from 'notistack';
import {
  useAppDispatch,
  useAppSelector,
} from 'app/hooks';
import {
  closePopupMessage,
  removePopupMessage,
  selectPopupMessages,
} from 'features/common/slice';
import React, { useEffect } from 'react';
import { Button } from '@mui/material';

let displayed: SnackbarKey[] = [];

const useNotifier = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectPopupMessages);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(({ key, message, type, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) {return;}

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        variant: type,
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removePopupMessage({ key: String(myKey) }));
          removeDisplayed(myKey as string);
        },
        action: item => (
          <Button onClick={() => dispatch(closePopupMessage({ key: item }))}>ЗАКРЫТЬ</Button>
        ),
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotifier;
