import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import { showPopupMessage } from 'features/common/slice';
import {
  ListResponse,
  ListSettings,
} from 'features/common/types';
import {
  RoomType,
  RoomTypeRequest,
} from 'features/dictionary/types';

export const roomTypesApi = createApi({
  reducerPath: 'roomTypesApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['RoomTypes'],
  endpoints: (build) => ({
    listRoomTypes: build.query<ListResponse<RoomType>, ListSettings>(
      {
        query: (settings) => ({ url: 'roomTypes', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.data.map(({ id }) => ({ type: 'RoomTypes', id } as const)),
              { type: 'RoomTypes', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'RoomTypes', id: 'LIST' }` is invalidated
            [{ type: 'RoomTypes', id: 'LIST' }],
      }
    ),
    searchRoomTypes: build.query<RoomType[], string>(
      {
        query: (search) => ({ url: search.length > 0 ? `roomTypes/by-name?term=${search}` : 'roomTypes?all=1', method: 'GET' }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'RoomTypes', id } as const)),
              { type: 'RoomTypes', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'RoomTypes', id: 'LIST' }` is invalidated
            [{ type: 'RoomTypes', id: 'LIST' }],
      }
    ),
    addRoomType: build.mutation<RoomType, RoomTypeRequest>({
      query: (data) => ({ url: 'roomTypes', method: 'POST', data }),
      invalidatesTags: [{ type: 'RoomTypes', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Тип комнаты успешно добавлен' }));
      },
    }),
    getRoomType: build.query<RoomType, number>({
      query: (id) => ({ url: `roomTypes/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'RoomTypes', id }],
    }),
    updateRoomType: build.mutation<RoomType, { id: number; data: RoomTypeRequest }>({
      query: ({ id, data }) => ({
        url: `roomTypes/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'RoomTypes', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Тип комнаты успешно обновлен' }));
      },
    }),
    deleteRoomType: build.mutation<{ success: boolean; id: number }, number>(
      {
        query: (id) => ({ url: `roomTypes/${id}`, method: 'DELETE' }),
        invalidatesTags: (result, error, id) => [{ type: 'RoomTypes', id }],
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(showPopupMessage({ text: 'Тип комнаты успешно удален' }));
        },
      }
    ),
  }),
});

export const {
  useLazyGetRoomTypeQuery,
  useAddRoomTypeMutation,
  useLazyListRoomTypesQuery,
  useLazySearchRoomTypesQuery,
  useUpdateRoomTypeMutation,
  useDeleteRoomTypeMutation,
} = roomTypesApi;
