import React, { FC } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  UserModel,
  UserRequest,
} from 'features/user/types';
import { useFormErrors } from 'features/common/hooks';
import { useListRolesQuery } from 'features/user/api/role';

interface UserFormProps {
  onSuccess: (model: UserRequest) => void;
  onCancel: () => void;
  isLoading: boolean;
  model?: UserModel;
}

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Неверный Email')
    .required('Введите Email'),
  name: yup
    .string()
    .required('Укажите имя'),
  last_name: yup
    .string()
    .required('Укажите фамилию'),
  phone: yup
    .string()
    .required('Укажите телефон'),
  roles: yup
    .array()
    .required('Выберите роль'),
});

const UserForm: FC<UserFormProps> = ({
  onSuccess,
  onCancel,
  isLoading,
  model,
}) => {
  const { data: roles } = useListRolesQuery();

  const initialValues = model ? {
    ...model,
    roles: model.roles.map(item => item.id),
  } : {
    name: '',
    email: '',
    last_name: '',
    phone: '',
    roles: [],
  };

  const formik = useFormik<UserRequest>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await onSuccess(values);
    },
  });

  useFormErrors(formik.setErrors);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="email"
              label="E-mail"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="name"
              label="Имя"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={ { marginTop: '12px' } }>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="last_name"
              label="Фамилия"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={formik.touched.last_name && Boolean(formik.errors.last_name)}
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="phone"
              label="Телефон"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={ { marginTop: '12px' } }>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="roles">Роль</InputLabel>
              <Select
                multiple
                name="roles"
                labelId="roles"
                value={formik.values.roles}
                label="Роль"
                onChange={formik.handleChange}
              >
                {
                  roles?.data.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name} - {role.app.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={2} paddingTop={4}>
          <LoadingButton
            color="success"
            loadingPosition="start"
            variant="contained"
            type="submit"
            startIcon={<SaveIcon />}
            loading={isLoading}
          >
            {model ? 'Редактировать' : 'Создать'}
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={onCancel}
          >
            Вернуться назад
          </Button>
        </Stack>
      </>
    </Box>
  );
};

export default UserForm;
