import React, { FC } from 'react';

import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Stack,
  TextField,
} from '@mui/material';
import { AcceptInviteRequest } from 'features/auth/types';
import { useFormErrors } from 'features/common/hooks';

export interface InviteFormProps {
  email: string;
  code: string;
  isLoading: boolean;
  onSubmit: (values: AcceptInviteRequest) => void;
}

const validationSchema = yup.object({
  email: yup
    .string()
    .required('Введите email'),
  password: yup
    .string()
    .required('Введите пароль'),
  confirmpassword: yup
    .string()
    .when('password', {
      is: (val: string) => !!val?.length,
      then: yup.string().oneOf(
        [yup.ref('password')],
        'Пароль должен совпасть'
      ),
    })
    .required('Введите пароль'),
});

const InviteForm: FC<InviteFormProps> = ({
  email,
  code,
  isLoading,
  onSubmit,
}) => {
  const initialValues = {
    email,
    password: '',
    confirmpassword: '',
    invite_code: code,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => onSubmit(values),
  });

  useFormErrors(formik.setErrors);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2} marginY={3}>
        <TextField
          fullWidth
          id="email"
          disabled={true}
          label="Введите email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          name="password"
          label="Введите пароль"
          type="password"
          autoComplete="new-password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <TextField
          fullWidth
          id="confirmpassword"
          name="confirmpassword"
          label="Подтвердите пароль"
          type="password"
          autoComplete="no"
          value={formik.values.confirmpassword}
          onChange={formik.handleChange}
          error={formik.touched.confirmpassword && Boolean(formik.errors.confirmpassword)}
          helperText={formik.touched.confirmpassword && formik.errors.confirmpassword}
        />
      </Stack>
      <LoadingButton
        variant="contained"
        color="success"
        size="large"
        type="submit"
        fullWidth
        disableElevation
        loading={isLoading}
      >
        Зарегистрироваться
      </LoadingButton>
    </form>
  );
};

export default InviteForm;
