// Получение разных форм слова, в зависимости от переданного числа p11(1, 'рубль~рубля~рублей')
export function p11n(count: number, words: string) {
  const wordList = words.split('~');
  if (count % 10 === 1 && count % 100 !== 11) {
    return wordList[0];
  }
  if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
    return wordList[1];
  }
  return wordList[2];
}
