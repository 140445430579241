import React, {
  FC,
  useEffect,
} from 'react';
import DataTable from 'features/common/components/DataTable';
import { usePaginatedQuery } from 'features/common/hooks';
import { useLazyListProvidersQuery } from 'features/provider/api/provider';
import {
  Filter,
  FILTER_OPERATOR,
  FILTER_TYPE,
  Meta,
} from 'features/common/types';
import { useNavigate } from 'react-router-dom';
import { PageContent } from 'features/common/layout/Styled';
import PageTopBar from 'features/common/layout/PageTopBar';
import { ProviderModel } from 'features/provider/types';

const columns = [
  {
    name: 'id',
    label: 'ID',
    sortName: 'p.id',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'p.id',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'name',
    label: 'Название',
    sortName: 'p.name',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'p.name',
        filterType: FILTER_TYPE.INPUT,
      },
      {
        operator: FILTER_OPERATOR.SUBSTRING,
        filterPath: 'p.name',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'code',
    label: 'Код',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'p.code',
        filterType: FILTER_TYPE.INPUT,
      },
      {
        operator: FILTER_OPERATOR.SUBSTRING,
        filterPath: 'p.code',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
];

const defaultFilter: Filter = {
  filterOperator: FILTER_OPERATOR.FULL,
  filterValue: '',
  filterField: 'name',
  filterPath: 'p.name',
};

const Providers: FC = () => {
  const {
    settings,
    filters,
    handleFiltersChange,
    handleLimitChange,
    handlePageChange,
    handleSortChange,
  } = usePaginatedQuery('-p.id');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchProviders, providers] = useLazyListProvidersQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (settings) {
      fetchProviders(settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return (
    <>
      <PageTopBar name="Поставщики" />
      <PageContent>
        <DataTable<ProviderModel>
          columns={columns}
          data={providers.data?.data ?? []}
          onEditEntry={({ id }: { id?: number }) => navigate(`${id}`)}
          sortedBy={settings?.sort ?? null}
          meta={providers.data?.meta as Meta}
          isLoading={providers.isLoading}
          allowDelete={false}
          onLimitChange={handleLimitChange}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          filters={filters}
          defaultFilter={defaultFilter}
          onEditFilters={handleFiltersChange}
        />
      </PageContent>
    </>
  );
};

export default Providers;
