import React, {
  FC,
  useEffect,
  useCallback,
  useState,
} from 'react';
import {
  HotelPhotoModel,
  PhotoRequest,
} from 'features/dictionary/types';
import {
  Box,
  Grid,
  ImageList,
  Stack,
  Skeleton,
  Modal,
} from '@mui/material';
import PhotoItem from 'features/dictionary/components/PhotoItem';

import {
  useDeleteHotelPhotoMutation,
  useLazyListHotelPhotosQuery,
  useUpdateHotelPhotoMutation,
} from 'features/dictionary/api/hotelPhoto';
import { useUpdateHotelMainPhotoMutation } from 'features/dictionary/api/hotel';
import PhotoGallery from 'features/dictionary/components/PhotoGallery';

interface PhotosProps {
  mainPhoto: HotelPhotoModel | null;
  hotelId: number;
}

const HotelPhotos: FC<PhotosProps> = ({ mainPhoto, hotelId }) => {
  const [deleteHotelPhoto] = useDeleteHotelPhotoMutation();
  const [fetchHotelPhotos, { data: photos, isLoading }] = useLazyListHotelPhotosQuery();
  const [updateHotelPhoto] = useUpdateHotelPhotoMutation();
  const [setHotelMainPhoto] = useUpdateHotelMainPhotoMutation();
  const [photoGalleryIsOpen, setPhotoGalleryIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    fetchHotelPhotos({ hotelId });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenPhotoModal = useCallback((index: number) => {
    setPhotoGalleryIsOpen(true);
    setPhotoIndex(index);
  }, []);

  const onPhotoDelete = (id: number) => {
    deleteHotelPhoto(id);
  };

  const onPhotoUpdate = (id: number, data: PhotoRequest) => {
    updateHotelPhoto({ id, data });
  };

  const onSetHotelMainPhoto = (photoId: number) => {
    setHotelMainPhoto({ id: hotelId, data: { photo_id: photoId } });
  };

  return (
    <>
      {isLoading || !photos ? (
        <Grid container wrap="nowrap">
          {
            (Array.from(new Array(3))).map((item, index) => (
              <Box key={index} sx={{ width: 210, marginRight: 0.5, my: 5 }}>
                <Skeleton variant="rectangular" width={210} height={118} />
                <Stack direction="row" mt={1} spacing={1}>
                  <Skeleton width={160}/>
                  <Skeleton width={40} />
                </Stack>
              </Box>
            ))
          }
        </Grid>
      ) : photos.length > 0 && (
        <>
          <Modal
            open={photoGalleryIsOpen}
            onClose={() => setPhotoGalleryIsOpen(false)}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div>
              <PhotoGallery
                photoIndex={photoIndex}
                photos={photos}
                onClose={() => setPhotoGalleryIsOpen(false)}
              />
            </div>
          </Modal>
          <ImageList sx={{ width: '100%', height: 450 }} cols={3} gap={12}>
            {(photos as HotelPhotoModel[]).map((photo, index) => (
              <PhotoItem
                key={photo.id}
                photo={photo}
                photoIndex={index}
                isMain={mainPhoto && mainPhoto.id === photo.id}
                onDelete={onPhotoDelete}
                onUpdate={onPhotoUpdate}
                onSetMain={onSetHotelMainPhoto}
                onOpenPhotoModal={onOpenPhotoModal}
                urlSuffix={'_200_133'}
              />
            ))}
          </ImageList>
        </>
      )}
    </>
  );
};

export default HotelPhotos;
