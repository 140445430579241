import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import {
  AcceptInviteRequest,
  LoginRequest,
  LoginResponse,
} from 'features/auth/types';

export const authApi = createApi({
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'login',
        method: 'POST',
        data: credentials,
      }),
    }),
    acceptInvite: builder.mutation<LoginResponse, AcceptInviteRequest>({
      query: (credentials) => ({
        url: '/acceptInvite',
        method: 'POST',
        data: credentials,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useAcceptInviteMutation,
} = authApi;
