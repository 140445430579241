import * as yup from 'yup';
import React, { FC } from 'react';
import {
  RoomType,
  RoomTypeRequest,
} from 'features/dictionary/types';
import { useFormik } from 'formik';
import { useFormErrors } from 'features/common/hooks';
import {
  Box,
  Button,
  Stack,
  TextField,
  Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Save as SaveIcon } from '@mui/icons-material';


const validationSchema = yup.object({
  name_ru: yup
    .string()
    .required('Укажите название'),
  name_en: yup
    .string()
    .required('Укажите название'),
});

interface RoomTypeFormProps {
  onSuccess: (model: RoomTypeRequest) => void;
  onCancel: () => void;
  isLoading: boolean;
  model?: RoomType;
}
const RoomTypeForm: FC<RoomTypeFormProps> = ({
  onSuccess,
  onCancel,
  isLoading,
  model,
}) => {
  const initialValues = model ?? {
    name_ru: '',
    name_en: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => await onSuccess(values),
  });

  useFormErrors(formik.setErrors);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="name_ru"
              label="Название на русском"
              value={formik.values.name_ru}
              onChange={formik.handleChange}
              error={formik.touched.name_ru && Boolean(formik.errors.name_ru)}
              helperText={formik.touched.name_ru && formik.errors.name_ru}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="name_en"
              label="Название на английском"
              value={formik.values.name_en}
              onChange={formik.handleChange}
              error={formik.touched.name_en && Boolean(formik.errors.name_en)}
              helperText={formik.touched.name_en && formik.errors.name_en}
            />
          </Grid>
        </Grid>
        <Stack direction="row" spacing={2} paddingTop={4}>
          <LoadingButton
            color="success"
            loadingPosition="start"
            variant="contained"
            type="submit"
            startIcon={<SaveIcon />}
            loading={isLoading}
          >
            {model ? 'Редактировать' : 'Создать'}
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={onCancel}
          >
            Вернуться назад
          </Button>
        </Stack>
      </>
    </Box>
  );
};

export default RoomTypeForm;
