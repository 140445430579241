import React, { FC } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Select,
  MenuItem,
  createFilterOptions,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  FacilityCategoryModel,
  FacilityModel,
  FacilityRequest,
  FacilityType,
} from 'features/dictionary/types';
import { useFormErrors } from 'features/common/hooks';
import FormAutocomplete from 'features/common/components/FormAutocomplete';
import { useLazySearchFacilityCategoriesQuery } from 'features/dictionary/api/facilityCategory';

interface FacilityFormProps {
  onSuccess: (request: FacilityRequest) => void;
  onCancel: () => void;
  isLoading: boolean;
  model?: FacilityModel;
}
const validationSchema = yup.object({
  name_ru: yup
    .string()
    .required('Укажите название'),
  name_en: yup
    .string()
    .required('Укажите название'),
  type: yup
    .string()
    .required('Выберете тип'),
});

const facilityTypes = [
  {
    value: 'hotel',
    label: 'Отель',
  },
  {
    value: 'room',
    label: 'Комната',
  }];

const FacilityForm: FC<FacilityFormProps> = ({
  onSuccess,
  onCancel,
  isLoading,
  model,
}) => {
  const initialValues = model ?? {
    name_ru: '',
    name_en: '',
    type: 'hotel' as FacilityType,
    code: '',
    category_id: null,
    category: null,
  };

  const [fetchFacilityCategories, facilityCategories] = useLazySearchFacilityCategoriesQuery();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ code, ...values }) => {
      onSuccess({ ...values, code: code || null });
    },
  });

  const onFacilityCategoryChange = (value: FacilityCategoryModel) => {
    formik.setFieldValue('category_id', value ? value.id : null);
    formik.setFieldValue('category', value);
  };

  useFormErrors(formik.setErrors);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="name_ru"
            label="Название на русском"
            value={formik.values.name_ru}
            onChange={formik.handleChange}
            error={formik.touched.name_ru && Boolean(formik.errors.name_ru)}
            helperText={formik.touched.name_ru && formik.errors.name_ru}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="name_en"
            label="Название на английском"
            value={formik.values.name_en}
            onChange={formik.handleChange}
            error={formik.touched.name_en && Boolean(formik.errors.name_en)}
            helperText={formik.touched.name_en && formik.errors.name_en}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={ { marginTop: '12px' } }>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="type">Тип</InputLabel>
            <Select
              labelId="type"
              value={formik.values.type}
              label="Тип"
              onChange={formik.handleChange}
            >
              {
                facilityTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormAutocomplete<FacilityCategoryModel>
            field="category_id"
            label="Выберите категорию"
            fetchList={fetchFacilityCategories}
            list={facilityCategories.data || []}
            isFetching={facilityCategories.isLoading}
            error={formik.touched.category_id && Boolean(formik.errors.category_id)}
            helperText={formik.touched.category_id && formik.errors.category_id}
            selectedOption={formik.values.category as FacilityCategoryModel}
            filterOptions={createFilterOptions<FacilityCategoryModel>({
              stringify: ({ name_ru }) => name_ru,
            })}
            comparator={(option, value) => (value.name_ru !== '' && option.name_ru === value.name_ru) || (value.name_en !== '' && option.name_en === value.name_en)}
            getOptionLabel={(option) => option.name_ru}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id}>
                {option.name_ru}
              </Box>
            )}
            handleChange={(event, value) => onFacilityCategoryChange(value as FacilityCategoryModel)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={ { marginTop: '12px' } }>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="code"
            label="Код"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
          />
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2} paddingTop={4}>
        <LoadingButton
          color="success"
          loadingPosition="start"
          variant="contained"
          type="submit"
          startIcon={<SaveIcon />}
          loading={isLoading}
        >
          {model ? 'Редактировать' : 'Создать'}
        </LoadingButton>
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          Вернуться назад
        </Button>
      </Stack>
    </Box>
  );
};

export default FacilityForm;
