import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import { showPopupMessage } from 'features/common/slice';
import {
  ListSettings,
  ListResponse,
} from 'features/common/types';
import {
  ProviderCredential,
  ProviderModel,
} from 'features/provider/types';

export const providerApi = createApi({
  reducerPath: 'providersApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Providers'],
  endpoints: (build) => ({
    listProviders: build.query<ListResponse<ProviderModel>, ListSettings>(
      {
        query: (settings) => ({ url: 'providers', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.data.map(({ id }) => ({ type: 'Providers', id } as const)),
              { type: 'Providers', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Providers', id: 'LIST' }` is invalidated
            [{ type: 'Providers', id: 'LIST' }],
      }
    ),
    searchProviders: build.query<ProviderModel[], string>(
      {
        query: (search) => ({ url: search.length > 0 ? `providers?term=${search}` : 'providers?all=1', method: 'GET' }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'Providers', id } as const)),
              { type: 'Providers', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Providers', id: 'LIST' }` is invalidated
            [{ type: 'Providers', id: 'LIST' }],
      }
    ),
    getProvider: build.query<ProviderModel, number>({
      query: (id) => ({ url: `providers/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'Providers', id }],
    }),
    updateProvider: build.mutation<ProviderModel, ProviderModel>({
      query: (data) => {
        const { id, ...payload } = data;
        return {
          url: `providers/${id}`,
          method: 'PUT',
          data: payload,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Providers', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Поставщик успешно обновлен' }));
      },
    }),
    searchProviderCredentials: build.mutation<ProviderCredential[], number>({
      query: (id) => ({ url: `providers/${id}/credentials`, method: 'GET' }),
      invalidatesTags: (result) =>
        result
          ? // successful query
          [
            ...result.map(() => ({ type: 'Providers' } as const)),
            { type: 'Providers', id: 'LIST' },
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Providers', id: 'LIST' }` is invalidated
          [{ type: 'Providers', id: 'LIST' }],
    }),
    getProviderCredentials: build.query<ProviderCredential[], number>({
      query: (id) => ({ url: `providers/${id}/credentials`, method: 'GET' }),
      providesTags: (result) =>
        result
          ? // successful query
          [
            ...result.map(() => ({ type: 'Providers' } as const)),
            { type: 'Providers', id: 'LIST' },
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Providers', id: 'LIST' }` is invalidated
          [{ type: 'Providers', id: 'LIST' }],
    }),
  }),
});

export const {
  useListProvidersQuery,
  useLazyListProvidersQuery,
  useGetProviderQuery,
  useSearchProvidersQuery,
  useLazyGetProviderQuery,
  useLazySearchProvidersQuery,
  useUpdateProviderMutation,
  useSearchProviderCredentialsMutation,
  useGetProviderCredentialsQuery,
} = providerApi;
