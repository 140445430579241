import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import PageTopBar from 'features/common/layout/PageTopBar';
import { PageContent } from 'features/common/layout/Styled';
import { useAddProviderTaskMutation } from 'features/provider/api/providerTask';
import ProviderTaskForm from 'features/provider/components/ProviderTaskForm';
import { ProviderTaskRequest } from 'features/provider/types';

const ProviderTaskCreate: FC = () => {
  const [addProviderTask, { isSuccess, isLoading }] = useAddProviderTaskMutation();
  const navigate = useNavigate();

  if (isSuccess) {
    navigate('/providers/import-tasks');
  }

  const handleSubmit = async (data: ProviderTaskRequest) => await addProviderTask(data);

  return (
    <>
      <PageTopBar name="Новая задача" />
      <PageContent>
        <ProviderTaskForm
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onCancel={() => navigate('/providers/import-tasks')}
        />
      </PageContent>
    </>
  );
};

export default ProviderTaskCreate;
