import React, { FC } from 'react';
import Header from 'features/common/layout/Header';
import SideNav from 'features/common/layout/SideNav';
import { LayoutStyled } from 'features/common/layout/Styled';
import { Outlet } from 'react-router-dom';

export const Layout: FC = () => (
  <LayoutStyled>
    <SideNav />
    <div className="workspace">
      <Header />
      <div className="content">
        <Outlet />
      </div>
    </div>
  </LayoutStyled>
);
