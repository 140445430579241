import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from 'app/api';
import {
  ListResponse,
  ListSettings,
} from 'features/common/types';
import { OrderModel } from 'features/order/types';


export const orderApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Orders'],
  endpoints: (build) => ({
    listOrders: build.query<ListResponse<OrderModel>, ListSettings>(
      {
        query: (settings) => ({ url: 'orders', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.data.map(({ id }) => ({ type: 'Orders', id } as const)),
              { type: 'Orders', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Orders', id: 'LIST' }` is invalidated
            [{ type: 'Orders', id: 'LIST' }],
      }
    ),
    getOrder: build.query<OrderModel, string>({
      query: (code) => ({ url: `orders/${code}`, method: 'GET' }),
      providesTags: (result, error, code) => [{ type: 'Orders', code }],
    }),
  }),
});

export const {
  useLazyGetOrderQuery,
  useLazyListOrdersQuery,
} = orderApi;
