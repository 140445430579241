import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  Box,
  Button,
  createFilterOptions,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import * as yup from 'yup';
import {
  FormikErrors,
  FormikTouched,
  useFormik,
} from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  RegionModel,
  RegionRequest,
  ProviderMapping,
  CountryModel,
  ProviderMappingRequest,
} from 'features/dictionary/types';
import { useFormErrors } from 'features/common/hooks';
import FormAutocomplete from 'features/common/components/FormAutocomplete';
import { ProviderModel } from 'features/provider/types';
import { useLazySearchProvidersQuery } from 'features/provider/api/provider';
import { useLazySearchCountriesQuery } from 'features/dictionary/api/country';

interface RegionFormProps {
  onSuccess: (model: RegionRequest) => void;
  onCancel: () => void;
  isLoading: boolean;
  model?: RegionModel;
}

const validationSchema = yup.object({
  mappings: yup.array().of(yup.object({
    provider_id: yup.number().required('Выберите поставщика'),
    provider_code: yup.string().required('Введите код'),
  })),
  name_ru: yup
    .string()
    .required('Введите название'),
  name_en: yup
    .string()
    .required('Введите название'),
});

const RegionForm: FC<RegionFormProps> = ({
  onSuccess,
  onCancel,
  isLoading,
  model,
}) => {
  const [ fetchTaskProviders, providers ] = useLazySearchProvidersQuery();
  const [ fetchCountries, countries ] = useLazySearchCountriesQuery();

  useEffect(() => {
    fetchTaskProviders('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = model ? { ...model } : {
    name_ru: '',
    name_en: '',
    country_id: 0,
    mappings: [] as ProviderMapping[],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const mappings = values.mappings.reduce<ProviderMappingRequest>((ret, value) => {
        ret[value.provider_id] = value.provider_code;

        return ret;
      }, {});

      const request = {
        ...values,
        mappings,
      };
      onSuccess(request);
    },
  });

  useFormErrors(formik.setErrors);

  const handleProviderAdd = () => {
    const provs = getAvailableProviders();
    if (provs.length === 0) {
      return;
    }

    formik.setValues(values => ({
      ...values,
      mappings: [
        ...values.mappings,
        {
          provider_id: Number(provs[0].id),
          provider_code: '',
        },
      ],
    }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRemoveMapping = (key: number) => () => {
    formik.setValues(values => ({
      ...values,
      mappings: values.mappings.filter((_, idx) => idx !== key),
    }));
  };

  const getAvailableProviders = useCallback((id?: number) => providers.data?.filter(item => item.id === id
      || !formik.values.mappings.find(val => val.provider_id === item.id)
  ) || [], [formik.values.mappings, providers.data]);

  const mappingsEl = useMemo(() => (formik.values.mappings as ProviderMapping[]).map((mapping, idx) => (
    <Fragment key={mapping.provider_id}>
      <Grid item xs={12} sm={5}>
        <FormAutocomplete<ProviderModel>
          field={`mappings[${idx}].provider_id`}
          label="Поставщик"
          isFetching={providers.isLoading}
          list={getAvailableProviders(mapping.provider_id)}
          selectedOption={providers.data?.find(item => item.id === mapping.provider_id)}
          disableClearable={true}
          error={false}
          helperText=""
          filterOptions={createFilterOptions<ProviderModel>({
            stringify: ({ name }) => name,
          })}
          comparator={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.name}
            </Box>
          )}
          handleChange={(event, value) => formik.setFieldValue(`mappings[${idx}].provider_id`, value ? value.id : 0)}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          fullWidth
          id={`mappings[${idx}].provider_code`}
          label="Код поставщика"
          value={formik.values.mappings[idx].provider_code}
          onChange={formik.handleChange}
          error={(formik.touched.mappings?.at(idx) as FormikTouched<ProviderMapping>)?.provider_code
            && Boolean((formik.errors.mappings?.at(idx) as FormikErrors<ProviderMapping>)?.provider_code)}
          helperText={(formik.touched.mappings?.at(idx) as FormikTouched<ProviderMapping>)?.provider_code
            && (formik.errors.mappings?.at(idx) as FormikErrors<ProviderMapping>)?.provider_code}
        />
      </Grid>
      <Grid item xs={12} sm={2} display="flex" alignItems="center">
        <Button onClick={handleRemoveMapping(idx)}>Удалить</Button>
      </Grid>
    </Fragment>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  )), [getAvailableProviders, handleRemoveMapping, providers.data, providers.isLoading]);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" gutterBottom paddingBottom={3}>
            Коды поставщиков
          </Typography>
          <Button
            size="medium"
            disabled={getAvailableProviders().length === 0}
            sx={{ height: '50%' }}
            variant="contained"
            onClick={handleProviderAdd}
          >
            Добавить
          </Button>
        </Stack>
        <Grid container spacing={2}>
          {mappingsEl}
        </Grid>
        <Typography variant="h6" gutterBottom paddingY={2}>
          Переводы названий
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="name_ru"
              label="Название на русском"
              value={formik.values.name_ru}
              onChange={formik.handleChange}
              error={formik.touched.name_ru && Boolean(formik.errors.name_ru)}
              helperText={formik.touched.name_ru && formik.errors.name_ru}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="name_en"
              label="Название на английском"
              value={formik.values.name_en}
              onChange={formik.handleChange}
              error={formik.touched.name_en && Boolean(formik.errors.name_en)}
              helperText={formik.touched.name_en && formik.errors.name_en}
            />
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom paddingY={2}>
          Местонахождение
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormAutocomplete<CountryModel>
              field="country_id"
              label="Введите страну"
              isFetching={countries.isLoading}
              fetchList={fetchCountries}
              list={countries.data || []}
              error={formik.touched.country_id && Boolean(formik.errors.country_id)}
              helperText={formik.touched.country_id && formik.errors.country_id}
              selectedOption={model?.country}
              filterOptions={createFilterOptions<CountryModel>({
                stringify: ({ name_ru }) => name_ru,
              })}
              comparator={(option, value) => (value.name_ru !== '' && option.name_ru === value.name_ru) || (value.name_en !== '' && option.name_en === value.name_en)}
              getOptionLabel={(option) => option.name_ru}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                  {option.name_ru}
                </Box>
              )}
              handleChange={(event, value) => formik.setFieldValue('country_id', value ? value.id : 0)}
            />
          </Grid>
        </Grid>
        <Stack direction="row" spacing={2} paddingTop={4}>
          <LoadingButton
            color="success"
            loadingPosition="start"
            variant="contained"
            type="submit"
            startIcon={<SaveIcon />}
            loading={isLoading}
          >
            {model ? 'Редактировать' : 'Создать'}
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={onCancel}
          >
            Вернуться назад
          </Button>
        </Stack>
      </>
    </Box>
  );
};

export default RegionForm;
