import {
  Filter,
  FILTER_OPERATOR,
  FILTER_TYPE,
  Meta,
} from 'features/common/types';
import React, {
  FC,
  useEffect,
} from 'react';
import PageTopBar from 'features/common/layout/PageTopBar';
import { PageContent } from 'features/common/layout/Styled';
import DataTable from 'features/common/components/DataTable';
import { ProviderMappingTasksModel } from 'features/provider/types';
import { useNavigate } from 'react-router-dom';
import { usePaginatedQuery } from 'features/common/hooks';
import { useLazyListMappingTasksQuery } from 'features/provider/api/mappingTask';
import { DateTime } from 'luxon';

const columns = [
  {
    name: 'id',
    label: 'ID',
    sortName: 'amt.id',
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'amt.id',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'provider_task.type',
    label: 'Задача импорта',
    format(value: string) {
      switch(value) {
      case 'country':
        return <>Страны</>;
      case 'region':
        return <>Регионы</>;
      case 'city':
        return <>Города</>;
      case 'hotel':
        return <>Отели</>;
      }
      return <>{value}</>;
    },
    filterableOperators: [],
  },
  {
    name: 'provider_task.provider.name',
    label: 'Поставщик',
    filterableOperators: [],
  },
  {
    name: 'status',
    label: 'Статус',
    sortName: 'amt.status',
    format(value: string) {
      switch(value) {
      case 'pending':
        return <>Назначено</>;
      case 'failed':
        return <>Неудачно</>;
      case 'completed':
        return <>Завершено</>;
      }
      return <>{value}</>;
    },
    filterableOperators: [
      {
        operator: FILTER_OPERATOR.FULL,
        filterPath: 'amt.status',
        filterType: FILTER_TYPE.INPUT,
      },
    ],
  },
  {
    name: 'started_at',
    label: 'Время начала',
    sortName: 'amt.started_at',
    format(value: string) {
      return (
        <>{
          DateTime.fromISO(value)
            .setLocale('ru')
            .toFormat('f')
        }</>
      );
    },
    filterableOperators: [],
  },
  {
    name: 'finished_at',
    label: 'Время окончания',
    sortName: 'amt.finished_at',
    format(value: string | null) {
      if (!value) {
        return <>&mdash;</>;
      }
      return (
        <>{
          DateTime.fromISO(value)
            .setLocale('ru')
            .toFormat('f')
        }</>
      );
    },
    filterableOperators: [],
  },
  {
    name: 'inserted_count',
    label: 'Смапленно',
    sortName: 'amt.inserted_count',
    filterableOperators: [],
  },
];

const defaultFilter: Filter = {
  filterOperator: FILTER_OPERATOR.FULL,
  filterValue: '',
  filterField: 'status',
  filterPath: 'amt.status',
};

const MappingTasks: FC = () => {
  const navigate = useNavigate();
  const {
    settings,
    filters,
    handleFiltersChange,
    handleLimitChange,
    handlePageChange,
    handleSortChange,
  } = usePaginatedQuery('-amt.id');

  const [ fetchMappingTasks, mappingTasks ] = useLazyListMappingTasksQuery();
  useEffect(() => {
    if (settings) {
      fetchMappingTasks(settings);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return (
    <>
      <PageTopBar name="Задачи маппинга" />
      <PageContent>
        <DataTable<ProviderMappingTasksModel>
          columns={columns}
          data={mappingTasks.data?.data ?? []}
          onEditEntry={({ id }: { id?: number }) => navigate(`/providers/${id}`)}
          sortedBy={settings?.sort ?? null}
          meta={mappingTasks.data?.meta as Meta}
          isLoading={mappingTasks.isLoading}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onDeleteEntry={() => { } }
          onLimitChange={handleLimitChange}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          filters={filters}
          defaultFilter={defaultFilter}
          onEditFilters={handleFiltersChange}
          displayMenu={false}
        />
      </PageContent>
    </>
  );
};

export default MappingTasks;
