import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  CityPhotoModel,
  PhotoRequest,
} from 'features/dictionary/types';
import {
  Box,
  Grid,
  ImageList,
  Stack,
  Skeleton,
  Modal,
} from '@mui/material';
import PhotoItem from 'features/dictionary/components/PhotoItem';
import {
  useLazyListCityPhotosQuery,
  useDeleteCityPhotoMutation,
  useUpdateCityPhotoMutation,
} from 'features/dictionary/api/cityPhoto';
import { useUpdateCityMainPhotoMutation } from 'features/dictionary/api/city';
import PhotoGallery from 'features/dictionary/components/PhotoGallery';

interface CityPhotosProps {
  mainPhoto: CityPhotoModel | null;
  cityId: number;
}

const CityPhotos: FC<CityPhotosProps> = ({ mainPhoto, cityId }) => {
  const [deleteCityPhoto] = useDeleteCityPhotoMutation();
  const [fetchCityPhotos, { data: photos, isLoading }] = useLazyListCityPhotosQuery();
  const [updateCityPhoto] = useUpdateCityPhotoMutation();
  const [setCityMainPhoto] = useUpdateCityMainPhotoMutation();
  const [photoGalleryIsOpen, setPhotoGalleryIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    fetchCityPhotos({ cityId });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityId]);

  const onPhotoDelete = (id: number) => {
    deleteCityPhoto(id);
  };

  const onPhotoUpdate = (id: number, data: PhotoRequest) => {
    updateCityPhoto({ id, data });
  };

  const onSetCityMainPhoto = (photoId: number) => {
    setCityMainPhoto({ id: cityId, data: { photo_id: photoId } });
  };

  const onOpenPhotoModal = useCallback((index: number) => {
    setPhotoGalleryIsOpen(true);
    setPhotoIndex(index);
  }, []);

  return (
    <>
      {
        isLoading || !photos ? (
          <Grid container wrap="nowrap">
            {
              Array.from(new Array(3)).map((item, index) => (
                <Box key={index} sx={{ width: 210, marginRight: 0.5, my: 5 }}>
                  <Skeleton variant="rectangular" width={210} height={118} />
                  <Stack direction="row" mt={1} spacing={1}>
                    <Skeleton width={160}/>
                    <Skeleton width={40} />
                  </Stack>
                </Box>
              ))
            }
          </Grid>
        ) : photos.length > 0 && (
          <>
            <Modal
              open={photoGalleryIsOpen}
              onClose={() => setPhotoGalleryIsOpen(false)}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <PhotoGallery
                photoIndex={photoIndex}
                photos={photos}
                onClose={() => setPhotoGalleryIsOpen(false)}
              />
            </Modal>
            <ImageList sx={{ width: '100%', height: 450 }} cols={3} gap={12}>
              {(photos as CityPhotoModel[]).map((photo, index) => (
                <PhotoItem
                  key={photo.id}
                  photo={photo}
                  photoIndex={index}
                  isMain={mainPhoto && mainPhoto.id === photo.id}
                  onDelete={onPhotoDelete}
                  onUpdate={onPhotoUpdate}
                  onOpenPhotoModal={onOpenPhotoModal}
                  onSetMain={onSetCityMainPhoto}
                  urlSuffix={'_350_350'}
                />
              ))}
            </ImageList>
          </>
        )}
    </>
  );
};

export default CityPhotos;
