import React, { FC } from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useFormErrors } from 'features/common/hooks';
import { PartnerModel } from 'features/partner/types';

interface PartnerFormProps {
  onSubmit: (model: PartnerModel) => void;
  onCancel: () => void;
  isLoading: boolean;
  model?: PartnerModel;
}

export const initialPartnerValues = {
  name: '',
  code: '',
};

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Введите название'),
  code: yup
    .string()
    .required('Введите код'),
});

const PartnerForm: FC<PartnerFormProps> = ({
  onSubmit,
  onCancel,
  isLoading,
  model,
}) => {
  const initialValues = model ? { ...model } : {
    name: '',
    code: '',
  };

  const formik = useFormik<PartnerModel>({
    initialValues,
    validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  useFormErrors(formik.setErrors);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Stack direction="row" justifyContent={'flex-end'} spacing={2} marginBottom={2}>
        <LoadingButton
          color="success"
          loadingPosition="start"
          variant="contained"
          type="submit"
          startIcon={<SaveIcon />}
          loading={isLoading}
        >
          {model ? 'Редактировать' : 'Создать'}
        </LoadingButton>
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          Вернуться назад
        </Button>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="name"
            label="Название"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="code"
            label="Код"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PartnerForm;
