import React, {
  useEffect,
  useState,
} from 'react';
import {
  Map,
  Marker,
} from '@2gis/mapgl/types';
import markerIcon from 'assets/svg/map-marker-on.svg';
import { use2GISBundle } from 'features/common/components/Use2GISBundle';
import {
  Button,
  ButtonGroup,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface IMapProps {
  mapCenter: number[];
  zoom: number;
  markerCoordinates?: Array<(number | null)>;
  onEditMarker: (value: number[]) => void;
}

function CustomMap(props: IMapProps) {
  const {
    mapCenter,
    zoom,
    markerCoordinates,
    onEditMarker,
  } = props;
  const mapGLBundle = use2GISBundle();
  const key = 'b4238e7d-091e-4eed-afbe-82a859f72a1e';
  const [map, setMap] = useState<Map>();
  const [marker, setMarker] = useState<Marker>();

  useEffect(() => {
    if (!mapGLBundle) {
      return;
    }
    const newMap = new mapGLBundle.Map('map-container', {
      center: mapCenter,
      zoom,
      key,
      disableZoomOnScroll: true,
      zoomControl: false,
    });
    setMap(newMap);
    return () => map && map.destroy();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapGLBundle]);

  useEffect(() => {
    let newMarker: Marker | undefined = marker;
    if (map && mapGLBundle) {
      if (markerCoordinates && markerCoordinates[0]) {
        if (newMarker) {
          (newMarker as Marker).setCoordinates(markerCoordinates as number[]);
        } else {
          newMarker = new mapGLBundle.Marker(map, {
            coordinates: markerCoordinates as number[],
            icon: markerIcon,
          });
          setMarker(newMarker);
        }
      }
      map.on('click', (e) => {
        if (newMarker) {
          newMarker.setCoordinates(e.lngLat);
        } else {
          newMarker = new mapGLBundle.Marker(map, {
            coordinates: e.lngLat,
            icon: markerIcon,
          });
          setMarker(newMarker);
        }
        onEditMarker(e.lngLat);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, mapGLBundle, markerCoordinates]);

  useEffect(() => {
    map?.setCenter(mapCenter);
  }, [map, mapCenter]);

  const handleZoom = (change: number) => () => {
    (map as Map).setZoom((map as Map).getZoom() + change);
  };

  return (
    <div style={{ width: '100%', height: '400px', position: 'relative' }}>
      <div id="map-container" style={{ width: '100%', height: '100%' }}></div>
      <ButtonGroup
        orientation="vertical"
        aria-label="vertical contained button group"
        variant="contained"
        style={{
          position: 'absolute',
          top: '15px',
          right: '15px',
        }}
      >
        <Button onClick={handleZoom(1)}>
          <AddIcon />
        </Button>
        <Button onClick={handleZoom(-1)}>
          <RemoveIcon />
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default CustomMap;
