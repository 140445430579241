import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/api';
import { showPopupMessage } from 'features/common/slice';
import {
  ListResponse,
  ListSettings,
} from 'features/common/types';
import {
  UserModel,
  UserRequest,
} from 'features/user/types';

export const userApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Users'],
  endpoints: (build) => ({
    listUsers: build.query<ListResponse<UserModel>, ListSettings>(
      {
        query: (settings) => ({ url: 'users', method: 'GET', undefined, params: settings }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.data.map(({ id }) => ({ type: 'Users', id } as const)),
              { type: 'Users', id: 'LIST' },
            ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'Users', id: 'LIST' }` is invalidated
            [{ type: 'Users', id: 'LIST' }],
      }
    ),
    searchUsers: build.query<UserModel[], string>(
      {
        query: (search) => ({ url: search.length > 0 ? `users/by-name?term=${search}` : 'users?all=1', method: 'GET' }),
        providesTags: (result) =>
          result
            ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'Users', id } as const)),
              { type: 'Users', id: 'LIST' },
            ]
            :
            [{ type: 'Users', id: 'LIST' }],
        transformResponse: (response: UserModel[] | ListResponse<UserModel>) => 'data' in response ? response.data : response,
      }
    ),
    createUser: build.mutation<UserModel, UserRequest>({
      query: (data) => ({ url: 'users', method: 'POST', data }),
      // Invalidates all User-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created city could show up in any lists.
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Приглашение успешно отправлено cозданному пользователю' }));
      },
    }),
    getUser: build.query<UserModel, number>({
      query: (id) => ({ url: `users/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'Users', id }],
    }),
    updateUser: build.mutation<UserModel, { id: number; data: UserRequest }>({
      query: ({ id, data }) => ({
        url: `users/${id}`,
        method: 'PUT',
        data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [{ type: 'Users', id }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Данные пользователя успешно обновлены' }));
      },
    }),
    resendInvitation: build.mutation<null, { id: number }>({
      query: ({ id }) => ({
        url: `users/${id}/resendInvite`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(showPopupMessage({ text: 'Приглашение успешно отправлено' }));
      },
    }),
  }),
});

export const {
  useLazyGetUserQuery,
  useCreateUserMutation,
  useLazyListUsersQuery,
  useUpdateUserMutation,
  useResendInvitationMutation,
} = userApi;
